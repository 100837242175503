/*------------------------------------
    # BORDER
------------------------------------*/

$type01 : 1px solid color(grey-10);

.#{$wf__ns}brd{
  border: $type01;
  &--top{
    border:0;
    border-top:$type01;
  }
  &--bottom{
    border:0;
    border-bottom:$type01;
  }
  &--left{
    border:0;
    border-left:$type01;
  }
  &--right{
    border:0;
    border-right:$type01;
  }
}
