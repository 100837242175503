/*----------------------------------------------
   # BUTTONS: estilización del carrusel
------------------------------------------------*/

.#{$wf__ns}carrusel{
    background-color: color(grey-2);
    padding: rem(54) rem(200);
    .#{$wf__ns}carrusel3{
        &__fig{
            width: rem(143);
            height: rem(90);
            padding: 0 rem(19);
        }
        &__img{
            width: 100%;
            height: 100%;
        }
    }
    

}

@include media-query('md'){

}


@include media-query('sm'){

}

@include media-query('xs'){

}
