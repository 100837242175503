/*----------------------------------------------
   # módulo
------------------------------------------------*/

.#{$wf__ns}micarpeta{
	display: none;
}

@include media-query('md'){
	.#{$wf__ns}micarpeta{
		display: block;
		height: rem(120);
		position: relative;
		.ppg-link{
			position: absolute;
			top: rem(20);
			right: rem(140);
		}
	}
}

@include media-query('sm'){
	.#{$wf__ns}micarpeta{
		height:rem(185);
		.ppg-link{
			position: relative;
			right:0;
			top:0;
		}
	}
}

@include media-query('xs'){

}
