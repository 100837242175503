/*----------------------------------------------
   # módulo
------------------------------------------------*/

.#{$wf__ns}info{
    display: block;
    background-color: color(grey-11);
    border:1px solid color(grey-4);
    padding: rem(25) rem(25) 0 rem(25);
    &__fieldset{
        position: relative;
    }
    &__legend{
        width:100%;
        padding-bottom:rem(25);
        padding-top:rem(7);
        //margin-bottom:20px;
        display: block;
        font-size: rem(24);
        color: color(secondary);
        //border-bottom: 1px solid color(grey-4);
    }
    &__radio{
        position: absolute;
        top: 0;
        right: 0;
    }
    &__lbl-radio{
        display: inline-block;
        width: rem(90);
        text-align: center;
        border: 1px solid color(grey-4);
        background-color: color(white);
        color: color(primary);
        padding: rem(12) rem(20);
        font-size:rem(14);
        font-weight:bold;
        border-radius: 5px;
        cursor: pointer;
        &:hover, &:focus{
            background-color: color(primary);
            color: color(white);
        }
    }
    &__lbl-textarea{
        font-size:rem(16);
        color: color(secondary);
        display: block;
    }
    &__textarea{
        display: block;
        border:1px solid color(grey-4);
        border-radius: 5px;
        width:100%;
        min-height:rem(150);
        margin-bottom: rem(20);
        padding:rem(10);
        color: color(grey-8);
    }
    &__submit{
        padding-right:rem(50);
        padding-left:rem(50);
        width:auto;
        float: right;
    }
    &__contet-text-area{
        @extend %clearfix;
        padding-bottom: rem(25);
    }
}

@include media-query('md'){
    .#{$wf__ns}info{
        &__radio{
            position: inherit;
            margin-bottom: rem(20);
        }
        &__legend{
            padding-bottom: rem(5);
        }
    }
}


@include media-query('sm'){
    .#{$wf__ns}info{
        &__legend{
            font-size: rem(20);
        }
    }

}

@include media-query('xs'){

}
