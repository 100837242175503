/*------------------------------------*\
    # GENERIC CLEARFIX
\*------------------------------------*/

/**
 * Micro clearfix, as per: css-101.org/articles/clearfix/latest-new-clearfix-so-far.php
 * Extend the clearfix placeholder class `%clearfix` with Sass to avoid the `.clearfix` class appearing
 * over and over in your markup.
 */
.clearfix,
%clearfix {
	&:after {
		content: "";
		display: block;
		clear: both;
	}
}

/*------------------------------------*\
    # END GENERIC CLEARFIX
\*------------------------------------*/
