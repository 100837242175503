@if $include__trumps--width {
    /*------------------------------------
       # TRUMPS FONTS SIZE
    -----------------------------------*/
    @each $number, $i in $wf__trumps-width {
        .t-w-#{$number}{
            width: ($number+0%) !important;
        }
    }

    /*------------------------------------
       # END TRUMPS FONTS SIZE
    -----------------------------------*/
}// end if


