//*----------------------------------------------
//   # módulo menú: se compone de un menú dropdown que debe ser accesible
//    el menú se convierte en un menú sticky en versión móvil
//------------------------------------------------*/

// LOCAL VAR

$bck: color(white);
$color01: color(white);
$color02: color(primary-hover);

.#{$wf__ns}menu{
	background-color: color(primary);

	// ELEMENT
	&__list{
		@extend %clearfix;
	}
	&__it{
		// display: table;
		float: left;
		//max-width: calc(100%/5);
		border-right: 1px solid rgba(255,255,255, .28);
		&:first-child{
			border-left: 1px solid rgba(255,255,255, .28);
		}
	}
	&__link{
		width: 100%;
		height: 100%;
		display:table;
		color: $color01;
		font-size: rem(14);
		font-weight: bold;
		padding: rem(16) rem(18) rem(16) rem(18);
		border: 2px solid transparent;
		text-align: left;
		vertical-align: middle;
		margin-top: rem(5);
		.#{$wf__ns}menu__text-link{
			display: table-cell;
			vertical-align: middle;
		}
		&:hover{
			text-decoration: none;
			background-color: $color02;
		}
		&:focus{
			text-decoration: none;
			outline: none;
			border-radius: 5px;
			border: 2px solid $color01;
			box-shadow: 0px 0px 5px 3px $color02;
		}
		&.active{
			color: $color02;
			background-color:$color01;
			.ppg-icon__crossW-3a:after{
				width: rem(11);
				height: rem(7);
				background-position: -5px -96px;
			}
		}
	}
	&__content{
		// relativo al body calculate w/ js
		padding: rem(30);
		position: absolute;
		background-color: $bck;
		z-index: z-index(offcanvas) + 1;
		border-right: 1px solid color(grey-4);
		border-left: 1px solid color(grey-4);
		border-bottom: 1px solid color(grey-4);
		.ppg-text{
			margin-top: rem(10);
		}
	}
	&__list-2l{
	}
	&__it-2l{
		margin-bottom: rem(25);
	}
	&__link-2l{
		display: block;
		font-family: $wf__font--default;
		font-size: rem(14);
		color: color(secondary);
		padding: rem(15) rem(10) rem(10);
		&:hover, &:focus{
			text-decoration: none;
			background-color: color(salmon);
		}
		//&:before{
		//	vertical-align: text-top;
		//}
	}
	&__sticky{
		display: none;
	}
	.#{$wf__ns}menu__secondary{
		display: none;
	}
	// MODIFIER
}

.menu-fixed {
	position:fixed;
	z-index:1000;
	top:0;
	max-width:rem(1000);
	width:100%;
	box-shadow:0 4px 3px rgba(0,0,0,.2);
}

@include media-query('md'){
	.#{$wf__ns}menu{
		&__link{
			padding-top: rem(10);
			padding-bottom: rem(10);
		}
		&__sticky{
			display: none;
		}
	}

}

@include media-query('sm'){
	.#{$wf__ns}menu{
		display: none;
		background-color: color(white);
		border-top:5px solid color(primary);
		// ELEMENT
		&__list{
		}
		&__it{
			float: none;
			//max-width: calc(100%/5);
			border-right: 0;
			margin-right:rem(-15);
			margin-left:rem(-15);
		}
		.#{$wf__ns}menu__link{
			display:block;
			color: $color02;
			padding: rem(15) rem(16);
			border: 0;
			border-bottom: 1px solid color(grey-4);
			text-align: left;
			margin-top:0;
			.#{$wf__ns}menu__text-link{
				display: inline-block;
				width:94%;
			}
			&:hover{
				text-decoration: none;
				background-color: color(white);
			}
			&:focus{
				border-radius: 0;
				border: 0;
				border-bottom: 1px solid color(grey-4);
				box-shadow: none;
			}
			.ppg-icon__crossW-3a{
				display: inline-block;
				width:5%;
				&:after{
					width: rem(11);
					height: rem(7);
					background-position: -5px -96px;
				}
			}
			&.active{
				color: $color02;
				background-color:$color01;
				.ppg-icon__crossW-3a:after{
					transform: rotate(180deg);
				}
			}
		}
		&__content{
			// relativo al body calculate w/ js
			display: none;
			padding: 0;
			position: inherit;
			border: 0;
		}
		&__list-2l{
			background-color: color(grey-11);
			//margin-right:-15px;
			//margin-left:-15px;
		}
		&__it-2l{
			margin-bottom: 0;
			padding-right:rem(16);
			padding-left:rem(16);
		}
		&__link-2l{
			display: block;
			font-family: $wf__font--default;
			font-size: rem(14);
			color: color(secondary);
			padding: rem(12) rem(10);
			border-bottom: 1px solid color(grey-4);
			&:before{
				content: none;
			}
		}
		&__sticky{
			display: block;
			background-color: color(grey-3);
			&:after, &:before{
				content: '';
				clear: both;
				display: block;
			}
			.#{$wf__ns}menu__stk-item{
				float: left;
				width: 40%;
				&:first-child, &:last-child{
					width:30%;
				}
				&:last-child{
					.#{$wf__ns}menu__stk-link{
						&:after{
							border-right: 0;
						}
					}
				}
			}
			.#{$wf__ns}menu__stk-link{
				position: relative;
				padding-top: rem(15);
				padding-bottom: rem(15);
				font-size: rem(14);
				display: block;
				color: color(secondary);
				text-align: center;
					&:after{
						position: absolute;
						right: 0;
						top: 50%;
						content: '';
						width: rem(2);
						height: 40%;
						border-right: 1px solid color(grey-4);
						transform: translate(0,-50%);
					}
				&.stk--menu{
					&:before{
						background: url($wf__base-path-icons-files + 'icon-menu-mobile.png') no-repeat center center;
						display: inline-block;
						vertical-align: middle;
						margin-right: rem(5);
						content: '';
						width: rem(25);
						height: rem(25);
					}
				}
				&.stk--folder{
					&:before{
						background: url(../assets/img/icons/spritesheet.png) no-repeat -520px -230px;
						display: inline-block;
						vertical-align: middle;
						margin-right: rem(5);
						content: '';
						width: rem(40);
						height: rem(25);
					}
				}
				&.stk--help{
					&:before{
						display: inline-block;
						vertical-align: middle;
						margin-right: rem(5);
						content: '';
						background: url(../assets/img/icons/spritesheet.png) no-repeat -531px -5px;;
						width: rem(25);
						height: rem(25);
					}
				}
				&.active{
					background-color: color(primary);
					color: color(white);
					text-decoration: none;
					&.stk--menu{
						&:before{
							background: url($wf__base-path-icons-files + 'aspa.png') no-repeat center center;
						}
					}

				}
			}
		}
		.#{$wf__ns}menu__secondary{
			display: block;
			background-color: color(grey-11);
			.#{$wf__ns}menu__it{
				padding-left: rem(20);
			}
			.ppg-form__group{
				.ppg-form__label{
					display: inline-block;
					padding: 0;
					vertical-align: super;
				}
				.ui-selectmenu-button{
					display: inline-block;
					.ui-selectmenu-text {
						border:0;
						padding-bottom:0;
						background-color: transparent;
					}
				}
			}
		}
	}
}

@include media-query('xs'){

}
