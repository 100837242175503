/*----------------------------------------------
   # módulo accordion
------------------------------------------------*/

.#{$wf__ns}accordion{
    &__heading{
        position: relative;
        border: 1px solid color(grey-4);
        padding: rem(25) rem(50) rem(25) rem(25);
        margin-bottom:rem(20);
        color: color(secondary);
        font-size: rem(18);
        cursor: pointer;
        .ui-accordion-header-icon{
            position: absolute;
            //top: 50%;
            top: calc((100% - 25px)/2);
            right: rem(25);
            //transform: translate(0,-50%);
            &:before{
                display: inline-block;
                content: '';
                width:rem(14);
                height:rem(10);
                background:url($wf__base-path-icons-files + "icon-flecha-negra--2d.png") no-repeat center ;
            }
        }
        &:hover, &:focus{
            background-color: color(salmon);
            color: color(primary);
            .ui-accordion-header-icon{
                &:before{
                    background:url($wf__base-path-icons-files + "icon-flecha-roja--2d.png") no-repeat center;
                }
            }
        }
        &.ui-state-active{
            background-color: color(salmon);
            color: color(primary);
            .ui-accordion-header-icon{
                &:before{
                    transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    background:url($wf__base-path-icons-files + "icon-flecha-roja--2d.png") no-repeat center;
                }
            }
        }
    }
    &__content{
        //display: block;
        overflow: auto;
        padding: 0 rem(25) rem(25) rem(25);
    }

}

@include media-query('md'){

}


@include media-query('sm'){

}

@include media-query('xs'){

}
