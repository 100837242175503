//*----------------------------------------------
//      # ICON
//------------------------------------------------*/

[class*=" ppg-icon"],
[class^="ppg-icon"]{
	&:after{
		content:"";
		background-image: url($wf__base-path-icons + "spritesheet.png");
		background-repeat: no-repeat;
		display: inline-block;
        vertical-align: middle;
        margin-left: rem(5);
	}
}
//ELEMENT
.#{$wf__ns}icon{
	&__fb-a{
		&:after, &:before{
			width: 51px;
			height: 50px;
			background-position: -5px -5px;
		}
	}
	&__fb-b{
		&:after, &:before{
			width: 102px;
			height: 100px;
			background-position: -66px -5px;
		}
	}
	&__fb-c{
		&:after, &:before{
			width: 153px;
			height: 150px;
			background-position: -178px -5px;
		}
	}
	&__060-a{
		&:after, &:before{
			width: 40px;
			height: 40px;
			background-position: -341px -5px;
		}
	}
	&__060-b{
		&:after, &:before{
			width: 80px;
			height: 80px;
			background-position: -391px -5px;
		}
	}
	&__060-c{
		&:after, &:before{
			 width: 120px;
			height: 120px;
			background-position: -341px -95px;
		}
	}
	&__acc-a{
		&:after, &:before{
			width: 40px;
			height: 40px;
			background-position: -481px -5px;
		}
	}
	&__acc-b{
		&:after, &:before{
			width: 80px;
			height: 80px;
			background-position: -481px -55px;
		}
	}
	&__acc-c{
		&:after, &:before{
			width: 120px;
			height: 120px;
			background-position: -5px -225px;
		}
	}
	&__help-a{
		&:after, &:before{
			width: 25px;
			height: 25px;
			background-position: -531px -5px;
		}
	}
	&__help-b{
		&:after, &:before{
			width: 50px;
			height: 50px;
			background-position: -5px -145px;
		}
	}
	&__help-c{
		&:after, &:before{
			width: 75px;
			height: 75px;
			background-position: -471px -145px;
		}
	}
	&__bullet-a{
		&:after, &:before{
			width: 8px;
			height: 10px;
			background-position: -566px -5px;
		}
	}
	&__bullet-b{
		&:after, &:before{
			width: 14px;
			height: 14px;
			background-position: -566px -22px;
		}
	}
	&__bullet-c{
		&:after, &:before{
			width: 21px;
			height: 21px;
			background-position: -65px -145px;
		}
	}
	&__mailbox-a{
		&:after, &:before{
			width: 24px;
			height: 18px;
			background-position: -96px -145px;
		}
	}
	&__mailbox-b{
		&:after, &:before{
			width: 48px;
			height: 36px;
			background-position: -135px -230px;
		}
	}
	&__mailbox-c{
		&:after, &:before{
			width: 72px;
			height: 54px;
			background-position: -193px -230px;
		}
	}
	&__mailgrey-a{
		&:after, &:before{
			width: 22px;
			height: 17px;
			background-position: -130px -145px;
		}
	}
	&__mailgrey-b{
		&:after, &:before{
			width: 44px;
			height: 34px;
			background-position: -275px -230px;
		}
	}
	&__mailgrey-c{
		&:after, &:before{
			width: 66px;
			height: 51px;
			background-position: -329px -230px;
		}
	}
	&__calendar-a{
		&:after, &:before{
			width: 19px;
			height: 20px;
			background-position: -556px -145px;
		}
	}
	&__calendar-b{
		&:after, &:before{
			width: 38px;
			height: 40px;
			background-position: -405px -230px;
		}
	}
	&__calendar-c{
		&:after, &:before{
			width: 57px;
			height: 60px;
			background-position: -453px -230px;
		}
	}
	&__folder-a{
		&:after, &:before{
			width: 40px;
			height: 27px;
			background-position: -520px -230px;
		}
	}
	&__folder-b{
		&:after, &:before{
			width: 80px;
			height: 54px;
			background-position: -135px -300px;
		}
	}
	&__folder-c{
		&:after, &:before{
			 width: 120px;
			height: 81px;
			background-position: -225px -304px;
		}
	}
	&__chat-a{
		&:after, &:before{
			width: 23px;
			height: 21px;
			background-position: -96px -175px;
		}
	}
	&__chat-b{
		&:after, &:before{
			width: 46px;
			height: 42px;
			background-position: -355px -304px;
		}
	}
	&__chat-c{
		&:after, &:before{
			width: 69px;
			height: 63px;
			background-position: -411px -304px;
		}
	}
	&__share-a{
		&:after, &:before{
			width: 18px;
			height: 18px;
			background-position: -129px -175px;
		}
	}
	&__share-b{
		&:after, &:before{
			width: 36px;
			height: 36px;
			background-position: -490px -304px;
		}
	}
	&__share-c{
		&:after, &:before{
			width: 54px;
			height: 54px;
			background-position: -490px -350px;
		}
	}
	&__download-a{
		&:after, &:before{
			width: 14px;
			height: 21px;
			background-position: -157px -175px;
		}
	}
	&__download-b{
		&:after, &:before{
			width: 28px;
			height: 42px;
			background-position: -5px -414px;
		}
	}
	&__download-c{
		&:after, &:before{
			width: 42px;
			height: 63px;
			background-position: -43px -414px;
		}
	}
	&__address-a{
		&:after, &:before{
			width: 22px;
			height: 29px;
			background-position: -181px -175px;
		}
	}
	&__address-b{
		&:after, &:before{
			width: 44px;
			height: 58px;
			background-position: -95px -414px;
		}
	}
	&__address-c{
		&:after, &:before{
			width: 66px;
			height: 87px;
			background-position: -149px -414px;
		}
	}
	&__link-a{
		&:after, &:before{
			width: 16px;
			height: 16px;
			background-position: -213px -175px;
		}
	}
	&__link-b{
		&:after, &:before{
			width: 32px;
			height: 32px;
			background-position: -536px -304px;
		}
	}
	&__link-c{
		&:after, &:before{
			width: 48px;
			height: 48px;
			background-position: -225px -414px;
		}
	}
	&__listen-a{
		&:after, &:before{
			width: 23px;
			height: 20px;
			background-position: -239px -175px;
		}
	}
	&__listen-b{
		&:after, &:before{
			width: 46px;
			height: 40px;
			background-position: -283px -414px;
		}
	}
	&__listen-c{
		&:after, &:before{
			width: 69px;
			height: 60px;
			background-position: -339px -414px;
		}
	}
	&__crossW-2a{
		&:after, &:before{
			width: 8px;
			height: 11px;
			background-position: -571px -46px;
		}
	}
	&__crossW-2a-Light{
		&:after, &:before{
			width: 8px;
			height: 11px;
			background-position: -5px -67px;
		} 
	}
	&__crossW-2b{
		&:after, &:before{
			width: 16px;
			height: 22px;
			background-position: -272px -175px;
		}
	}
	&__crossW-2b-Light{
		&:after, &:before{
			width: 16px;
			height: 27px;
			background-position: -298px -175px;
		} 
	}
	&__crossW-2c{
		&:after, &:before{
			width: 24px;
			height: 33px;
			background-position: -556px -175px;
		}
	}
	&__crossW-3{
		&:after, &:before{
			width: 11px;
			height: 7px;
			background-position: -554px -346px;
		}
	}
	&__crossW-3a{
		&:after, &:before{
			width: 11px;
			height: 7px;
			background-position: -5px -363px;
		}
	}
	&__crossW-3b{
		&:after, &:before{
			width: 22px;
			height: 14px;
			background-position: -26px -363px;
		}
	}
	&__crossW-3c{
		&:after, &:before{
			width: 33px;
			height: 21px;
			background-position: -418px -414px;
		}
	}
	&__crossW-4{
		&:after, &:before{
			width: 16px;
			height: 27px;
			background-position: -58px -363px;
		}
	}
	&__crossDisable-4aLight{
		&:after, &:before{
			width: 16px;
			height: 27px;
			background-position: -84px -363px;
		}
	}
	&__crossDisable-4bLight{
		&:after, &:before{
			width: 32px;
			height: 54px;
			background-position: -461px -414px;
		}
	}
	&__crossDisable-4cLight{
		&:after, &:before{
			width: 48px;
			height: 81px;
			background-position: -503px -414px;
		}
	}
	&__crossB-2a{
		&:after, &:before{
			width: 8px;
			height: 11px;
			background-position: -23px -67px;
		}
	}
	&__crossB-2b{
		&:after, &:before{
			width: 16px;
			height: 22px;
			background-position: -355px -363px;
		}
	}
	&__crossB-2c{
		&:after, &:before{
			width: 24px;
			height: 33px;
			background-position: -554px -363px;
		}
	}
	&__crossB-3a-filled{
		&:after, &:before{
			width: 9px;
			height: 7px;
			background-position: -41px -67px;
		}
	}
	&__crossB-3b-filled{
		&:after, &:before{
			width: 18px;
			height: 14px;
			background-position: -561px -406px;
		}
	}
	&__crossB-3c-filled{
		&:after, &:before{
			width: 27px;
			height: 21px;
			background-position: -5px -505px;
		}
	}
	&__crossB-3c-filled{
		&:after, &:before{
			width: 27px;
			height: 21px;
			background-position: -5px -505px;
		}
	}
	&__crossR-1a{
		&:after, &:before{
			width: 11px;
			height: 7px;
			background-position: -110px -363px;
		}
	}
	&__crossR-1b{
		&:after, &:before{
			width: 22px;
			height: 14px;
			background-position: -42px -505px;
		}
	}
	&__crossR-1c{
		&:after, &:before{
			width: 33px;
			height: 21px;
			background-position: -74px -505px;
		}
	}
	&__crossR-2a{
		&:after, &:before{
			width: 8px;
			height: 11px;
			background-position: -341px -67px;
		}
	}
	&__crossR-2aLight{
		&:after, &:before{
			width: 16px;
			height: 27px;
			background-position: -561px -430px;
		}
	}
	&__crossR-2b{
		&:after, &:before{
			width: 16px;
			height: 22px;
			background-position: -5px -467px;
		}
	}
	&__crossR-2bLight{
		&:after, &:before{
			width: 32px;
			height: 54px;
			background-position: -225px -505px;
		}
	}
	&__crossR-2c{
		&:after, &:before{
			width: 24px;
			height: 33px;
			background-position: -267px -505px;

		}
	}
	&__crossR-2cLight{
		&:after, &:before{
			width: 48px;
			height: 81px;
			background-position: -590px -5px;
		}
	}
	&__crossR-3a{
		&:after, &:before{
			width: 11px;
			height: 7px;
			background-position: -5px -96px;
		}
	}
	&__crossR-3a-filled{
		&:after, &:before{
			width: 9px;
			height: 6px;
			background-position: -26px -96px;
		}
	}
	&__crossR-3b{
		&:after, &:before{
			width: 22px;
			height: 14px;
			background-position: -571px -96px;
		}
	}
	&__crossR-3b-filled{
		&:after, &:before{
			width: 18px;
			height: 12px;
			background-position: -603px -96px;
		}
	}
	&__crossR-3c{
		&:after, &:before{
			width: 33px;
			height: 21px;
			background-position: -603px -118px;
		}
	}
	&__crossR-3c-filled{
		&:after, &:before{
			width: 27px;
			height: 18px;
			background-position: -590px -149px;
		}
	}
	&__timetable-a{
		&:after, &:before{
			width: 19px;
			height: 19px;
			background-position: -65px -177px;
		}
	}
	&__timetable-b{
		&:after, &:before{
			width: 38px;
			height: 38px;
			background-position: -590px -177px;
		}
	}
	&__timetable-c{
		&:after, &:before{
			width: 57px;
			height: 57px;
			background-position: -570px -225px;
		}
	}
	&__print-a{
		&:after, &:before{
			width: 21px;
			height: 19px;
			background-position: -578px -292px;
		}
	}
	&__print-b{
		&:after, &:before{
			width: 42px;
			height: 38px;
			background-position: -588px -321px;
		}
	}
	&__print-c{
		&:after, &:before{
			width: 63px;
			height: 57px;
			background-position: -561px -467px;
		}
	}
	&__map-a{
		&:after, &:before{
			width: 28px;
			height: 28px;
			background-position: -131px -369px;
		}
	}
	&__map-b{
		&:after, &:before{
			width: 56px;
			height: 56px;
			background-position: -117px -534px;
		}
	}
	&__map-c{
		&:after, &:before{
			width: 84px;
			height: 84px;
			background-position: -301px -505px;
		}
	}
	&__position-a{
		&:after, &:before{
			width: 24px;
			height: 25px;
			background-position: -169px -369px;
		}
	}
	&__position-b{
		&:after, &:before{
			width: 48px;
			height: 50px;
			background-position: -589px -369px;
		}
	}
	&__position-c{
		&:after, &:before{
			width: 72px;
			height: 75px;
			background-position: -395px -534px;
		}
	}
	&__search-r{
		&:after, &:before{
			width: 21px;
			height: 23px;
			background-position: -589px -429px;
		}
	}
	&__search-ra{
		&:after, &:before{
			width: 21px;
			height: 23px;
			background-position: -418px -462px;
		}
	}
	&__search-rb{
		&:after, &:before{
			width: 42px;
			height: 46px;
			background-position: -477px -534px;
		}
	}
	&__search-rc{
		&:after, &:before{
			width: 63px;
			height: 69px;
			background-position: -529px -534px;
		}
	}
	&__search-w{
		&:after, &:before{
			width: 21px;
			height: 23px;
			background-position: -42px -534px;
		}
	}
	&__search-wa{
		&:after, &:before{
			width: 21px;
			height: 23px;
			background-position: -183px -534px;
		}
	}
	&__search-wb{
		&:after, &:before{
			width: 42px;
			height: 46px;
			background-position: -5px -613px;
		}
	}
	&__search-wc{
		&:after, &:before{
			width: 63px;
			height: 69px;
			background-position: -648px -5px;
		}
	}
	&__tlf-a{
		&:after, &:before{
			width: 22px;
			height: 23px;
			background-position: -648px -84px;
		}
	}
	&__tlf-b{
		&:after, &:before{
			width: 44px;
			height: 46px;
			background-position: -646px -117px;
		}
	}
	&__tlf-c{
		&:after, &:before{
			width: 66px;
			height: 69px;
			background-position: -638px -173px;
		}
	}
	&__tlfGrey-a{
		&:after, &:before{
			width: 22px;
			height: 22px;
			background-position: -680px -84px;
		}
	}
	&__tlfGrey-b{
		&:after, &:before{
			width: 44px;
			height: 44px;
			background-position: -637px -252px;
		}
	}
	&__tlfGrey-c{
		&:after, &:before{
			width: 66px;
			height: 66px;
			background-position: -634px -429px;
		}
	}
	&__tree-a{
		&:after, &:before{
			width: 28px;
			height: 20px;
			background-position: -640px -306px;

		}
	}
	&__tree-b{
		&:after, &:before{
			width: 56px;
			height: 40px;
			background-position: -647px -336px;

		}
	}
	&__tree-c{
		&:after, &:before{
			width: 84px;
			height: 60px;
			background-position: -602px -534px;

		}
	}
}

//MODIFIER
.#{$wf__ns}icon--before{
    &:after{
        content: none;
    }
    &:before{
        content:'';
        background-image: url($wf__base-path-icons + "spritesheet.png");
        background-repeat: no-repeat;
        display: inline-block;
        vertical-align: middle;
        margin-right: rem(5);
    }
}
.#{$wf__ns}icon--rotate{
	&:after,&:before{
		-ms-transform: rotate(180deg); 
		-webkit-transform: rotate(180deg); 
		transform: rotate(180deg);
	}
}

.ppg-icon__help{
	padding-left:rem(65);
    min-height: rem(51);
    padding-right:rem(40);
	position:relative;
    &:before{
		position:absolute;
		left:0;
		top: 0;
        display: inline-block;
        margin-right: rem(13);
        width:rem(52);
        height:rem(51);
        border-radius: 50%;
        vertical-align: middle;
        content: url($wf__base-path-contents + 'support.png');
    }
    &:after{
        display:none;
    }
    &--text{
        display: inline-block;
        &:before,&:after{
            display:none;
        }
    }
}
.ppg-icon__job{
    &:before{
        display: inline-block;
        margin-right: rem(13);
        width:rem(50);
        height:rem(50);
        border-radius: 50%;
        vertical-align: middle;
        content: url($wf__base-path-contents + 'pae-app.png');
    }
}

// MODIFIER
/*
	Modificador para los iconos en el menu principal
*/

.#{$wf__ns}menu{
	.#{$wf__ns}menu__link{
		.ppg-icon__crossW-3a{
			display: table-cell;
			vertical-align: middle;
		}
	}	
}

// End icons.scss
