@if $include__trumps--float {


     /*------------------------------------
         # TRUMPS FLOAT
     ------------------------------------*/

     /**
     * Float left.
     */
     .t-float-left {
         float: left !important;
     }

     /**
      * Float right.
      */
     .t-float-right {
         float: right !important;
     }

     /**
      * Float none.
      */
     .t-float-none {
         float: none !important;
     }


     /*------------------------------------
         # END TRUMPS FLOAT
     ------------------------------------*/

 }
