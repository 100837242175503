/*----------------------------------------------
   # módulo select: se sobreescriben los estilos del select custom de jquery ui
------------------------------------------------*/

.ui-selectmenu-text{
	background: color(white);
	padding:rem(7.5);
	border-radius:3px;
	border:1px solid color(grey-9);
	box-sizing: border-box;
	cursor: pointer;
	height: rem(40);
}

.ui-selectmenu-menu{
	//padding-top: 42px;
	.ui-menu{
		width: rem(220);
		border-radius: 3px;
		border: 1px solid color(grey-4);
		background: color(white);
		box-shadow: 0 2px 4px 0 #9b9b9b;
		max-height:rem(185);
		//position: absolute;
		//top: 42px;
		
	}
	.ui-menu-item{
		padding:rem(8);
		border-bottom: 1px solid color(grey-4);
		cursor: pointer;
		&:hover{
			background: color(salmon);
		}
		&:last-child{
			border:none;
		}
	}
	.ui-menu-item-wrapper{
		&.ui-state-active{
			font-weight:bold;
		}
	}
}

.ui-selectmenu-button{
	&.ui-button{
		width:100%;
		display: block;
		position: relative;
		&:focus{
			outline:none;
			.ui-selectmenu-text{
				box-shadow: 0 0 5px 0 #c33400;
				border: solid 1px color(primary);
			}
		}
	}
}


.#{$wf__ns}header{
	.ui-selectmenu-text{
		border: 1px solid transparent;
		&:hover{
			background: color(salmon);
		}
		
	}
}

.#{$wf__ns}select{
	&--form{
		.ui-selectmenu-text{
			margin-right:0;
			position: relative;
			display: block;
			&:after{
				content:"";
				background-image: url($wf__base-path-icons + "spritesheet.png");
				background-repeat: no-repeat;
				display: inline-block;
				vertical-align: middle;
				margin-left: rem(5);
				width: 9px;
				height: 7px;
				background-position: -41px -67px;
				position: absolute;
				top:50%;
				right:rem(10);
				-webkit-transform: translate(0,-50%);
				-moz-transform: translate(0,-50%);
				-ms-transform: translate(0,-50%);
				-o-transform: translate(0,-50%);
				transform: translate(0,-50%);
				
			}
		}
	}
}



@include media-query('md'){
	.ui-selectmenu-button{
		&.ui-button{
			display: block;
		}
	}
}


@include media-query('sm'){
	.ui-selectmenu-button{
		&.ui-button{
			display: block;
		}
	}
}

@include media-query('xs'){

}
