@charset "UTF-8";
/* mixin for multiline */
/*------------------------------------------------------------------------
    # GENERIC BOX-SIZING
-------------------------------------------------------------------------*/
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

/*------------------------------------------------------------------------
    # END GENERIC BOX-SIZING
-------------------------------------------------------------------------*/
/*------------------------------------*    # GENERIC CLEARFIX
\*------------------------------------*/
/**
 * Micro clearfix, as per: css-101.org/articles/clearfix/latest-new-clearfix-so-far.php
 * Extend the clearfix placeholder class `%clearfix` with Sass to avoid the `.clearfix` class appearing
 * over and over in your markup.
 */
.clearfix:after,
.o-layout:after,
.o-list--inline:after,
.o-list--rrss .o-list__box:after,
.o-list--header .o-list__box:after,
.ppg-form__fieldset:after,
.ppg-info__contet-text-area:after,
.ppg-menu__list:after,
.o-layout--dynamic .ppg-slick:after,
.ppg-table__list:after,
.ppg-tabs .ppg-tabs__list:after,
.ppg-tabs--type2.ppg-tabs .ppg-tabs__list:after {
  content: "";
  display: block;
  clear: both; }

/*------------------------------------*    # END GENERIC CLEARFIX
\*------------------------------------*/
/*------------------------------------------------------------------------
     # GENERIC HIDE ACCESSIBLE
 -------------------------------------------------------------------------*/
.hideAccessible {
  position: absolute;
  left: -9999em; }

/*------------------------------------------------------------------------
    # END GENERIC HIDE ACCESSIBLE
-------------------------------------------------------------------------*/
/*--------------------------------
	# GENERIC RESET
--------------------------------*/
html,
body,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
article,
aside,
details,
dialog,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary,
audio,
canvas,
video,
mark,
code,
kbd,
pre,
samp,
a,
abbr,
acronym,
address,
code,
del,
dfn,
em,
strong,
img,
q,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
div {
  margin: 0;
  padding: 0;
  border: none;
  font-weight: normal;
  font-style: normal;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  text-decoration: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

input,
button,
select,
textarea {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-style: normal;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  text-decoration: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

ul,
ol,
dl {
  list-style: none; }

/*-----------------------------------
	#TAGS HTML5 BROWSER NO SUPPORT
-------------------------------------*/
article,
aside,
details,
dialog,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

dfn {
  font-style: italic; }

mark {
  background-color: #ff0;
  color: #000; }

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1.6em; }

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic; }

/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none; }

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/*-----------------------------------
	#LINKS
-------------------------------------*/
a {
  cursor: pointer;
  background-color: transparent;
  text-decoration-skip: objects;
  -webkit-text-decoration-skip: objects; }

a:focus,
a:hover {
  text-decoration: underline; }

a:visited,
a:active {
  text-decoration: none; }

a:active,
a:hover {
  outline-width: 0; }

/*-----------------------------------
	#HEADINGS, WITH AND WITHOUT LINK
-------------------------------------*/
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit; }

/*-----------------------------------
	#RESET TABLE
-------------------------------------*/
table {
  border-collapse: collapse;
  border-spacing: 0; }

/*-----------------------------------
	#RESET FORM
-------------------------------------*/
/**
 * 1. Change the font styles in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible; }

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none; }

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

/**
 * Change the border, margin, and padding in all browsers (opinionated).
 */
fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */ }

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto; }

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

[type="radio"] + label,
[type="checkbox"] + label {
  cursor: pointer; }

/*-----------------------------------
	#RESET INTERACTIVE
-------------------------------------*/
/*
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 */
details,
menu {
  display: block; }

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item; }

/*-----------------------------------
	#RESET SCRIPTING
-------------------------------------*/
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block; }

/**
 * Add the correct display in IE.
 */
template {
  display: none; }

/*-----------------------------------
	#RESET HIDDEN
-------------------------------------*/
/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none; }

/*--------------------------------
	# END GENERIC RESET
--------------------------------*/
/*------------------------------------
     # ELEMENT BODY
 ------------------------------------*/
body {
  position: relative;
  font-family: Arial, Helvetica, sans-serif;
  color: black;
  background-color: #f8f8f8; }

/*------------------------------------
    # END ELEMENT BODY
------------------------------------*/
/*-------------------------------------------
 	# ELEMENT FONT GENERATOR
 ---------------------------------------------*/
/*-------------------------------------------
    # END ELEMENT FONT GENERATOR
---------------------------------------------*/
/*----------------------------------------------------------------
     # ELEMENT HEADINGS
 ------------------------------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  color: black;
  line-height: 1.5; }

h1 {
  font-size: 2rem; }

h2 {
  font-size: 1.5rem; }

h3 {
  font-size: 1.25rem; }

h4 {
  font-size: 1.125rem; }

h5 {
  font-size: 0.8125rem; }

h6 {
  font-size: 0.6875rem; }

/*----------------------------------------------------------------
     # END ELEMENT HEADINGS
 ------------------------------------------------------------------*/
/*------------------------------------
    # ELEMENT HTML
------------------------------------*/
html {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  position: relative; }

.bck-grey {
  background-color: #f8f8f8;
  margin-top: 0 !important;
  padding-top: 46px; }

/*------------------------------------
    # END ELEMENT HTML
------------------------------------*/
/*------------------------------------
    # ELEMENT IMAGE
------------------------------------*/
img {
  max-width: 100%;
  font-style: italic;
  vertical-align: middle; }

img,
img[width],
img[height] {
  max-width: none; }

/*------------------------------------
    # END ELEMENT IMAGE
------------------------------------*/
/*------------------------------------
    # ELEMENT LISTS
-------------------------------------*/
li > ul,
li > ol {
  margin-bottom: 0; }

/*------------------------------------
    # END ELEMENT LISTS
-------------------------------------*/
/*------------------------------------
    # OBJECTS AVATAR
------------------------------------*/
.o-avatar {
  display: table; }
  .o-avatar__item {
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: top;
    display: table-cell; }
    .o-avatar__item--type02 {
      width: 50%; }
    .o-avatar__item--flush-pd {
      padding: 0; }
  .o-avatar--middle .o-avatar__item {
    vertical-align: middle; }
  .o-avatar--base .o-avatar__item {
    vertical-align: baseline; }

@media only screen and (max-width: 767px) {
  .o-avatar--type02 {
    padding: 12px 12px 12px 0; }
    .o-avatar--type02 .o-avatar__item {
      display: block; }
      .o-avatar--type02 .o-avatar__item:first-child {
        vertical-align: top;
        display: table-cell; }
    .o-avatar--type02 .o-avatar__item--type02 {
      width: 100%;
      margin-bottom: 5px; } }

/*------------------------------------
    # END OBJECTS AVATAR
------------------------------------*/
/*------------------------------------
    # OBJECT BOX
------------------------------------*/
.o-box {
  width: 100%;
  border: 1px solid #dddddd;
  background-color: white;
  padding: 20px 15px;
  margin-bottom: 20px; }
  .o-box--type01 {
    border: 0;
    padding: 20px 0;
    margin-bottom: 0; }
  .o-box--flush-pd {
    padding: 0; }

@media only screen and (max-width: 1023px) {
  .o-box--type02 {
    width: 32.9%;
    display: inline-block;
    vertical-align: top; }
  .o-box__img {
    width: 50%;
    display: block;
    padding-bottom: 5px;
    margin: 0 auto; } }

@media only screen and (max-width: 767px) {
  .o-box--type02 {
    width: 100%;
    display: block; } }

/*------------------------------------
    # END OBJECT BOX
------------------------------------*/
/*------------------------------------
    # OBJECTS CARD
------------------------------------*/
.o-card {
  background-color: white;
  border: 1px solid #c9c6c6;
  padding: 10px;
  margin-bottom: 20px; }
  .o-card__content {
    padding: 9px 10px; }
  .o-card__picture img {
    width: 100%; }
  .o-card__title {
    margin-bottom: 9px; }
  .o-card__text {
    max-height: 3.4375rem;
    overflow: hidden;
    margin-bottom: 15px; }
  .o-card .o-card__box {
    display: block;
    /* Fallback for non-webkit */
    display: -webkit-box;
    max-width: 204px;
    height: 36.12px;
    /* Fallback for non-webkit */
    font-size: 14px;
    line-height: 1.29;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; }
  .o-card--slick {
    width: 316px;
    height: 375px; }
    .o-card--slick:hover {
      box-shadow: 0 3px 4px 0 rgba(68, 68, 68, 0.38);
      border: solid 1px #c33400; }
    .o-card--slick:active {
      box-shadow: 0 3px 4px 0 rgba(68, 68, 68, 0.38);
      border: solid 1px #c33400; }
    .o-card--slick:focus {
      box-shadow: 0 0 11px 0 #c33400;
      border: solid 1px #bc3302; }
  .o-card--type2 {
    position: relative; }
    .o-card--type2 .o-card__picture {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 0; }
    .o-card--type2 .o-card__content {
      position: relative;
      z-index: 1; }
    .o-card--type2 .o-card__text {
      width: 70%; }
  .o-card--type3 {
    border: 1px solid #dddddd; }
    .o-card--type3 .o-card__picture {
      width: 60%;
      display: inline-block;
      vertical-align: top; }
    .o-card--type3 .o-card__content {
      width: 39%;
      display: inline-block;
      vertical-align: top; }
    .o-card--type3 .o-card__title {
      margin-bottom: 10px; }
    .o-card--type3 .o-card__text {
      margin-bottom: 30px; }

.ppg-slick__link:focus {
  outline: none; }
  .ppg-slick__link:focus .o-card--slick {
    box-shadow: 0 0 11px 0 #c33400;
    border: solid 1px #bc3302;
    outline: none; }

@media only screen and (max-width: 1279px) {
  .o-card--slick {
    width: 351.1px;
    height: 431.3px; }
  .o-layout--dynamic .ppg-slick .o-card--slick {
    width: 305px; } }

@media only screen and (max-width: 767px) {
  .o-card--slick {
    width: 300px;
    height: 366.4px; }
  .o-card--type3 {
    border: 1px solid #dddddd; }
    .o-card--type3 .o-card__picture {
      width: 100%;
      display: block;
      vertical-align: top; }
    .o-card--type3 .o-card__content {
      width: 100%;
      display: inline-block;
      vertical-align: top; }
    .o-card--type3 .o-card__title {
      margin-bottom: 10px; }
    .o-card--type3 .o-card__text {
      margin-bottom: 30px; } }

/*------------------------------------
    # OBJECT LAYOUT UNO
------------------------------------*/
.o-layout {
  max-width: 990px;
  margin: auto;
  margin-top: 20px; }
  .o-layout__jumbo {
    position: relative;
    width: 100%;
    float: left;
    margin-bottom: 20px; }
  .o-layout__content {
    position: relative;
    width: 65.8%;
    float: left;
    margin-right: 19.5px; }
  .o-layout__sidebar {
    position: relative;
    width: 32%;
    float: left; }
  .o-layout--dynamic .o-layout__jumbo {
    width: 65%; }
  .o-layout--dynamic .o-layout__sidebar {
    float: none;
    display: inline-block; }

@media only screen and (max-width: 1279px) {
  .o-layout {
    width: 100%; }
    .o-layout__jumbo {
      margin-top: 4.1875rem;
      padding-left: 1.3125rem;
      padding-right: 1.3125rem; }
    .o-layout--dynamic .o-layout__jumbo {
      padding: 0; }
    .o-layout--dynamic .o-layout__sidebar {
      margin-top: 4.1875rem; } }

@media only screen and (max-width: 1023px) {
  .o-layout {
    padding: 0 0.625rem; }
    .o-layout__jumbo {
      float: none;
      display: block; }
    .o-layout__content {
      width: 100%;
      float: none;
      display: block; }
    .o-layout__sidebar {
      width: 100%;
      float: none;
      display: block; }
    .o-layout--dynamic .o-layout__jumbo {
      width: 100%;
      text-align: center; }
    .o-layout--dynamic .o-layout__sidebar {
      display: block; } }

@media only screen and (max-width: 767px) {
  .o-layout {
    padding: 0; }
    .o-layout__jumbo {
      margin-top: 5.1875rem;
      padding: 0 10px; }
    .o-layout__content {
      padding: 0 0.625rem; }
    .o-layout--sm-nomargin {
      margin-top: 0; }
    .o-layout__content--type02, .o-layout__sidebar--type02 {
      padding: 0; } }

/*------------------------------------
    # END OBJECT LAYOUT UNO
------------------------------------*/
/*------------------------------------
    # OBJECT LAYOUT LIST
------------------------------------*/
.o-list {
  background-color: white;
  border: 1px solid #dddddd;
  padding: 0 15px 20px;
  margin-bottom: 20px; }
  .o-list__name {
    border-bottom: 1px solid #dddddd;
    padding-top: 15px;
    padding-bottom: 8px;
    margin-bottom: 15px; }
  .o-list__item {
    border-bottom: 1px solid #dddddd;
    margin-bottom: 15px; }
    .o-list__item:last-child {
      border-bottom: 0; }
  .o-list--aside.o-list {
    padding-bottom: 0; }
    .o-list--aside.o-list .o-list__name {
      margin-bottom: 0; }
    .o-list--aside.o-list .o-list__item {
      padding-top: 10px;
      padding-bottom: 10px;
      margin-bottom: 0; }
  .o-list--inline .o-list__item {
    float: left;
    border: 0;
    padding-top: 10px;
    padding-bottom: 10px; }
    .o-list--inline .o-list__item:first-child {
      padding-left: 0; }
    .o-list--inline .o-list__item:last-child {
      padding-right: 0; }
  .o-list--inline .o-list--flush-pd {
    padding: 0; }
  .o-list--flush {
    padding: 0;
    border: 0;
    margin: 0; }
  .o-list--flush-br {
    border: 0; }
  .o-list--flush-pd {
    padding: 0; }
  .o-list--flush-mg {
    margin: 0; }
  .o-list--table {
    border: none;
    background-color: transparent; }
  .o-list--border {
    border-bottom: 1px solid #c1c1c1;
    border-top: 1px solid #e0ded9; }
  .o-list--border02 {
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd; }
    .o-list--border02 .o-list__item {
      margin-bottom: 0;
      padding-left: 1.25rem; }
    .o-list--border02 .o-lit__box {
      float: right; }
  .o-list--border03 {
    border-bottom: 1px solid #dddddd;
    margin-bottom: 15px; }
    .o-list--border03 .o-list__item {
      margin-bottom: 0;
      padding-left: 1.25rem; }
    .o-list--border03 .o-lit__box {
      float: right; }
  .o-list--type01.o-list .o-list__item {
    padding: 0; }
  .o-list--type01.o-list .o-list__name {
    width: 100%;
    padding-left: 20px; }
  .o-list--type01.o-list.o-list--inline .o-list__item {
    padding-top: 20px;
    padding-bottom: 20px; }
  .o-list--style {
    list-style-type: disc;
    color: #c33400; }
  .o-list--margin {
    margin-bottom: 3.75rem; }
  .o-list--rrss {
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #dddddd; }
    .o-list--rrss .o-list__box {
      float: right; }
    .o-list--rrss .o-list__item {
      position: relative;
      padding: 0;
      margin: 0; }
  .o-list--header .o-list__box {
    float: right; }
  .o-list--header .o-list__item {
    position: relative;
    padding: 0; }

.ppg-footer .o-list {
  background-color: transparent; }
  .ppg-footer .o-list.o-list--inline .o-list-item {
    border-bottom: 1px solid #c9c6c6;
    border-top: 1px solid #c9c6c6; }

.ppg-news .o-list__item {
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%; }
  .ppg-news .o-list__item:hover {
    background: #fff4f0; }

.ppg-table .o-list__item {
  margin-bottom: 12px; }

.ppg-table .o-list__item2 {
  border: 0;
  border-bottom: 1px solid #c1c1c1;
  margin: 0; }

.ppg-table .o-list__item3 {
  border-bottom: 0;
  margin-bottom: 0; }

.ppg-table .o-list__item4 {
  margin-bottom: 0; }

.ppg-table--type02 .o-list__item {
  margin-bottom: 0; }
  .ppg-table--type02 .o-list__item:first-child {
    padding-left: 15px; }

.o-list--aside .o-list__name {
  width: 100%; }

@media only screen and (max-width: 1023px) {
  .o-list--type02 {
    width: 32.9%;
    display: inline-block;
    vertical-align: top; }
  .o-list--type03 {
    width: 33%;
    display: inline-block; }
  .o-list--type04 {
    width: 64%;
    display: inline-block;
    vertical-align: top;
    margin-left: 20px; }
  .o-list--margin {
    margin-bottom: 1.25rem; } }

@media only screen and (max-width: 767px) {
  .o-list--type02 {
    width: 100%;
    display: block;
    vertical-align: top; }
  .o-list--type03, .o-list--type04 {
    width: 100%;
    margin-left: 0; }
  .o-list--type01.o-list.o-list--inline .o-list__item--type02 {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: 1px solid #dddddd; }
    .o-list--type01.o-list.o-list--inline .o-list__item--type02:last-child {
      border-bottom: 0; }
  .o-list--border02 .o-list__item {
    padding-left: 9px; }
  .o-list--border02 .o-lit__box {
    float: inherit; }
  .o-list--border03 .o-list__item {
    padding-left: 3px; }
  .o-list--border03 .o-lit__box {
    float: inherit; } }

/*------------------------------------
    # END OBJECT LIST
------------------------------------*/
/*------------------------------------------------------------
    # OBJECT NORA
-------------------------------------------------------------*/
.xs-inline-visible, .xs-visible, .sm-inline-visible, .sm-visible, .md-inline-visible, .md-visible, .lg-inline-visible, .lg-visible, .xl-inline-visible, .xl-visible {
  display: none !important; }

.row:before, .row:after {
  content: "";
  display: block;
  clear: both; }

@media (min-width: 320px) {
  .gridContainer {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem; }
  .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
    float: left;
    box-sizing: border-box; }
  [class*="col-"] {
    position: relative;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem; }
  .row {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem; }
    .row .row {
      margin-left: -0.9375rem;
      margin-right: -0.9375rem; }
  .col-xs-offset-0 {
    margin-left: 0%; }
  .col-xs-pull-0 {
    right: 0%; }
  .col-xs-push-0 {
    left: 0%; }
  .col-xs-offset-1 {
    margin-left: 8.33333%; }
  .col-xs-pull-1 {
    right: 8.33333%; }
  .col-xs-push-1 {
    left: 8.33333%; }
  .col-xs-offset-2 {
    margin-left: 16.66667%; }
  .col-xs-pull-2 {
    right: 16.66667%; }
  .col-xs-push-2 {
    left: 16.66667%; }
  .col-xs-offset-3 {
    margin-left: 25%; }
  .col-xs-pull-3 {
    right: 25%; }
  .col-xs-push-3 {
    left: 25%; }
  .col-xs-offset-4 {
    margin-left: 33.33333%; }
  .col-xs-pull-4 {
    right: 33.33333%; }
  .col-xs-push-4 {
    left: 33.33333%; }
  .col-xs-offset-5 {
    margin-left: 41.66667%; }
  .col-xs-pull-5 {
    right: 41.66667%; }
  .col-xs-push-5 {
    left: 41.66667%; }
  .col-xs-offset-6 {
    margin-left: 50%; }
  .col-xs-pull-6 {
    right: 50%; }
  .col-xs-push-6 {
    left: 50%; }
  .col-xs-offset-7 {
    margin-left: 58.33333%; }
  .col-xs-pull-7 {
    right: 58.33333%; }
  .col-xs-push-7 {
    left: 58.33333%; }
  .col-xs-offset-8 {
    margin-left: 66.66667%; }
  .col-xs-pull-8 {
    right: 66.66667%; }
  .col-xs-push-8 {
    left: 66.66667%; }
  .col-xs-offset-9 {
    margin-left: 75%; }
  .col-xs-pull-9 {
    right: 75%; }
  .col-xs-push-9 {
    left: 75%; }
  .col-xs-offset-10 {
    margin-left: 83.33333%; }
  .col-xs-pull-10 {
    right: 83.33333%; }
  .col-xs-push-10 {
    left: 83.33333%; }
  .col-xs-offset-11 {
    margin-left: 91.66667%; }
  .col-xs-pull-11 {
    right: 91.66667%; }
  .col-xs-push-11 {
    left: 91.66667%; }
  .col-xs-clear-right:after {
    content: "";
    display: block;
    clear: both; }
  .col-xs-clear-left:before {
    content: "";
    display: block;
    clear: both; }
  .col-xs-clear-all:before {
    content: "";
    display: block;
    clear: both; }
  .col-xs-clear-all:after {
    content: "";
    display: block;
    clear: both; }
  .col-xs-noGutter-right {
    padding-right: 0; }
  .col-xs-noGutter-left {
    padding-left: 0; }
  .col-xs-noGutter-all {
    padding-left: 0;
    padding-right: 0; }
  .col-xs-1 {
    width: 8.33333%; }
  .col-xs-2 {
    width: 16.66667%; }
  .col-xs-3 {
    width: 25%; }
  .col-xs-4 {
    width: 33.33333%; }
  .col-xs-5 {
    width: 41.66667%; }
  .col-xs-6 {
    width: 50%; }
  .col-xs-7 {
    width: 58.33333%; }
  .col-xs-8 {
    width: 66.66667%; }
  .col-xs-9 {
    width: 75%; }
  .col-xs-10 {
    width: 83.33333%; }
  .col-xs-11 {
    width: 91.66667%; }
  .col-xs-12 {
    width: 100%; } }

@media (min-width: 320px) and (max-width: 767px) {
  .xs-visible {
    display: block !important; }
  .xs-inline-visible {
    display: inline-block !important; }
  .xs-hide {
    display: none !important; }
  .col-xs-end {
    float: right; } }

@media (min-width: 768px) {
  .gridContainer {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem; }
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
    box-sizing: border-box; }
  [class*="col-"] {
    position: relative;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem; }
  .row {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem; }
    .row .row {
      margin-left: -0.9375rem;
      margin-right: -0.9375rem; }
  .col-sm-offset-0 {
    margin-left: 0%; }
  .col-sm-pull-0 {
    right: 0%; }
  .col-sm-push-0 {
    left: 0%; }
  .col-sm-offset-1 {
    margin-left: 8.33333%; }
  .col-sm-pull-1 {
    right: 8.33333%; }
  .col-sm-push-1 {
    left: 8.33333%; }
  .col-sm-offset-2 {
    margin-left: 16.66667%; }
  .col-sm-pull-2 {
    right: 16.66667%; }
  .col-sm-push-2 {
    left: 16.66667%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-pull-3 {
    right: 25%; }
  .col-sm-push-3 {
    left: 25%; }
  .col-sm-offset-4 {
    margin-left: 33.33333%; }
  .col-sm-pull-4 {
    right: 33.33333%; }
  .col-sm-push-4 {
    left: 33.33333%; }
  .col-sm-offset-5 {
    margin-left: 41.66667%; }
  .col-sm-pull-5 {
    right: 41.66667%; }
  .col-sm-push-5 {
    left: 41.66667%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-pull-6 {
    right: 50%; }
  .col-sm-push-6 {
    left: 50%; }
  .col-sm-offset-7 {
    margin-left: 58.33333%; }
  .col-sm-pull-7 {
    right: 58.33333%; }
  .col-sm-push-7 {
    left: 58.33333%; }
  .col-sm-offset-8 {
    margin-left: 66.66667%; }
  .col-sm-pull-8 {
    right: 66.66667%; }
  .col-sm-push-8 {
    left: 66.66667%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-pull-9 {
    right: 75%; }
  .col-sm-push-9 {
    left: 75%; }
  .col-sm-offset-10 {
    margin-left: 83.33333%; }
  .col-sm-pull-10 {
    right: 83.33333%; }
  .col-sm-push-10 {
    left: 83.33333%; }
  .col-sm-offset-11 {
    margin-left: 91.66667%; }
  .col-sm-pull-11 {
    right: 91.66667%; }
  .col-sm-push-11 {
    left: 91.66667%; }
  .col-sm-clear-right:after {
    content: "";
    display: block;
    clear: both; }
  .col-sm-clear-left:before {
    content: "";
    display: block;
    clear: both; }
  .col-sm-clear-all:before {
    content: "";
    display: block;
    clear: both; }
  .col-sm-clear-all:after {
    content: "";
    display: block;
    clear: both; }
  .col-sm-noGutter-right {
    padding-right: 0; }
  .col-sm-noGutter-left {
    padding-left: 0; }
  .col-sm-noGutter-all {
    padding-left: 0;
    padding-right: 0; }
  .col-sm-1 {
    width: 8.33333%; }
  .col-sm-2 {
    width: 16.66667%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-4 {
    width: 33.33333%; }
  .col-sm-5 {
    width: 41.66667%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-7 {
    width: 58.33333%; }
  .col-sm-8 {
    width: 66.66667%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-10 {
    width: 83.33333%; }
  .col-sm-11 {
    width: 91.66667%; }
  .col-sm-12 {
    width: 100%; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .sm-visible {
    display: block !important; }
  .sm-inline-visible {
    display: inline-block !important; }
  .sm-hide {
    display: none !important; }
  .col-sm-end {
    float: right; } }

@media (min-width: 1024px) {
  .gridContainer {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem; }
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
    box-sizing: border-box; }
  [class*="col-"] {
    position: relative;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem; }
  .row {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem; }
    .row .row {
      margin-left: -0.9375rem;
      margin-right: -0.9375rem; }
  .col-md-offset-0 {
    margin-left: 0%; }
  .col-md-pull-0 {
    right: 0%; }
  .col-md-push-0 {
    left: 0%; }
  .col-md-offset-1 {
    margin-left: 8.33333%; }
  .col-md-pull-1 {
    right: 8.33333%; }
  .col-md-push-1 {
    left: 8.33333%; }
  .col-md-offset-2 {
    margin-left: 16.66667%; }
  .col-md-pull-2 {
    right: 16.66667%; }
  .col-md-push-2 {
    left: 16.66667%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-pull-3 {
    right: 25%; }
  .col-md-push-3 {
    left: 25%; }
  .col-md-offset-4 {
    margin-left: 33.33333%; }
  .col-md-pull-4 {
    right: 33.33333%; }
  .col-md-push-4 {
    left: 33.33333%; }
  .col-md-offset-5 {
    margin-left: 41.66667%; }
  .col-md-pull-5 {
    right: 41.66667%; }
  .col-md-push-5 {
    left: 41.66667%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-pull-6 {
    right: 50%; }
  .col-md-push-6 {
    left: 50%; }
  .col-md-offset-7 {
    margin-left: 58.33333%; }
  .col-md-pull-7 {
    right: 58.33333%; }
  .col-md-push-7 {
    left: 58.33333%; }
  .col-md-offset-8 {
    margin-left: 66.66667%; }
  .col-md-pull-8 {
    right: 66.66667%; }
  .col-md-push-8 {
    left: 66.66667%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-pull-9 {
    right: 75%; }
  .col-md-push-9 {
    left: 75%; }
  .col-md-offset-10 {
    margin-left: 83.33333%; }
  .col-md-pull-10 {
    right: 83.33333%; }
  .col-md-push-10 {
    left: 83.33333%; }
  .col-md-offset-11 {
    margin-left: 91.66667%; }
  .col-md-pull-11 {
    right: 91.66667%; }
  .col-md-push-11 {
    left: 91.66667%; }
  .col-md-clear-right:after {
    content: "";
    display: block;
    clear: both; }
  .col-md-clear-left:before {
    content: "";
    display: block;
    clear: both; }
  .col-md-clear-all:before {
    content: "";
    display: block;
    clear: both; }
  .col-md-clear-all:after {
    content: "";
    display: block;
    clear: both; }
  .col-md-noGutter-right {
    padding-right: 0; }
  .col-md-noGutter-left {
    padding-left: 0; }
  .col-md-noGutter-all {
    padding-left: 0;
    padding-right: 0; }
  .col-md-1 {
    width: 8.33333%; }
  .col-md-2 {
    width: 16.66667%; }
  .col-md-3 {
    width: 25%; }
  .col-md-4 {
    width: 33.33333%; }
  .col-md-5 {
    width: 41.66667%; }
  .col-md-6 {
    width: 50%; }
  .col-md-7 {
    width: 58.33333%; }
  .col-md-8 {
    width: 66.66667%; }
  .col-md-9 {
    width: 75%; }
  .col-md-10 {
    width: 83.33333%; }
  .col-md-11 {
    width: 91.66667%; }
  .col-md-12 {
    width: 100%; } }

@media (min-width: 1024px) and (max-width: 1279px) {
  .md-visible {
    display: block !important; }
  .md-inline-visible {
    display: inline-block !important; }
  .md-hide {
    display: none !important; }
  .col-md-end {
    float: right; } }

@media (min-width: 1280px) {
  .gridContainer {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem; }
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
    box-sizing: border-box; }
  [class*="col-"] {
    position: relative;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem; }
  .row {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem; }
    .row .row {
      margin-left: -0.9375rem;
      margin-right: -0.9375rem; }
  .col-lg-offset-0 {
    margin-left: 0%; }
  .col-lg-pull-0 {
    right: 0%; }
  .col-lg-push-0 {
    left: 0%; }
  .col-lg-offset-1 {
    margin-left: 8.33333%; }
  .col-lg-pull-1 {
    right: 8.33333%; }
  .col-lg-push-1 {
    left: 8.33333%; }
  .col-lg-offset-2 {
    margin-left: 16.66667%; }
  .col-lg-pull-2 {
    right: 16.66667%; }
  .col-lg-push-2 {
    left: 16.66667%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-pull-3 {
    right: 25%; }
  .col-lg-push-3 {
    left: 25%; }
  .col-lg-offset-4 {
    margin-left: 33.33333%; }
  .col-lg-pull-4 {
    right: 33.33333%; }
  .col-lg-push-4 {
    left: 33.33333%; }
  .col-lg-offset-5 {
    margin-left: 41.66667%; }
  .col-lg-pull-5 {
    right: 41.66667%; }
  .col-lg-push-5 {
    left: 41.66667%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-pull-6 {
    right: 50%; }
  .col-lg-push-6 {
    left: 50%; }
  .col-lg-offset-7 {
    margin-left: 58.33333%; }
  .col-lg-pull-7 {
    right: 58.33333%; }
  .col-lg-push-7 {
    left: 58.33333%; }
  .col-lg-offset-8 {
    margin-left: 66.66667%; }
  .col-lg-pull-8 {
    right: 66.66667%; }
  .col-lg-push-8 {
    left: 66.66667%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-pull-9 {
    right: 75%; }
  .col-lg-push-9 {
    left: 75%; }
  .col-lg-offset-10 {
    margin-left: 83.33333%; }
  .col-lg-pull-10 {
    right: 83.33333%; }
  .col-lg-push-10 {
    left: 83.33333%; }
  .col-lg-offset-11 {
    margin-left: 91.66667%; }
  .col-lg-pull-11 {
    right: 91.66667%; }
  .col-lg-push-11 {
    left: 91.66667%; }
  .col-lg-clear-right:after {
    content: "";
    display: block;
    clear: both; }
  .col-lg-clear-left:before {
    content: "";
    display: block;
    clear: both; }
  .col-lg-clear-all:before {
    content: "";
    display: block;
    clear: both; }
  .col-lg-clear-all:after {
    content: "";
    display: block;
    clear: both; }
  .col-lg-noGutter-right {
    padding-right: 0; }
  .col-lg-noGutter-left {
    padding-left: 0; }
  .col-lg-noGutter-all {
    padding-left: 0;
    padding-right: 0; }
  .col-lg-1 {
    width: 8.33333%; }
  .col-lg-2 {
    width: 16.66667%; }
  .col-lg-3 {
    width: 25%; }
  .col-lg-4 {
    width: 33.33333%; }
  .col-lg-5 {
    width: 41.66667%; }
  .col-lg-6 {
    width: 50%; }
  .col-lg-7 {
    width: 58.33333%; }
  .col-lg-8 {
    width: 66.66667%; }
  .col-lg-9 {
    width: 75%; }
  .col-lg-10 {
    width: 83.33333%; }
  .col-lg-11 {
    width: 91.66667%; }
  .col-lg-12 {
    width: 100%; } }

@media (min-width: 1280px) and (max-width: 1399px) {
  .lg-visible {
    display: block !important; }
  .lg-inline-visible {
    display: inline-block !important; }
  .lg-hide {
    display: none !important; }
  .col-lg-end {
    float: right; } }

@media (min-width: 1400px) {
  .gridContainer {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem; }
  .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
    float: left;
    box-sizing: border-box; }
  [class*="col-"] {
    position: relative;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem; }
  .row {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem; }
    .row .row {
      margin-left: -0.9375rem;
      margin-right: -0.9375rem; }
  .col-xl-offset-0 {
    margin-left: 0%; }
  .col-xl-pull-0 {
    right: 0%; }
  .col-xl-push-0 {
    left: 0%; }
  .col-xl-offset-1 {
    margin-left: 8.33333%; }
  .col-xl-pull-1 {
    right: 8.33333%; }
  .col-xl-push-1 {
    left: 8.33333%; }
  .col-xl-offset-2 {
    margin-left: 16.66667%; }
  .col-xl-pull-2 {
    right: 16.66667%; }
  .col-xl-push-2 {
    left: 16.66667%; }
  .col-xl-offset-3 {
    margin-left: 25%; }
  .col-xl-pull-3 {
    right: 25%; }
  .col-xl-push-3 {
    left: 25%; }
  .col-xl-offset-4 {
    margin-left: 33.33333%; }
  .col-xl-pull-4 {
    right: 33.33333%; }
  .col-xl-push-4 {
    left: 33.33333%; }
  .col-xl-offset-5 {
    margin-left: 41.66667%; }
  .col-xl-pull-5 {
    right: 41.66667%; }
  .col-xl-push-5 {
    left: 41.66667%; }
  .col-xl-offset-6 {
    margin-left: 50%; }
  .col-xl-pull-6 {
    right: 50%; }
  .col-xl-push-6 {
    left: 50%; }
  .col-xl-offset-7 {
    margin-left: 58.33333%; }
  .col-xl-pull-7 {
    right: 58.33333%; }
  .col-xl-push-7 {
    left: 58.33333%; }
  .col-xl-offset-8 {
    margin-left: 66.66667%; }
  .col-xl-pull-8 {
    right: 66.66667%; }
  .col-xl-push-8 {
    left: 66.66667%; }
  .col-xl-offset-9 {
    margin-left: 75%; }
  .col-xl-pull-9 {
    right: 75%; }
  .col-xl-push-9 {
    left: 75%; }
  .col-xl-offset-10 {
    margin-left: 83.33333%; }
  .col-xl-pull-10 {
    right: 83.33333%; }
  .col-xl-push-10 {
    left: 83.33333%; }
  .col-xl-offset-11 {
    margin-left: 91.66667%; }
  .col-xl-pull-11 {
    right: 91.66667%; }
  .col-xl-push-11 {
    left: 91.66667%; }
  .col-xl-clear-right:after {
    content: "";
    display: block;
    clear: both; }
  .col-xl-clear-left:before {
    content: "";
    display: block;
    clear: both; }
  .col-xl-clear-all:before {
    content: "";
    display: block;
    clear: both; }
  .col-xl-clear-all:after {
    content: "";
    display: block;
    clear: both; }
  .col-xl-noGutter-right {
    padding-right: 0; }
  .col-xl-noGutter-left {
    padding-left: 0; }
  .col-xl-noGutter-all {
    padding-left: 0;
    padding-right: 0; }
  .col-xl-1 {
    width: 8.33333%; }
  .col-xl-2 {
    width: 16.66667%; }
  .col-xl-3 {
    width: 25%; }
  .col-xl-4 {
    width: 33.33333%; }
  .col-xl-5 {
    width: 41.66667%; }
  .col-xl-6 {
    width: 50%; }
  .col-xl-7 {
    width: 58.33333%; }
  .col-xl-8 {
    width: 66.66667%; }
  .col-xl-9 {
    width: 75%; }
  .col-xl-10 {
    width: 83.33333%; }
  .col-xl-11 {
    width: 91.66667%; }
  .col-xl-12 {
    width: 100%; } }

@media (min-width: 1400px) and (max-width: 3499px) {
  .xl-visible {
    display: block !important; }
  .xl-inline-visible {
    display: inline-block !important; }
  .xl-hide {
    display: none !important; }
  .col-xl-end {
    float: right; } }

/*------------------------------------------------------------
    # END OBJECT NORA
    -------------------------------------------------------------*/
/*------------------------------------
         # OBJECTS UNITS
     ------------------------------------*/
.o-unit {
  font-size: 0;
  padding-left: 15px;
  padding-right: 15px; }
  .o-unit [class*=" o-it"],
  .o-unit [class^="o-it"] {
    display: inline-block;
    font-size: 1rem;
    vertical-align: top;
    padding-left: 15px;
    padding-right: 15px; }
  .o-unit__row {
    margin-left: -15px;
    margin-right: -15px; }
  .o-unit--margin {
    margin-bottom: 90px; }

.o-unit--middle [class*=" o-it"],
.o-unit--middle [class^="o-it"] {
  vertical-align: middle; }

.o-unit--base [class*=" o-it"],
.o-unit--base [class^="o-it"] {
  vertical-align: baseline; }

.o-unit--bottom [class*=" o-it"],
.o-unit--bottom [class^="o-it"] {
  vertical-align: bottom; }

.o-it-1\/1 {
  width: 100%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-1\/1 {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-1\/1 {
    width: 100%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-1\/1 {
    width: 100%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-1\/1 {
    width: 100%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-1\/1 {
    width: 100%; } }

.o-it-2\/1 {
  width: 200%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-2\/1 {
    width: 200%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-2\/1 {
    width: 200%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-2\/1 {
    width: 200%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-2\/1 {
    width: 200%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-2\/1 {
    width: 200%; } }

.o-it-3\/1 {
  width: 300%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-3\/1 {
    width: 300%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-3\/1 {
    width: 300%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-3\/1 {
    width: 300%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-3\/1 {
    width: 300%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-3\/1 {
    width: 300%; } }

.o-it-4\/1 {
  width: 400%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-4\/1 {
    width: 400%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-4\/1 {
    width: 400%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-4\/1 {
    width: 400%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-4\/1 {
    width: 400%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-4\/1 {
    width: 400%; } }

.o-it-5\/1 {
  width: 500%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-5\/1 {
    width: 500%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-5\/1 {
    width: 500%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-5\/1 {
    width: 500%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-5\/1 {
    width: 500%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-5\/1 {
    width: 500%; } }

.o-it-6\/1 {
  width: 600%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-6\/1 {
    width: 600%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-6\/1 {
    width: 600%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-6\/1 {
    width: 600%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-6\/1 {
    width: 600%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-6\/1 {
    width: 600%; } }

.o-it-7\/1 {
  width: 700%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-7\/1 {
    width: 700%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-7\/1 {
    width: 700%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-7\/1 {
    width: 700%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-7\/1 {
    width: 700%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-7\/1 {
    width: 700%; } }

.o-it-8\/1 {
  width: 800%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-8\/1 {
    width: 800%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-8\/1 {
    width: 800%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-8\/1 {
    width: 800%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-8\/1 {
    width: 800%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-8\/1 {
    width: 800%; } }

.o-it-9\/1 {
  width: 900%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-9\/1 {
    width: 900%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-9\/1 {
    width: 900%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-9\/1 {
    width: 900%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-9\/1 {
    width: 900%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-9\/1 {
    width: 900%; } }

.o-it-10\/1 {
  width: 1000%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-10\/1 {
    width: 1000%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-10\/1 {
    width: 1000%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-10\/1 {
    width: 1000%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-10\/1 {
    width: 1000%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-10\/1 {
    width: 1000%; } }

.o-it-11\/1 {
  width: 1100%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-11\/1 {
    width: 1100%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-11\/1 {
    width: 1100%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-11\/1 {
    width: 1100%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-11\/1 {
    width: 1100%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-11\/1 {
    width: 1100%; } }

.o-it-12\/1 {
  width: 1200%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-12\/1 {
    width: 1200%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-12\/1 {
    width: 1200%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-12\/1 {
    width: 1200%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-12\/1 {
    width: 1200%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-12\/1 {
    width: 1200%; } }

.o-it-13\/1 {
  width: 1300%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-13\/1 {
    width: 1300%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-13\/1 {
    width: 1300%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-13\/1 {
    width: 1300%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-13\/1 {
    width: 1300%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-13\/1 {
    width: 1300%; } }

.o-it-14\/1 {
  width: 1400%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-14\/1 {
    width: 1400%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-14\/1 {
    width: 1400%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-14\/1 {
    width: 1400%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-14\/1 {
    width: 1400%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-14\/1 {
    width: 1400%; } }

.o-it-15\/1 {
  width: 1500%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-15\/1 {
    width: 1500%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-15\/1 {
    width: 1500%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-15\/1 {
    width: 1500%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-15\/1 {
    width: 1500%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-15\/1 {
    width: 1500%; } }

.o-it-16\/1 {
  width: 1600%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-16\/1 {
    width: 1600%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-16\/1 {
    width: 1600%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-16\/1 {
    width: 1600%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-16\/1 {
    width: 1600%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-16\/1 {
    width: 1600%; } }

.o-it-1\/2 {
  width: 50%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-1\/2 {
    width: 50%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-1\/2 {
    width: 50%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-1\/2 {
    width: 50%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-1\/2 {
    width: 50%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-1\/2 {
    width: 50%; } }

.o-it-2\/2 {
  width: 100%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-2\/2 {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-2\/2 {
    width: 100%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-2\/2 {
    width: 100%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-2\/2 {
    width: 100%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-2\/2 {
    width: 100%; } }

.o-it-3\/2 {
  width: 150%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-3\/2 {
    width: 150%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-3\/2 {
    width: 150%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-3\/2 {
    width: 150%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-3\/2 {
    width: 150%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-3\/2 {
    width: 150%; } }

.o-it-4\/2 {
  width: 200%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-4\/2 {
    width: 200%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-4\/2 {
    width: 200%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-4\/2 {
    width: 200%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-4\/2 {
    width: 200%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-4\/2 {
    width: 200%; } }

.o-it-5\/2 {
  width: 250%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-5\/2 {
    width: 250%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-5\/2 {
    width: 250%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-5\/2 {
    width: 250%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-5\/2 {
    width: 250%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-5\/2 {
    width: 250%; } }

.o-it-6\/2 {
  width: 300%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-6\/2 {
    width: 300%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-6\/2 {
    width: 300%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-6\/2 {
    width: 300%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-6\/2 {
    width: 300%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-6\/2 {
    width: 300%; } }

.o-it-7\/2 {
  width: 350%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-7\/2 {
    width: 350%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-7\/2 {
    width: 350%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-7\/2 {
    width: 350%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-7\/2 {
    width: 350%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-7\/2 {
    width: 350%; } }

.o-it-8\/2 {
  width: 400%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-8\/2 {
    width: 400%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-8\/2 {
    width: 400%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-8\/2 {
    width: 400%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-8\/2 {
    width: 400%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-8\/2 {
    width: 400%; } }

.o-it-9\/2 {
  width: 450%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-9\/2 {
    width: 450%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-9\/2 {
    width: 450%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-9\/2 {
    width: 450%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-9\/2 {
    width: 450%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-9\/2 {
    width: 450%; } }

.o-it-10\/2 {
  width: 500%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-10\/2 {
    width: 500%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-10\/2 {
    width: 500%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-10\/2 {
    width: 500%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-10\/2 {
    width: 500%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-10\/2 {
    width: 500%; } }

.o-it-11\/2 {
  width: 550%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-11\/2 {
    width: 550%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-11\/2 {
    width: 550%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-11\/2 {
    width: 550%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-11\/2 {
    width: 550%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-11\/2 {
    width: 550%; } }

.o-it-12\/2 {
  width: 600%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-12\/2 {
    width: 600%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-12\/2 {
    width: 600%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-12\/2 {
    width: 600%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-12\/2 {
    width: 600%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-12\/2 {
    width: 600%; } }

.o-it-13\/2 {
  width: 650%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-13\/2 {
    width: 650%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-13\/2 {
    width: 650%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-13\/2 {
    width: 650%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-13\/2 {
    width: 650%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-13\/2 {
    width: 650%; } }

.o-it-14\/2 {
  width: 700%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-14\/2 {
    width: 700%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-14\/2 {
    width: 700%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-14\/2 {
    width: 700%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-14\/2 {
    width: 700%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-14\/2 {
    width: 700%; } }

.o-it-15\/2 {
  width: 750%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-15\/2 {
    width: 750%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-15\/2 {
    width: 750%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-15\/2 {
    width: 750%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-15\/2 {
    width: 750%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-15\/2 {
    width: 750%; } }

.o-it-16\/2 {
  width: 800%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-16\/2 {
    width: 800%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-16\/2 {
    width: 800%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-16\/2 {
    width: 800%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-16\/2 {
    width: 800%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-16\/2 {
    width: 800%; } }

.o-it-1\/3 {
  width: 33.33333%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-1\/3 {
    width: 33.33333%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-1\/3 {
    width: 33.33333%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-1\/3 {
    width: 33.33333%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-1\/3 {
    width: 33.33333%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-1\/3 {
    width: 33.33333%; } }

.o-it-2\/3 {
  width: 66.66667%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-2\/3 {
    width: 66.66667%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-2\/3 {
    width: 66.66667%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-2\/3 {
    width: 66.66667%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-2\/3 {
    width: 66.66667%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-2\/3 {
    width: 66.66667%; } }

.o-it-3\/3 {
  width: 100%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-3\/3 {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-3\/3 {
    width: 100%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-3\/3 {
    width: 100%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-3\/3 {
    width: 100%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-3\/3 {
    width: 100%; } }

.o-it-4\/3 {
  width: 133.33333%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-4\/3 {
    width: 133.33333%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-4\/3 {
    width: 133.33333%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-4\/3 {
    width: 133.33333%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-4\/3 {
    width: 133.33333%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-4\/3 {
    width: 133.33333%; } }

.o-it-5\/3 {
  width: 166.66667%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-5\/3 {
    width: 166.66667%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-5\/3 {
    width: 166.66667%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-5\/3 {
    width: 166.66667%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-5\/3 {
    width: 166.66667%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-5\/3 {
    width: 166.66667%; } }

.o-it-6\/3 {
  width: 200%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-6\/3 {
    width: 200%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-6\/3 {
    width: 200%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-6\/3 {
    width: 200%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-6\/3 {
    width: 200%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-6\/3 {
    width: 200%; } }

.o-it-7\/3 {
  width: 233.33333%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-7\/3 {
    width: 233.33333%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-7\/3 {
    width: 233.33333%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-7\/3 {
    width: 233.33333%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-7\/3 {
    width: 233.33333%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-7\/3 {
    width: 233.33333%; } }

.o-it-8\/3 {
  width: 266.66667%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-8\/3 {
    width: 266.66667%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-8\/3 {
    width: 266.66667%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-8\/3 {
    width: 266.66667%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-8\/3 {
    width: 266.66667%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-8\/3 {
    width: 266.66667%; } }

.o-it-9\/3 {
  width: 300%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-9\/3 {
    width: 300%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-9\/3 {
    width: 300%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-9\/3 {
    width: 300%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-9\/3 {
    width: 300%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-9\/3 {
    width: 300%; } }

.o-it-10\/3 {
  width: 333.33333%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-10\/3 {
    width: 333.33333%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-10\/3 {
    width: 333.33333%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-10\/3 {
    width: 333.33333%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-10\/3 {
    width: 333.33333%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-10\/3 {
    width: 333.33333%; } }

.o-it-11\/3 {
  width: 366.66667%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-11\/3 {
    width: 366.66667%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-11\/3 {
    width: 366.66667%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-11\/3 {
    width: 366.66667%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-11\/3 {
    width: 366.66667%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-11\/3 {
    width: 366.66667%; } }

.o-it-12\/3 {
  width: 400%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-12\/3 {
    width: 400%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-12\/3 {
    width: 400%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-12\/3 {
    width: 400%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-12\/3 {
    width: 400%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-12\/3 {
    width: 400%; } }

.o-it-13\/3 {
  width: 433.33333%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-13\/3 {
    width: 433.33333%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-13\/3 {
    width: 433.33333%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-13\/3 {
    width: 433.33333%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-13\/3 {
    width: 433.33333%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-13\/3 {
    width: 433.33333%; } }

.o-it-14\/3 {
  width: 466.66667%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-14\/3 {
    width: 466.66667%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-14\/3 {
    width: 466.66667%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-14\/3 {
    width: 466.66667%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-14\/3 {
    width: 466.66667%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-14\/3 {
    width: 466.66667%; } }

.o-it-15\/3 {
  width: 500%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-15\/3 {
    width: 500%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-15\/3 {
    width: 500%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-15\/3 {
    width: 500%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-15\/3 {
    width: 500%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-15\/3 {
    width: 500%; } }

.o-it-16\/3 {
  width: 533.33333%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-16\/3 {
    width: 533.33333%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-16\/3 {
    width: 533.33333%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-16\/3 {
    width: 533.33333%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-16\/3 {
    width: 533.33333%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-16\/3 {
    width: 533.33333%; } }

.o-it-1\/4 {
  width: 25%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-1\/4 {
    width: 25%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-1\/4 {
    width: 25%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-1\/4 {
    width: 25%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-1\/4 {
    width: 25%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-1\/4 {
    width: 25%; } }

.o-it-2\/4 {
  width: 50%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-2\/4 {
    width: 50%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-2\/4 {
    width: 50%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-2\/4 {
    width: 50%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-2\/4 {
    width: 50%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-2\/4 {
    width: 50%; } }

.o-it-3\/4 {
  width: 75%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-3\/4 {
    width: 75%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-3\/4 {
    width: 75%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-3\/4 {
    width: 75%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-3\/4 {
    width: 75%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-3\/4 {
    width: 75%; } }

.o-it-4\/4 {
  width: 100%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-4\/4 {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-4\/4 {
    width: 100%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-4\/4 {
    width: 100%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-4\/4 {
    width: 100%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-4\/4 {
    width: 100%; } }

.o-it-5\/4 {
  width: 125%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-5\/4 {
    width: 125%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-5\/4 {
    width: 125%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-5\/4 {
    width: 125%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-5\/4 {
    width: 125%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-5\/4 {
    width: 125%; } }

.o-it-6\/4 {
  width: 150%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-6\/4 {
    width: 150%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-6\/4 {
    width: 150%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-6\/4 {
    width: 150%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-6\/4 {
    width: 150%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-6\/4 {
    width: 150%; } }

.o-it-7\/4 {
  width: 175%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-7\/4 {
    width: 175%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-7\/4 {
    width: 175%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-7\/4 {
    width: 175%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-7\/4 {
    width: 175%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-7\/4 {
    width: 175%; } }

.o-it-8\/4 {
  width: 200%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-8\/4 {
    width: 200%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-8\/4 {
    width: 200%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-8\/4 {
    width: 200%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-8\/4 {
    width: 200%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-8\/4 {
    width: 200%; } }

.o-it-9\/4 {
  width: 225%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-9\/4 {
    width: 225%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-9\/4 {
    width: 225%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-9\/4 {
    width: 225%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-9\/4 {
    width: 225%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-9\/4 {
    width: 225%; } }

.o-it-10\/4 {
  width: 250%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-10\/4 {
    width: 250%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-10\/4 {
    width: 250%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-10\/4 {
    width: 250%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-10\/4 {
    width: 250%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-10\/4 {
    width: 250%; } }

.o-it-11\/4 {
  width: 275%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-11\/4 {
    width: 275%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-11\/4 {
    width: 275%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-11\/4 {
    width: 275%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-11\/4 {
    width: 275%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-11\/4 {
    width: 275%; } }

.o-it-12\/4 {
  width: 300%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-12\/4 {
    width: 300%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-12\/4 {
    width: 300%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-12\/4 {
    width: 300%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-12\/4 {
    width: 300%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-12\/4 {
    width: 300%; } }

.o-it-13\/4 {
  width: 325%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-13\/4 {
    width: 325%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-13\/4 {
    width: 325%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-13\/4 {
    width: 325%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-13\/4 {
    width: 325%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-13\/4 {
    width: 325%; } }

.o-it-14\/4 {
  width: 350%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-14\/4 {
    width: 350%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-14\/4 {
    width: 350%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-14\/4 {
    width: 350%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-14\/4 {
    width: 350%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-14\/4 {
    width: 350%; } }

.o-it-15\/4 {
  width: 375%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-15\/4 {
    width: 375%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-15\/4 {
    width: 375%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-15\/4 {
    width: 375%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-15\/4 {
    width: 375%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-15\/4 {
    width: 375%; } }

.o-it-16\/4 {
  width: 400%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-16\/4 {
    width: 400%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-16\/4 {
    width: 400%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-16\/4 {
    width: 400%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-16\/4 {
    width: 400%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-16\/4 {
    width: 400%; } }

.o-it-1\/5 {
  width: 20%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-1\/5 {
    width: 20%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-1\/5 {
    width: 20%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-1\/5 {
    width: 20%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-1\/5 {
    width: 20%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-1\/5 {
    width: 20%; } }

.o-it-2\/5 {
  width: 40%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-2\/5 {
    width: 40%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-2\/5 {
    width: 40%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-2\/5 {
    width: 40%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-2\/5 {
    width: 40%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-2\/5 {
    width: 40%; } }

.o-it-3\/5 {
  width: 60%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-3\/5 {
    width: 60%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-3\/5 {
    width: 60%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-3\/5 {
    width: 60%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-3\/5 {
    width: 60%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-3\/5 {
    width: 60%; } }

.o-it-4\/5 {
  width: 80%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-4\/5 {
    width: 80%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-4\/5 {
    width: 80%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-4\/5 {
    width: 80%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-4\/5 {
    width: 80%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-4\/5 {
    width: 80%; } }

.o-it-5\/5 {
  width: 100%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-5\/5 {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-5\/5 {
    width: 100%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-5\/5 {
    width: 100%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-5\/5 {
    width: 100%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-5\/5 {
    width: 100%; } }

.o-it-6\/5 {
  width: 120%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-6\/5 {
    width: 120%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-6\/5 {
    width: 120%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-6\/5 {
    width: 120%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-6\/5 {
    width: 120%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-6\/5 {
    width: 120%; } }

.o-it-7\/5 {
  width: 140%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-7\/5 {
    width: 140%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-7\/5 {
    width: 140%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-7\/5 {
    width: 140%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-7\/5 {
    width: 140%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-7\/5 {
    width: 140%; } }

.o-it-8\/5 {
  width: 160%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-8\/5 {
    width: 160%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-8\/5 {
    width: 160%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-8\/5 {
    width: 160%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-8\/5 {
    width: 160%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-8\/5 {
    width: 160%; } }

.o-it-9\/5 {
  width: 180%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-9\/5 {
    width: 180%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-9\/5 {
    width: 180%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-9\/5 {
    width: 180%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-9\/5 {
    width: 180%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-9\/5 {
    width: 180%; } }

.o-it-10\/5 {
  width: 200%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-10\/5 {
    width: 200%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-10\/5 {
    width: 200%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-10\/5 {
    width: 200%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-10\/5 {
    width: 200%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-10\/5 {
    width: 200%; } }

.o-it-11\/5 {
  width: 220%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-11\/5 {
    width: 220%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-11\/5 {
    width: 220%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-11\/5 {
    width: 220%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-11\/5 {
    width: 220%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-11\/5 {
    width: 220%; } }

.o-it-12\/5 {
  width: 240%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-12\/5 {
    width: 240%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-12\/5 {
    width: 240%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-12\/5 {
    width: 240%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-12\/5 {
    width: 240%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-12\/5 {
    width: 240%; } }

.o-it-13\/5 {
  width: 260%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-13\/5 {
    width: 260%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-13\/5 {
    width: 260%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-13\/5 {
    width: 260%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-13\/5 {
    width: 260%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-13\/5 {
    width: 260%; } }

.o-it-14\/5 {
  width: 280%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-14\/5 {
    width: 280%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-14\/5 {
    width: 280%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-14\/5 {
    width: 280%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-14\/5 {
    width: 280%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-14\/5 {
    width: 280%; } }

.o-it-15\/5 {
  width: 300%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-15\/5 {
    width: 300%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-15\/5 {
    width: 300%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-15\/5 {
    width: 300%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-15\/5 {
    width: 300%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-15\/5 {
    width: 300%; } }

.o-it-16\/5 {
  width: 320%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-16\/5 {
    width: 320%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-16\/5 {
    width: 320%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-16\/5 {
    width: 320%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-16\/5 {
    width: 320%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-16\/5 {
    width: 320%; } }

.o-it-1\/6 {
  width: 16.66667%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-1\/6 {
    width: 16.66667%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-1\/6 {
    width: 16.66667%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-1\/6 {
    width: 16.66667%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-1\/6 {
    width: 16.66667%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-1\/6 {
    width: 16.66667%; } }

.o-it-2\/6 {
  width: 33.33333%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-2\/6 {
    width: 33.33333%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-2\/6 {
    width: 33.33333%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-2\/6 {
    width: 33.33333%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-2\/6 {
    width: 33.33333%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-2\/6 {
    width: 33.33333%; } }

.o-it-3\/6 {
  width: 50%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-3\/6 {
    width: 50%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-3\/6 {
    width: 50%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-3\/6 {
    width: 50%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-3\/6 {
    width: 50%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-3\/6 {
    width: 50%; } }

.o-it-4\/6 {
  width: 66.66667%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-4\/6 {
    width: 66.66667%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-4\/6 {
    width: 66.66667%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-4\/6 {
    width: 66.66667%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-4\/6 {
    width: 66.66667%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-4\/6 {
    width: 66.66667%; } }

.o-it-5\/6 {
  width: 83.33333%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-5\/6 {
    width: 83.33333%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-5\/6 {
    width: 83.33333%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-5\/6 {
    width: 83.33333%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-5\/6 {
    width: 83.33333%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-5\/6 {
    width: 83.33333%; } }

.o-it-6\/6 {
  width: 100%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-6\/6 {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-6\/6 {
    width: 100%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-6\/6 {
    width: 100%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-6\/6 {
    width: 100%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-6\/6 {
    width: 100%; } }

.o-it-7\/6 {
  width: 116.66667%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-7\/6 {
    width: 116.66667%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-7\/6 {
    width: 116.66667%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-7\/6 {
    width: 116.66667%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-7\/6 {
    width: 116.66667%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-7\/6 {
    width: 116.66667%; } }

.o-it-8\/6 {
  width: 133.33333%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-8\/6 {
    width: 133.33333%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-8\/6 {
    width: 133.33333%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-8\/6 {
    width: 133.33333%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-8\/6 {
    width: 133.33333%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-8\/6 {
    width: 133.33333%; } }

.o-it-9\/6 {
  width: 150%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-9\/6 {
    width: 150%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-9\/6 {
    width: 150%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-9\/6 {
    width: 150%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-9\/6 {
    width: 150%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-9\/6 {
    width: 150%; } }

.o-it-10\/6 {
  width: 166.66667%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-10\/6 {
    width: 166.66667%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-10\/6 {
    width: 166.66667%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-10\/6 {
    width: 166.66667%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-10\/6 {
    width: 166.66667%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-10\/6 {
    width: 166.66667%; } }

.o-it-11\/6 {
  width: 183.33333%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-11\/6 {
    width: 183.33333%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-11\/6 {
    width: 183.33333%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-11\/6 {
    width: 183.33333%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-11\/6 {
    width: 183.33333%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-11\/6 {
    width: 183.33333%; } }

.o-it-12\/6 {
  width: 200%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-12\/6 {
    width: 200%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-12\/6 {
    width: 200%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-12\/6 {
    width: 200%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-12\/6 {
    width: 200%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-12\/6 {
    width: 200%; } }

.o-it-13\/6 {
  width: 216.66667%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-13\/6 {
    width: 216.66667%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-13\/6 {
    width: 216.66667%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-13\/6 {
    width: 216.66667%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-13\/6 {
    width: 216.66667%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-13\/6 {
    width: 216.66667%; } }

.o-it-14\/6 {
  width: 233.33333%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-14\/6 {
    width: 233.33333%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-14\/6 {
    width: 233.33333%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-14\/6 {
    width: 233.33333%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-14\/6 {
    width: 233.33333%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-14\/6 {
    width: 233.33333%; } }

.o-it-15\/6 {
  width: 250%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-15\/6 {
    width: 250%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-15\/6 {
    width: 250%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-15\/6 {
    width: 250%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-15\/6 {
    width: 250%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-15\/6 {
    width: 250%; } }

.o-it-16\/6 {
  width: 266.66667%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-16\/6 {
    width: 266.66667%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-16\/6 {
    width: 266.66667%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-16\/6 {
    width: 266.66667%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-16\/6 {
    width: 266.66667%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-16\/6 {
    width: 266.66667%; } }

.o-it-1\/7 {
  width: 14.28571%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-1\/7 {
    width: 14.28571%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-1\/7 {
    width: 14.28571%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-1\/7 {
    width: 14.28571%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-1\/7 {
    width: 14.28571%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-1\/7 {
    width: 14.28571%; } }

.o-it-2\/7 {
  width: 28.57143%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-2\/7 {
    width: 28.57143%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-2\/7 {
    width: 28.57143%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-2\/7 {
    width: 28.57143%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-2\/7 {
    width: 28.57143%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-2\/7 {
    width: 28.57143%; } }

.o-it-3\/7 {
  width: 42.85714%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-3\/7 {
    width: 42.85714%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-3\/7 {
    width: 42.85714%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-3\/7 {
    width: 42.85714%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-3\/7 {
    width: 42.85714%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-3\/7 {
    width: 42.85714%; } }

.o-it-4\/7 {
  width: 57.14286%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-4\/7 {
    width: 57.14286%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-4\/7 {
    width: 57.14286%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-4\/7 {
    width: 57.14286%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-4\/7 {
    width: 57.14286%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-4\/7 {
    width: 57.14286%; } }

.o-it-5\/7 {
  width: 71.42857%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-5\/7 {
    width: 71.42857%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-5\/7 {
    width: 71.42857%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-5\/7 {
    width: 71.42857%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-5\/7 {
    width: 71.42857%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-5\/7 {
    width: 71.42857%; } }

.o-it-6\/7 {
  width: 85.71429%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-6\/7 {
    width: 85.71429%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-6\/7 {
    width: 85.71429%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-6\/7 {
    width: 85.71429%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-6\/7 {
    width: 85.71429%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-6\/7 {
    width: 85.71429%; } }

.o-it-7\/7 {
  width: 100%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-7\/7 {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-7\/7 {
    width: 100%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-7\/7 {
    width: 100%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-7\/7 {
    width: 100%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-7\/7 {
    width: 100%; } }

.o-it-8\/7 {
  width: 114.28571%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-8\/7 {
    width: 114.28571%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-8\/7 {
    width: 114.28571%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-8\/7 {
    width: 114.28571%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-8\/7 {
    width: 114.28571%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-8\/7 {
    width: 114.28571%; } }

.o-it-9\/7 {
  width: 128.57143%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-9\/7 {
    width: 128.57143%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-9\/7 {
    width: 128.57143%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-9\/7 {
    width: 128.57143%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-9\/7 {
    width: 128.57143%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-9\/7 {
    width: 128.57143%; } }

.o-it-10\/7 {
  width: 142.85714%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-10\/7 {
    width: 142.85714%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-10\/7 {
    width: 142.85714%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-10\/7 {
    width: 142.85714%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-10\/7 {
    width: 142.85714%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-10\/7 {
    width: 142.85714%; } }

.o-it-11\/7 {
  width: 157.14286%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-11\/7 {
    width: 157.14286%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-11\/7 {
    width: 157.14286%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-11\/7 {
    width: 157.14286%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-11\/7 {
    width: 157.14286%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-11\/7 {
    width: 157.14286%; } }

.o-it-12\/7 {
  width: 171.42857%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-12\/7 {
    width: 171.42857%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-12\/7 {
    width: 171.42857%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-12\/7 {
    width: 171.42857%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-12\/7 {
    width: 171.42857%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-12\/7 {
    width: 171.42857%; } }

.o-it-13\/7 {
  width: 185.71429%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-13\/7 {
    width: 185.71429%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-13\/7 {
    width: 185.71429%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-13\/7 {
    width: 185.71429%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-13\/7 {
    width: 185.71429%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-13\/7 {
    width: 185.71429%; } }

.o-it-14\/7 {
  width: 200%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-14\/7 {
    width: 200%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-14\/7 {
    width: 200%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-14\/7 {
    width: 200%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-14\/7 {
    width: 200%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-14\/7 {
    width: 200%; } }

.o-it-15\/7 {
  width: 214.28571%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-15\/7 {
    width: 214.28571%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-15\/7 {
    width: 214.28571%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-15\/7 {
    width: 214.28571%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-15\/7 {
    width: 214.28571%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-15\/7 {
    width: 214.28571%; } }

.o-it-16\/7 {
  width: 228.57143%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-16\/7 {
    width: 228.57143%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-16\/7 {
    width: 228.57143%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-16\/7 {
    width: 228.57143%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-16\/7 {
    width: 228.57143%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-16\/7 {
    width: 228.57143%; } }

.o-it-1\/8 {
  width: 12.5%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-1\/8 {
    width: 12.5%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-1\/8 {
    width: 12.5%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-1\/8 {
    width: 12.5%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-1\/8 {
    width: 12.5%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-1\/8 {
    width: 12.5%; } }

.o-it-2\/8 {
  width: 25%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-2\/8 {
    width: 25%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-2\/8 {
    width: 25%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-2\/8 {
    width: 25%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-2\/8 {
    width: 25%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-2\/8 {
    width: 25%; } }

.o-it-3\/8 {
  width: 37.5%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-3\/8 {
    width: 37.5%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-3\/8 {
    width: 37.5%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-3\/8 {
    width: 37.5%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-3\/8 {
    width: 37.5%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-3\/8 {
    width: 37.5%; } }

.o-it-4\/8 {
  width: 50%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-4\/8 {
    width: 50%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-4\/8 {
    width: 50%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-4\/8 {
    width: 50%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-4\/8 {
    width: 50%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-4\/8 {
    width: 50%; } }

.o-it-5\/8 {
  width: 62.5%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-5\/8 {
    width: 62.5%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-5\/8 {
    width: 62.5%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-5\/8 {
    width: 62.5%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-5\/8 {
    width: 62.5%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-5\/8 {
    width: 62.5%; } }

.o-it-6\/8 {
  width: 75%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-6\/8 {
    width: 75%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-6\/8 {
    width: 75%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-6\/8 {
    width: 75%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-6\/8 {
    width: 75%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-6\/8 {
    width: 75%; } }

.o-it-7\/8 {
  width: 87.5%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-7\/8 {
    width: 87.5%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-7\/8 {
    width: 87.5%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-7\/8 {
    width: 87.5%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-7\/8 {
    width: 87.5%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-7\/8 {
    width: 87.5%; } }

.o-it-8\/8 {
  width: 100%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-8\/8 {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-8\/8 {
    width: 100%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-8\/8 {
    width: 100%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-8\/8 {
    width: 100%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-8\/8 {
    width: 100%; } }

.o-it-9\/8 {
  width: 112.5%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-9\/8 {
    width: 112.5%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-9\/8 {
    width: 112.5%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-9\/8 {
    width: 112.5%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-9\/8 {
    width: 112.5%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-9\/8 {
    width: 112.5%; } }

.o-it-10\/8 {
  width: 125%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-10\/8 {
    width: 125%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-10\/8 {
    width: 125%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-10\/8 {
    width: 125%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-10\/8 {
    width: 125%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-10\/8 {
    width: 125%; } }

.o-it-11\/8 {
  width: 137.5%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-11\/8 {
    width: 137.5%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-11\/8 {
    width: 137.5%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-11\/8 {
    width: 137.5%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-11\/8 {
    width: 137.5%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-11\/8 {
    width: 137.5%; } }

.o-it-12\/8 {
  width: 150%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-12\/8 {
    width: 150%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-12\/8 {
    width: 150%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-12\/8 {
    width: 150%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-12\/8 {
    width: 150%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-12\/8 {
    width: 150%; } }

.o-it-13\/8 {
  width: 162.5%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-13\/8 {
    width: 162.5%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-13\/8 {
    width: 162.5%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-13\/8 {
    width: 162.5%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-13\/8 {
    width: 162.5%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-13\/8 {
    width: 162.5%; } }

.o-it-14\/8 {
  width: 175%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-14\/8 {
    width: 175%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-14\/8 {
    width: 175%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-14\/8 {
    width: 175%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-14\/8 {
    width: 175%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-14\/8 {
    width: 175%; } }

.o-it-15\/8 {
  width: 187.5%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-15\/8 {
    width: 187.5%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-15\/8 {
    width: 187.5%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-15\/8 {
    width: 187.5%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-15\/8 {
    width: 187.5%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-15\/8 {
    width: 187.5%; } }

.o-it-16\/8 {
  width: 200%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-16\/8 {
    width: 200%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-16\/8 {
    width: 200%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-16\/8 {
    width: 200%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-16\/8 {
    width: 200%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-16\/8 {
    width: 200%; } }

.o-it-1\/9 {
  width: 11.11111%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-1\/9 {
    width: 11.11111%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-1\/9 {
    width: 11.11111%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-1\/9 {
    width: 11.11111%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-1\/9 {
    width: 11.11111%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-1\/9 {
    width: 11.11111%; } }

.o-it-2\/9 {
  width: 22.22222%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-2\/9 {
    width: 22.22222%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-2\/9 {
    width: 22.22222%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-2\/9 {
    width: 22.22222%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-2\/9 {
    width: 22.22222%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-2\/9 {
    width: 22.22222%; } }

.o-it-3\/9 {
  width: 33.33333%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-3\/9 {
    width: 33.33333%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-3\/9 {
    width: 33.33333%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-3\/9 {
    width: 33.33333%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-3\/9 {
    width: 33.33333%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-3\/9 {
    width: 33.33333%; } }

.o-it-4\/9 {
  width: 44.44444%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-4\/9 {
    width: 44.44444%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-4\/9 {
    width: 44.44444%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-4\/9 {
    width: 44.44444%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-4\/9 {
    width: 44.44444%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-4\/9 {
    width: 44.44444%; } }

.o-it-5\/9 {
  width: 55.55556%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-5\/9 {
    width: 55.55556%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-5\/9 {
    width: 55.55556%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-5\/9 {
    width: 55.55556%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-5\/9 {
    width: 55.55556%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-5\/9 {
    width: 55.55556%; } }

.o-it-6\/9 {
  width: 66.66667%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-6\/9 {
    width: 66.66667%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-6\/9 {
    width: 66.66667%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-6\/9 {
    width: 66.66667%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-6\/9 {
    width: 66.66667%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-6\/9 {
    width: 66.66667%; } }

.o-it-7\/9 {
  width: 77.77778%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-7\/9 {
    width: 77.77778%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-7\/9 {
    width: 77.77778%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-7\/9 {
    width: 77.77778%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-7\/9 {
    width: 77.77778%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-7\/9 {
    width: 77.77778%; } }

.o-it-8\/9 {
  width: 88.88889%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-8\/9 {
    width: 88.88889%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-8\/9 {
    width: 88.88889%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-8\/9 {
    width: 88.88889%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-8\/9 {
    width: 88.88889%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-8\/9 {
    width: 88.88889%; } }

.o-it-9\/9 {
  width: 100%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-9\/9 {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-9\/9 {
    width: 100%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-9\/9 {
    width: 100%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-9\/9 {
    width: 100%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-9\/9 {
    width: 100%; } }

.o-it-10\/9 {
  width: 111.11111%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-10\/9 {
    width: 111.11111%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-10\/9 {
    width: 111.11111%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-10\/9 {
    width: 111.11111%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-10\/9 {
    width: 111.11111%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-10\/9 {
    width: 111.11111%; } }

.o-it-11\/9 {
  width: 122.22222%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-11\/9 {
    width: 122.22222%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-11\/9 {
    width: 122.22222%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-11\/9 {
    width: 122.22222%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-11\/9 {
    width: 122.22222%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-11\/9 {
    width: 122.22222%; } }

.o-it-12\/9 {
  width: 133.33333%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-12\/9 {
    width: 133.33333%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-12\/9 {
    width: 133.33333%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-12\/9 {
    width: 133.33333%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-12\/9 {
    width: 133.33333%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-12\/9 {
    width: 133.33333%; } }

.o-it-13\/9 {
  width: 144.44444%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-13\/9 {
    width: 144.44444%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-13\/9 {
    width: 144.44444%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-13\/9 {
    width: 144.44444%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-13\/9 {
    width: 144.44444%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-13\/9 {
    width: 144.44444%; } }

.o-it-14\/9 {
  width: 155.55556%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-14\/9 {
    width: 155.55556%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-14\/9 {
    width: 155.55556%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-14\/9 {
    width: 155.55556%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-14\/9 {
    width: 155.55556%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-14\/9 {
    width: 155.55556%; } }

.o-it-15\/9 {
  width: 166.66667%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-15\/9 {
    width: 166.66667%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-15\/9 {
    width: 166.66667%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-15\/9 {
    width: 166.66667%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-15\/9 {
    width: 166.66667%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-15\/9 {
    width: 166.66667%; } }

.o-it-16\/9 {
  width: 177.77778%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-16\/9 {
    width: 177.77778%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-16\/9 {
    width: 177.77778%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-16\/9 {
    width: 177.77778%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-16\/9 {
    width: 177.77778%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-16\/9 {
    width: 177.77778%; } }

.o-it-1\/10 {
  width: 10%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-1\/10 {
    width: 10%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-1\/10 {
    width: 10%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-1\/10 {
    width: 10%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-1\/10 {
    width: 10%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-1\/10 {
    width: 10%; } }

.o-it-2\/10 {
  width: 20%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-2\/10 {
    width: 20%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-2\/10 {
    width: 20%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-2\/10 {
    width: 20%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-2\/10 {
    width: 20%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-2\/10 {
    width: 20%; } }

.o-it-3\/10 {
  width: 30%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-3\/10 {
    width: 30%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-3\/10 {
    width: 30%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-3\/10 {
    width: 30%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-3\/10 {
    width: 30%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-3\/10 {
    width: 30%; } }

.o-it-4\/10 {
  width: 40%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-4\/10 {
    width: 40%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-4\/10 {
    width: 40%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-4\/10 {
    width: 40%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-4\/10 {
    width: 40%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-4\/10 {
    width: 40%; } }

.o-it-5\/10 {
  width: 50%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-5\/10 {
    width: 50%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-5\/10 {
    width: 50%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-5\/10 {
    width: 50%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-5\/10 {
    width: 50%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-5\/10 {
    width: 50%; } }

.o-it-6\/10 {
  width: 60%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-6\/10 {
    width: 60%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-6\/10 {
    width: 60%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-6\/10 {
    width: 60%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-6\/10 {
    width: 60%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-6\/10 {
    width: 60%; } }

.o-it-7\/10 {
  width: 70%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-7\/10 {
    width: 70%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-7\/10 {
    width: 70%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-7\/10 {
    width: 70%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-7\/10 {
    width: 70%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-7\/10 {
    width: 70%; } }

.o-it-8\/10 {
  width: 80%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-8\/10 {
    width: 80%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-8\/10 {
    width: 80%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-8\/10 {
    width: 80%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-8\/10 {
    width: 80%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-8\/10 {
    width: 80%; } }

.o-it-9\/10 {
  width: 90%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-9\/10 {
    width: 90%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-9\/10 {
    width: 90%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-9\/10 {
    width: 90%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-9\/10 {
    width: 90%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-9\/10 {
    width: 90%; } }

.o-it-10\/10 {
  width: 100%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-10\/10 {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-10\/10 {
    width: 100%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-10\/10 {
    width: 100%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-10\/10 {
    width: 100%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-10\/10 {
    width: 100%; } }

.o-it-11\/10 {
  width: 110%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-11\/10 {
    width: 110%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-11\/10 {
    width: 110%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-11\/10 {
    width: 110%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-11\/10 {
    width: 110%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-11\/10 {
    width: 110%; } }

.o-it-12\/10 {
  width: 120%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-12\/10 {
    width: 120%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-12\/10 {
    width: 120%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-12\/10 {
    width: 120%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-12\/10 {
    width: 120%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-12\/10 {
    width: 120%; } }

.o-it-13\/10 {
  width: 130%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-13\/10 {
    width: 130%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-13\/10 {
    width: 130%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-13\/10 {
    width: 130%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-13\/10 {
    width: 130%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-13\/10 {
    width: 130%; } }

.o-it-14\/10 {
  width: 140%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-14\/10 {
    width: 140%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-14\/10 {
    width: 140%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-14\/10 {
    width: 140%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-14\/10 {
    width: 140%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-14\/10 {
    width: 140%; } }

.o-it-15\/10 {
  width: 150%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-15\/10 {
    width: 150%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-15\/10 {
    width: 150%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-15\/10 {
    width: 150%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-15\/10 {
    width: 150%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-15\/10 {
    width: 150%; } }

.o-it-16\/10 {
  width: 160%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-16\/10 {
    width: 160%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-16\/10 {
    width: 160%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-16\/10 {
    width: 160%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-16\/10 {
    width: 160%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-16\/10 {
    width: 160%; } }

.o-it-1\/11 {
  width: 9.09091%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-1\/11 {
    width: 9.09091%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-1\/11 {
    width: 9.09091%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-1\/11 {
    width: 9.09091%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-1\/11 {
    width: 9.09091%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-1\/11 {
    width: 9.09091%; } }

.o-it-2\/11 {
  width: 18.18182%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-2\/11 {
    width: 18.18182%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-2\/11 {
    width: 18.18182%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-2\/11 {
    width: 18.18182%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-2\/11 {
    width: 18.18182%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-2\/11 {
    width: 18.18182%; } }

.o-it-3\/11 {
  width: 27.27273%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-3\/11 {
    width: 27.27273%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-3\/11 {
    width: 27.27273%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-3\/11 {
    width: 27.27273%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-3\/11 {
    width: 27.27273%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-3\/11 {
    width: 27.27273%; } }

.o-it-4\/11 {
  width: 36.36364%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-4\/11 {
    width: 36.36364%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-4\/11 {
    width: 36.36364%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-4\/11 {
    width: 36.36364%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-4\/11 {
    width: 36.36364%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-4\/11 {
    width: 36.36364%; } }

.o-it-5\/11 {
  width: 45.45455%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-5\/11 {
    width: 45.45455%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-5\/11 {
    width: 45.45455%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-5\/11 {
    width: 45.45455%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-5\/11 {
    width: 45.45455%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-5\/11 {
    width: 45.45455%; } }

.o-it-6\/11 {
  width: 54.54545%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-6\/11 {
    width: 54.54545%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-6\/11 {
    width: 54.54545%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-6\/11 {
    width: 54.54545%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-6\/11 {
    width: 54.54545%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-6\/11 {
    width: 54.54545%; } }

.o-it-7\/11 {
  width: 63.63636%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-7\/11 {
    width: 63.63636%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-7\/11 {
    width: 63.63636%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-7\/11 {
    width: 63.63636%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-7\/11 {
    width: 63.63636%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-7\/11 {
    width: 63.63636%; } }

.o-it-8\/11 {
  width: 72.72727%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-8\/11 {
    width: 72.72727%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-8\/11 {
    width: 72.72727%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-8\/11 {
    width: 72.72727%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-8\/11 {
    width: 72.72727%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-8\/11 {
    width: 72.72727%; } }

.o-it-9\/11 {
  width: 81.81818%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-9\/11 {
    width: 81.81818%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-9\/11 {
    width: 81.81818%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-9\/11 {
    width: 81.81818%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-9\/11 {
    width: 81.81818%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-9\/11 {
    width: 81.81818%; } }

.o-it-10\/11 {
  width: 90.90909%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-10\/11 {
    width: 90.90909%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-10\/11 {
    width: 90.90909%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-10\/11 {
    width: 90.90909%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-10\/11 {
    width: 90.90909%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-10\/11 {
    width: 90.90909%; } }

.o-it-11\/11 {
  width: 100%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-11\/11 {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-11\/11 {
    width: 100%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-11\/11 {
    width: 100%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-11\/11 {
    width: 100%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-11\/11 {
    width: 100%; } }

.o-it-12\/11 {
  width: 109.09091%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-12\/11 {
    width: 109.09091%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-12\/11 {
    width: 109.09091%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-12\/11 {
    width: 109.09091%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-12\/11 {
    width: 109.09091%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-12\/11 {
    width: 109.09091%; } }

.o-it-13\/11 {
  width: 118.18182%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-13\/11 {
    width: 118.18182%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-13\/11 {
    width: 118.18182%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-13\/11 {
    width: 118.18182%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-13\/11 {
    width: 118.18182%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-13\/11 {
    width: 118.18182%; } }

.o-it-14\/11 {
  width: 127.27273%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-14\/11 {
    width: 127.27273%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-14\/11 {
    width: 127.27273%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-14\/11 {
    width: 127.27273%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-14\/11 {
    width: 127.27273%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-14\/11 {
    width: 127.27273%; } }

.o-it-15\/11 {
  width: 136.36364%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-15\/11 {
    width: 136.36364%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-15\/11 {
    width: 136.36364%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-15\/11 {
    width: 136.36364%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-15\/11 {
    width: 136.36364%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-15\/11 {
    width: 136.36364%; } }

.o-it-16\/11 {
  width: 145.45455%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-16\/11 {
    width: 145.45455%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-16\/11 {
    width: 145.45455%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-16\/11 {
    width: 145.45455%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-16\/11 {
    width: 145.45455%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-16\/11 {
    width: 145.45455%; } }

.o-it-1\/12 {
  width: 8.33333%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-1\/12 {
    width: 8.33333%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-1\/12 {
    width: 8.33333%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-1\/12 {
    width: 8.33333%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-1\/12 {
    width: 8.33333%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-1\/12 {
    width: 8.33333%; } }

.o-it-2\/12 {
  width: 16.66667%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-2\/12 {
    width: 16.66667%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-2\/12 {
    width: 16.66667%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-2\/12 {
    width: 16.66667%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-2\/12 {
    width: 16.66667%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-2\/12 {
    width: 16.66667%; } }

.o-it-3\/12 {
  width: 25%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-3\/12 {
    width: 25%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-3\/12 {
    width: 25%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-3\/12 {
    width: 25%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-3\/12 {
    width: 25%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-3\/12 {
    width: 25%; } }

.o-it-4\/12 {
  width: 33.33333%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-4\/12 {
    width: 33.33333%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-4\/12 {
    width: 33.33333%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-4\/12 {
    width: 33.33333%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-4\/12 {
    width: 33.33333%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-4\/12 {
    width: 33.33333%; } }

.o-it-5\/12 {
  width: 41.66667%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-5\/12 {
    width: 41.66667%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-5\/12 {
    width: 41.66667%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-5\/12 {
    width: 41.66667%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-5\/12 {
    width: 41.66667%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-5\/12 {
    width: 41.66667%; } }

.o-it-6\/12 {
  width: 50%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-6\/12 {
    width: 50%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-6\/12 {
    width: 50%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-6\/12 {
    width: 50%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-6\/12 {
    width: 50%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-6\/12 {
    width: 50%; } }

.o-it-7\/12 {
  width: 58.33333%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-7\/12 {
    width: 58.33333%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-7\/12 {
    width: 58.33333%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-7\/12 {
    width: 58.33333%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-7\/12 {
    width: 58.33333%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-7\/12 {
    width: 58.33333%; } }

.o-it-8\/12 {
  width: 66.66667%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-8\/12 {
    width: 66.66667%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-8\/12 {
    width: 66.66667%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-8\/12 {
    width: 66.66667%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-8\/12 {
    width: 66.66667%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-8\/12 {
    width: 66.66667%; } }

.o-it-9\/12 {
  width: 75%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-9\/12 {
    width: 75%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-9\/12 {
    width: 75%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-9\/12 {
    width: 75%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-9\/12 {
    width: 75%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-9\/12 {
    width: 75%; } }

.o-it-10\/12 {
  width: 83.33333%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-10\/12 {
    width: 83.33333%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-10\/12 {
    width: 83.33333%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-10\/12 {
    width: 83.33333%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-10\/12 {
    width: 83.33333%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-10\/12 {
    width: 83.33333%; } }

.o-it-11\/12 {
  width: 91.66667%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-11\/12 {
    width: 91.66667%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-11\/12 {
    width: 91.66667%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-11\/12 {
    width: 91.66667%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-11\/12 {
    width: 91.66667%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-11\/12 {
    width: 91.66667%; } }

.o-it-12\/12 {
  width: 100%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-12\/12 {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-12\/12 {
    width: 100%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-12\/12 {
    width: 100%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-12\/12 {
    width: 100%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-12\/12 {
    width: 100%; } }

.o-it-13\/12 {
  width: 108.33333%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-13\/12 {
    width: 108.33333%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-13\/12 {
    width: 108.33333%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-13\/12 {
    width: 108.33333%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-13\/12 {
    width: 108.33333%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-13\/12 {
    width: 108.33333%; } }

.o-it-14\/12 {
  width: 116.66667%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-14\/12 {
    width: 116.66667%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-14\/12 {
    width: 116.66667%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-14\/12 {
    width: 116.66667%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-14\/12 {
    width: 116.66667%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-14\/12 {
    width: 116.66667%; } }

.o-it-15\/12 {
  width: 125%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-15\/12 {
    width: 125%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-15\/12 {
    width: 125%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-15\/12 {
    width: 125%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-15\/12 {
    width: 125%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-15\/12 {
    width: 125%; } }

.o-it-16\/12 {
  width: 133.33333%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-16\/12 {
    width: 133.33333%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-16\/12 {
    width: 133.33333%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-16\/12 {
    width: 133.33333%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-16\/12 {
    width: 133.33333%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-16\/12 {
    width: 133.33333%; } }

.o-it-1\/13 {
  width: 7.69231%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-1\/13 {
    width: 7.69231%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-1\/13 {
    width: 7.69231%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-1\/13 {
    width: 7.69231%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-1\/13 {
    width: 7.69231%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-1\/13 {
    width: 7.69231%; } }

.o-it-2\/13 {
  width: 15.38462%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-2\/13 {
    width: 15.38462%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-2\/13 {
    width: 15.38462%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-2\/13 {
    width: 15.38462%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-2\/13 {
    width: 15.38462%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-2\/13 {
    width: 15.38462%; } }

.o-it-3\/13 {
  width: 23.07692%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-3\/13 {
    width: 23.07692%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-3\/13 {
    width: 23.07692%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-3\/13 {
    width: 23.07692%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-3\/13 {
    width: 23.07692%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-3\/13 {
    width: 23.07692%; } }

.o-it-4\/13 {
  width: 30.76923%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-4\/13 {
    width: 30.76923%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-4\/13 {
    width: 30.76923%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-4\/13 {
    width: 30.76923%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-4\/13 {
    width: 30.76923%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-4\/13 {
    width: 30.76923%; } }

.o-it-5\/13 {
  width: 38.46154%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-5\/13 {
    width: 38.46154%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-5\/13 {
    width: 38.46154%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-5\/13 {
    width: 38.46154%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-5\/13 {
    width: 38.46154%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-5\/13 {
    width: 38.46154%; } }

.o-it-6\/13 {
  width: 46.15385%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-6\/13 {
    width: 46.15385%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-6\/13 {
    width: 46.15385%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-6\/13 {
    width: 46.15385%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-6\/13 {
    width: 46.15385%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-6\/13 {
    width: 46.15385%; } }

.o-it-7\/13 {
  width: 53.84615%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-7\/13 {
    width: 53.84615%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-7\/13 {
    width: 53.84615%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-7\/13 {
    width: 53.84615%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-7\/13 {
    width: 53.84615%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-7\/13 {
    width: 53.84615%; } }

.o-it-8\/13 {
  width: 61.53846%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-8\/13 {
    width: 61.53846%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-8\/13 {
    width: 61.53846%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-8\/13 {
    width: 61.53846%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-8\/13 {
    width: 61.53846%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-8\/13 {
    width: 61.53846%; } }

.o-it-9\/13 {
  width: 69.23077%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-9\/13 {
    width: 69.23077%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-9\/13 {
    width: 69.23077%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-9\/13 {
    width: 69.23077%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-9\/13 {
    width: 69.23077%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-9\/13 {
    width: 69.23077%; } }

.o-it-10\/13 {
  width: 76.92308%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-10\/13 {
    width: 76.92308%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-10\/13 {
    width: 76.92308%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-10\/13 {
    width: 76.92308%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-10\/13 {
    width: 76.92308%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-10\/13 {
    width: 76.92308%; } }

.o-it-11\/13 {
  width: 84.61538%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-11\/13 {
    width: 84.61538%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-11\/13 {
    width: 84.61538%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-11\/13 {
    width: 84.61538%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-11\/13 {
    width: 84.61538%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-11\/13 {
    width: 84.61538%; } }

.o-it-12\/13 {
  width: 92.30769%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-12\/13 {
    width: 92.30769%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-12\/13 {
    width: 92.30769%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-12\/13 {
    width: 92.30769%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-12\/13 {
    width: 92.30769%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-12\/13 {
    width: 92.30769%; } }

.o-it-13\/13 {
  width: 100%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-13\/13 {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-13\/13 {
    width: 100%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-13\/13 {
    width: 100%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-13\/13 {
    width: 100%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-13\/13 {
    width: 100%; } }

.o-it-14\/13 {
  width: 107.69231%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-14\/13 {
    width: 107.69231%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-14\/13 {
    width: 107.69231%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-14\/13 {
    width: 107.69231%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-14\/13 {
    width: 107.69231%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-14\/13 {
    width: 107.69231%; } }

.o-it-15\/13 {
  width: 115.38462%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-15\/13 {
    width: 115.38462%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-15\/13 {
    width: 115.38462%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-15\/13 {
    width: 115.38462%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-15\/13 {
    width: 115.38462%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-15\/13 {
    width: 115.38462%; } }

.o-it-16\/13 {
  width: 123.07692%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-16\/13 {
    width: 123.07692%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-16\/13 {
    width: 123.07692%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-16\/13 {
    width: 123.07692%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-16\/13 {
    width: 123.07692%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-16\/13 {
    width: 123.07692%; } }

.o-it-1\/14 {
  width: 7.14286%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-1\/14 {
    width: 7.14286%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-1\/14 {
    width: 7.14286%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-1\/14 {
    width: 7.14286%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-1\/14 {
    width: 7.14286%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-1\/14 {
    width: 7.14286%; } }

.o-it-2\/14 {
  width: 14.28571%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-2\/14 {
    width: 14.28571%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-2\/14 {
    width: 14.28571%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-2\/14 {
    width: 14.28571%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-2\/14 {
    width: 14.28571%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-2\/14 {
    width: 14.28571%; } }

.o-it-3\/14 {
  width: 21.42857%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-3\/14 {
    width: 21.42857%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-3\/14 {
    width: 21.42857%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-3\/14 {
    width: 21.42857%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-3\/14 {
    width: 21.42857%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-3\/14 {
    width: 21.42857%; } }

.o-it-4\/14 {
  width: 28.57143%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-4\/14 {
    width: 28.57143%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-4\/14 {
    width: 28.57143%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-4\/14 {
    width: 28.57143%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-4\/14 {
    width: 28.57143%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-4\/14 {
    width: 28.57143%; } }

.o-it-5\/14 {
  width: 35.71429%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-5\/14 {
    width: 35.71429%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-5\/14 {
    width: 35.71429%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-5\/14 {
    width: 35.71429%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-5\/14 {
    width: 35.71429%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-5\/14 {
    width: 35.71429%; } }

.o-it-6\/14 {
  width: 42.85714%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-6\/14 {
    width: 42.85714%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-6\/14 {
    width: 42.85714%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-6\/14 {
    width: 42.85714%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-6\/14 {
    width: 42.85714%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-6\/14 {
    width: 42.85714%; } }

.o-it-7\/14 {
  width: 50%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-7\/14 {
    width: 50%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-7\/14 {
    width: 50%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-7\/14 {
    width: 50%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-7\/14 {
    width: 50%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-7\/14 {
    width: 50%; } }

.o-it-8\/14 {
  width: 57.14286%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-8\/14 {
    width: 57.14286%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-8\/14 {
    width: 57.14286%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-8\/14 {
    width: 57.14286%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-8\/14 {
    width: 57.14286%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-8\/14 {
    width: 57.14286%; } }

.o-it-9\/14 {
  width: 64.28571%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-9\/14 {
    width: 64.28571%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-9\/14 {
    width: 64.28571%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-9\/14 {
    width: 64.28571%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-9\/14 {
    width: 64.28571%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-9\/14 {
    width: 64.28571%; } }

.o-it-10\/14 {
  width: 71.42857%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-10\/14 {
    width: 71.42857%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-10\/14 {
    width: 71.42857%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-10\/14 {
    width: 71.42857%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-10\/14 {
    width: 71.42857%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-10\/14 {
    width: 71.42857%; } }

.o-it-11\/14 {
  width: 78.57143%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-11\/14 {
    width: 78.57143%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-11\/14 {
    width: 78.57143%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-11\/14 {
    width: 78.57143%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-11\/14 {
    width: 78.57143%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-11\/14 {
    width: 78.57143%; } }

.o-it-12\/14 {
  width: 85.71429%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-12\/14 {
    width: 85.71429%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-12\/14 {
    width: 85.71429%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-12\/14 {
    width: 85.71429%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-12\/14 {
    width: 85.71429%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-12\/14 {
    width: 85.71429%; } }

.o-it-13\/14 {
  width: 92.85714%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-13\/14 {
    width: 92.85714%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-13\/14 {
    width: 92.85714%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-13\/14 {
    width: 92.85714%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-13\/14 {
    width: 92.85714%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-13\/14 {
    width: 92.85714%; } }

.o-it-14\/14 {
  width: 100%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-14\/14 {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-14\/14 {
    width: 100%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-14\/14 {
    width: 100%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-14\/14 {
    width: 100%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-14\/14 {
    width: 100%; } }

.o-it-15\/14 {
  width: 107.14286%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-15\/14 {
    width: 107.14286%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-15\/14 {
    width: 107.14286%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-15\/14 {
    width: 107.14286%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-15\/14 {
    width: 107.14286%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-15\/14 {
    width: 107.14286%; } }

.o-it-16\/14 {
  width: 114.28571%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-16\/14 {
    width: 114.28571%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-16\/14 {
    width: 114.28571%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-16\/14 {
    width: 114.28571%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-16\/14 {
    width: 114.28571%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-16\/14 {
    width: 114.28571%; } }

.o-it-1\/15 {
  width: 6.66667%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-1\/15 {
    width: 6.66667%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-1\/15 {
    width: 6.66667%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-1\/15 {
    width: 6.66667%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-1\/15 {
    width: 6.66667%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-1\/15 {
    width: 6.66667%; } }

.o-it-2\/15 {
  width: 13.33333%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-2\/15 {
    width: 13.33333%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-2\/15 {
    width: 13.33333%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-2\/15 {
    width: 13.33333%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-2\/15 {
    width: 13.33333%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-2\/15 {
    width: 13.33333%; } }

.o-it-3\/15 {
  width: 20%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-3\/15 {
    width: 20%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-3\/15 {
    width: 20%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-3\/15 {
    width: 20%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-3\/15 {
    width: 20%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-3\/15 {
    width: 20%; } }

.o-it-4\/15 {
  width: 26.66667%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-4\/15 {
    width: 26.66667%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-4\/15 {
    width: 26.66667%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-4\/15 {
    width: 26.66667%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-4\/15 {
    width: 26.66667%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-4\/15 {
    width: 26.66667%; } }

.o-it-5\/15 {
  width: 33.33333%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-5\/15 {
    width: 33.33333%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-5\/15 {
    width: 33.33333%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-5\/15 {
    width: 33.33333%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-5\/15 {
    width: 33.33333%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-5\/15 {
    width: 33.33333%; } }

.o-it-6\/15 {
  width: 40%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-6\/15 {
    width: 40%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-6\/15 {
    width: 40%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-6\/15 {
    width: 40%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-6\/15 {
    width: 40%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-6\/15 {
    width: 40%; } }

.o-it-7\/15 {
  width: 46.66667%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-7\/15 {
    width: 46.66667%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-7\/15 {
    width: 46.66667%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-7\/15 {
    width: 46.66667%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-7\/15 {
    width: 46.66667%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-7\/15 {
    width: 46.66667%; } }

.o-it-8\/15 {
  width: 53.33333%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-8\/15 {
    width: 53.33333%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-8\/15 {
    width: 53.33333%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-8\/15 {
    width: 53.33333%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-8\/15 {
    width: 53.33333%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-8\/15 {
    width: 53.33333%; } }

.o-it-9\/15 {
  width: 60%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-9\/15 {
    width: 60%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-9\/15 {
    width: 60%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-9\/15 {
    width: 60%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-9\/15 {
    width: 60%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-9\/15 {
    width: 60%; } }

.o-it-10\/15 {
  width: 66.66667%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-10\/15 {
    width: 66.66667%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-10\/15 {
    width: 66.66667%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-10\/15 {
    width: 66.66667%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-10\/15 {
    width: 66.66667%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-10\/15 {
    width: 66.66667%; } }

.o-it-11\/15 {
  width: 73.33333%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-11\/15 {
    width: 73.33333%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-11\/15 {
    width: 73.33333%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-11\/15 {
    width: 73.33333%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-11\/15 {
    width: 73.33333%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-11\/15 {
    width: 73.33333%; } }

.o-it-12\/15 {
  width: 80%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-12\/15 {
    width: 80%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-12\/15 {
    width: 80%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-12\/15 {
    width: 80%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-12\/15 {
    width: 80%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-12\/15 {
    width: 80%; } }

.o-it-13\/15 {
  width: 86.66667%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-13\/15 {
    width: 86.66667%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-13\/15 {
    width: 86.66667%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-13\/15 {
    width: 86.66667%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-13\/15 {
    width: 86.66667%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-13\/15 {
    width: 86.66667%; } }

.o-it-14\/15 {
  width: 93.33333%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-14\/15 {
    width: 93.33333%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-14\/15 {
    width: 93.33333%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-14\/15 {
    width: 93.33333%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-14\/15 {
    width: 93.33333%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-14\/15 {
    width: 93.33333%; } }

.o-it-15\/15 {
  width: 100%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-15\/15 {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-15\/15 {
    width: 100%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-15\/15 {
    width: 100%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-15\/15 {
    width: 100%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-15\/15 {
    width: 100%; } }

.o-it-16\/15 {
  width: 106.66667%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-16\/15 {
    width: 106.66667%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-16\/15 {
    width: 106.66667%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-16\/15 {
    width: 106.66667%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-16\/15 {
    width: 106.66667%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-16\/15 {
    width: 106.66667%; } }

.o-it-1\/16 {
  width: 6.25%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-1\/16 {
    width: 6.25%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-1\/16 {
    width: 6.25%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-1\/16 {
    width: 6.25%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-1\/16 {
    width: 6.25%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-1\/16 {
    width: 6.25%; } }

.o-it-2\/16 {
  width: 12.5%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-2\/16 {
    width: 12.5%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-2\/16 {
    width: 12.5%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-2\/16 {
    width: 12.5%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-2\/16 {
    width: 12.5%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-2\/16 {
    width: 12.5%; } }

.o-it-3\/16 {
  width: 18.75%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-3\/16 {
    width: 18.75%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-3\/16 {
    width: 18.75%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-3\/16 {
    width: 18.75%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-3\/16 {
    width: 18.75%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-3\/16 {
    width: 18.75%; } }

.o-it-4\/16 {
  width: 25%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-4\/16 {
    width: 25%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-4\/16 {
    width: 25%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-4\/16 {
    width: 25%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-4\/16 {
    width: 25%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-4\/16 {
    width: 25%; } }

.o-it-5\/16 {
  width: 31.25%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-5\/16 {
    width: 31.25%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-5\/16 {
    width: 31.25%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-5\/16 {
    width: 31.25%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-5\/16 {
    width: 31.25%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-5\/16 {
    width: 31.25%; } }

.o-it-6\/16 {
  width: 37.5%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-6\/16 {
    width: 37.5%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-6\/16 {
    width: 37.5%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-6\/16 {
    width: 37.5%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-6\/16 {
    width: 37.5%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-6\/16 {
    width: 37.5%; } }

.o-it-7\/16 {
  width: 43.75%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-7\/16 {
    width: 43.75%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-7\/16 {
    width: 43.75%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-7\/16 {
    width: 43.75%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-7\/16 {
    width: 43.75%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-7\/16 {
    width: 43.75%; } }

.o-it-8\/16 {
  width: 50%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-8\/16 {
    width: 50%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-8\/16 {
    width: 50%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-8\/16 {
    width: 50%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-8\/16 {
    width: 50%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-8\/16 {
    width: 50%; } }

.o-it-9\/16 {
  width: 56.25%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-9\/16 {
    width: 56.25%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-9\/16 {
    width: 56.25%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-9\/16 {
    width: 56.25%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-9\/16 {
    width: 56.25%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-9\/16 {
    width: 56.25%; } }

.o-it-10\/16 {
  width: 62.5%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-10\/16 {
    width: 62.5%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-10\/16 {
    width: 62.5%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-10\/16 {
    width: 62.5%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-10\/16 {
    width: 62.5%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-10\/16 {
    width: 62.5%; } }

.o-it-11\/16 {
  width: 68.75%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-11\/16 {
    width: 68.75%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-11\/16 {
    width: 68.75%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-11\/16 {
    width: 68.75%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-11\/16 {
    width: 68.75%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-11\/16 {
    width: 68.75%; } }

.o-it-12\/16 {
  width: 75%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-12\/16 {
    width: 75%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-12\/16 {
    width: 75%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-12\/16 {
    width: 75%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-12\/16 {
    width: 75%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-12\/16 {
    width: 75%; } }

.o-it-13\/16 {
  width: 81.25%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-13\/16 {
    width: 81.25%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-13\/16 {
    width: 81.25%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-13\/16 {
    width: 81.25%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-13\/16 {
    width: 81.25%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-13\/16 {
    width: 81.25%; } }

.o-it-14\/16 {
  width: 87.5%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-14\/16 {
    width: 87.5%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-14\/16 {
    width: 87.5%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-14\/16 {
    width: 87.5%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-14\/16 {
    width: 87.5%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-14\/16 {
    width: 87.5%; } }

.o-it-15\/16 {
  width: 93.75%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-15\/16 {
    width: 93.75%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-15\/16 {
    width: 93.75%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-15\/16 {
    width: 93.75%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-15\/16 {
    width: 93.75%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-15\/16 {
    width: 93.75%; } }

.o-it-16\/16 {
  width: 100%; }

@media only screen and (max-width: 319px) {
  .o-it-xs-16\/16 {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .o-it-sm-16\/16 {
    width: 100%; } }

@media only screen and (max-width: 1023px) {
  .o-it-md-16\/16 {
    width: 100%; } }

@media only screen and (max-width: 1279px) {
  .o-it-lg-16\/16 {
    width: 100%; } }

@media only screen and (max-width: 3499px) {
  .o-it-xl-16\/16 {
    width: 100%; } }

/*------------------------------------
         # END OBJECTS UNITS
     ------------------------------------*/
@media only screen and (max-width: 1023px) {
  .o-unit--margin {
    margin-bottom: 20px; } }

@media only screen and (max-width: 767px) {
  .o-unit--margin {
    margin-bottom: 10px; } }

/*------------------------------------
    # OBJECT WRAPPER
------------------------------------*/
.wrapper {
  max-width: 61.875rem;
  margin: 0 auto; }

/*------------------------------------
    # END OBJECT WRAPPER
------------------------------------*/
/*----------------------------------------------
   # módulo accordion
------------------------------------------------*/
.ppg-accordion__heading {
  position: relative;
  border: 1px solid #dddddd;
  padding: 1.5625rem 3.125rem 1.5625rem 1.5625rem;
  margin-bottom: 1.25rem;
  color: #333333;
  font-size: 1.125rem;
  cursor: pointer; }
  .ppg-accordion__heading .ui-accordion-header-icon {
    position: absolute;
    top: calc((100% - 25px)/2);
    right: 1.5625rem; }
    .ppg-accordion__heading .ui-accordion-header-icon:before {
      display: inline-block;
      content: '';
      width: 0.875rem;
      height: 0.625rem;
      background: url("../assets/img/icons/files/icon-flecha-negra--2d.png") no-repeat center; }
  .ppg-accordion__heading:hover, .ppg-accordion__heading:focus {
    background-color: #fff4f0;
    color: #c33400; }
    .ppg-accordion__heading:hover .ui-accordion-header-icon:before, .ppg-accordion__heading:focus .ui-accordion-header-icon:before {
      background: url("../assets/img/icons/files/icon-flecha-roja--2d.png") no-repeat center; }
  .ppg-accordion__heading.ui-state-active {
    background-color: #fff4f0;
    color: #c33400; }
    .ppg-accordion__heading.ui-state-active .ui-accordion-header-icon:before {
      transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      background: url("../assets/img/icons/files/icon-flecha-roja--2d.png") no-repeat center; }

.ppg-accordion__content {
  overflow: auto;
  padding: 0 1.5625rem 1.5625rem 1.5625rem; }

/*----------------------------------------------
   # Estilos para el body
------------------------------------------------*/
.body--type02 {
  background-color: white; }

/*------------------------------------
    # BORDER
------------------------------------*/
.ppg-brd {
  border: 1px solid #c1c1c1; }
  .ppg-brd--top {
    border: 0;
    border-top: 1px solid #c1c1c1; }
  .ppg-brd--bottom {
    border: 0;
    border-bottom: 1px solid #c1c1c1; }
  .ppg-brd--left {
    border: 0;
    border-left: 1px solid #c1c1c1; }
  .ppg-brd--right {
    border: 0;
    border-right: 1px solid #c1c1c1; }

/*----------------------------------------------
   # BUTTONS: estilización de los botones
------------------------------------------------*/
.ppg-button {
  border-radius: 0.1875rem;
  background-color: #c33400;
  width: 100%;
  border: 1px solid transparent;
  padding: 0.8125rem 0.3125rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: white;
  text-align: center;
  font-size: 0.875rem;
  cursor: pointer; }
  .ppg-button:focus {
    background-color: #c33400;
    border: 1px solid white;
    box-shadow: 0 0 0 2px #c33400;
    text-decoration: none;
    outline: none; }
  .ppg-button--type02 {
    background-color: transparent;
    color: #c33400;
    border: 1px solid transparent; }
    .ppg-button--type02:focus {
      background-color: transparent;
      outline: none;
      box-shadow: 0 0 5px 0 #c33400;
      border: solid 1px #bc3302; }
  .ppg-button--type03 {
    background-color: transparent;
    color: #333333;
    text-align: right; }
  .ppg-button--type04 {
    background-color: white;
    color: #c33400;
    border: 1px solid #c33400;
    border-radius: 5px; }
    .ppg-button--type04:hover {
      background-color: #fff4f0; }
    .ppg-button--type04:focus {
      background-color: white;
      color: #c33400;
      border: 1px solid #c33400;
      box-shadow: 0 0 0 2px #c33400; }
  .ppg-button--nopd {
    padding-top: 0.40625rem;
    padding-bottom: 0.40625rem; }

.ppg-header .btn-default {
  border: 0;
  box-shadow: none;
  padding-right: 1.125rem;
  padding-left: 0.3125rem; }
  .ppg-header .btn-default .filter-option.filter-option {
    text-align: right; }
  .ppg-header .btn-default .bs-caret .caret {
    right: 0.3125rem; }
  .ppg-header .btn-default:hover {
    background: white; }
  .ppg-header .btn-default:focus {
    box-shadow: 0 0 5px 0 #c33400;
    border: solid 1px #bc3302;
    border-radius: 1px;
    background: white;
    outline: none; }
  .ppg-header .btn-default span {
    text-align: center !important; }

.ppg-header__list span {
  text-align: center !important; }
  .ppg-header__list span .ui-selectmenu-text {
    margin-right: 0;
    padding-right: 1.25rem; }

.ppg-form--button .ppg-button {
  width: 60%;
  margin-left: 1.25rem; }

.ppg-main__search .ppg-button {
  width: 2.875rem;
  height: 2.875rem;
  position: absolute;
  top: 0;
  right: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

/*----------------------------------------------
   # BUTTONS: estilización del carrusel
------------------------------------------------*/
.ppg-carrusel {
  background-color: #e9e9e9;
  padding: 3.375rem 12.5rem; }
  .ppg-carrusel .ppg-carrusel3__fig {
    width: 8.9375rem;
    height: 5.625rem;
    padding: 0 1.1875rem; }
  .ppg-carrusel .ppg-carrusel3__img {
    width: 100%;
    height: 100%; }

/*----------------------------------------------
   # PPG-DATE
------------------------------------------------*/
.ppg-date {
  text-align: center;
  border: 1px solid #c9c6c6;
  color: #c33400;
  font-weight: bold;
  padding: 0.625rem;
  background-color: #efefef; }
  .ppg-date__month {
    display: block; }

/*----------------------------------------------
   # módulo datepicker: sobreescribe los estilos de jquery ui para el componente  datepicker
------------------------------------------------*/
.ui-datepicker {
  background: white;
  width: 18.75rem;
  border: 1px solid #dddddd;
  padding: 0.9375rem;
  box-sizing: border-box;
  box-shadow: 0 2px 4px 0 #9b9b9b;
  margin-top: 1px; }
  .ui-datepicker .ui-state-default {
    color: #333333;
    border: 1px solid transparent;
    border-radius: 4px;
    height: 100%;
    display: block;
    padding: 0.625rem; }
    .ui-datepicker .ui-state-default:hover {
      border: 1px solid #bbbbbb;
      background: #fff4f0;
      text-decoration: none; }
  .ui-datepicker .ui-state-active {
    background: #c33400;
    color: white; }
    .ui-datepicker .ui-state-active.ui-state-hover {
      background: #c33400;
      color: white; }

.ui-datepicker-header {
  position: relative;
  text-align: center;
  padding-bottom: 0.625rem; }
  .ui-datepicker-header .ui-datepicker-month {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold; }
  .ui-datepicker-header .ui-datepicker-year {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold; }

.ui-datepicker-calendar {
  width: 100%; }
  .ui-datepicker-calendar span {
    font-weight: bold; }
  .ui-datepicker-calendar tbody {
    text-align: center;
    color: #333333; }

.ui-datepicker-prev {
  text-indent: -999999px;
  position: absolute;
  width: 0.6875rem;
  height: 0.6875rem;
  left: 0.125rem;
  top: 0.3125rem; }
  .ui-datepicker-prev span {
    width: 0.5rem;
    height: 0.6875rem;
    display: block;
    position: absolute;
    background-image: url("../assets/img/icons/spritesheet.png");
    background-position: -341px -67px;
    text-indent: -99999px;
    overflow: hidden;
    background-repeat: no-repeat;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }
  .ui-datepicker-prev.ui-state-disabled span {
    background-position: -23px -67px;
    width: 0.5rem;
    height: 0.6875rem;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }

.ui-datepicker-next {
  text-indent: -999999px;
  position: absolute;
  right: 0.125rem;
  top: 0.3125rem;
  width: 0.6875rem;
  height: 0.6875rem; }
  .ui-datepicker-next span {
    width: 0.5rem;
    height: 0.6875rem;
    display: block;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    background-image: url("../assets/img/icons/spritesheet.png");
    background-position: -341px -67px;
    text-indent: -99999px;
    overflow: hidden;
    background-repeat: no-repeat; }

/*----------------------------------------------
   # módulo
------------------------------------------------*/
.ppg-duplicated {
  display: none; }

@media only screen and (max-width: 1023px) {
  .ppg-duplicated {
    display: block; } }

/*----------------------------------------------
   # módulo footer
------------------------------------------------*/
.ppg-footer {
  background: #efefef; }
  .ppg-footer__row {
    padding: 1.0625rem 0; }
    .ppg-footer__row:last-child {
      border: 0; }
  .ppg-footer__list {
    width: 10.9375rem;
    display: inline-block;
    vertical-align: top;
    text-align: left; }
  .ppg-footer__link:first-child .ppg-link {
    padding-left: 0; }
  .ppg-footer__copyright {
    width: 8.75rem;
    text-align: left; }
  .ppg-footer__copyright-title.ppg-footer__copyright-title {
    font-size: 0.625rem;
    font-weight: bold; }
  .ppg-footer__item--inline {
    padding-top: 0.625rem; }

@media only screen and (max-width: 1023px) {
  .ppg-footer {
    padding-left: 1.25rem;
    padding-right: 1.25rem; } }

@media only screen and (max-width: 767px) {
  .ppg-footer {
    text-align: center; }
    .ppg-footer__ue {
      width: 3.9375rem; }
    .ppg-footer__copyright.ppg-footer__copyright {
      width: 6.875rem;
      margin-left: -0.625rem; }
    .ppg-footer__copyright-title.ppg-footer__copyright-title {
      font-size: 0.5rem; } }

/*----------------------------------------------
   # módulo
------------------------------------------------*/
.ppg-form {
  padding-top: 1.5625rem;
  padding-bottom: 1.5625rem;
  background-color: rgba(227, 227, 227, 0.82); }
  .ppg-form .ppg-form__group--nopad {
    padding-left: 0; }
  .ppg-form__title {
    padding-left: 0.46875rem;
    display: inline-block; }
    .ppg-form__title:after {
      content: "";
      width: 1.5625rem;
      height: 1.5625rem;
      background: url("../assets/img/icons/svg/icon-ayuda.svg");
      margin-left: 0.625rem;
      display: inline-block;
      vertical-align: text-bottom; }
  .ppg-form__group {
    position: relative;
    margin-left: -0.46875rem;
    margin-right: -0.46875rem; }
    .ppg-form__group:after {
      position: absolute;
      right: 0.9375rem;
      top: 50%;
      transform: translate(0, -50%); }
    .ppg-form__group--datepicker {
      display: inline-block; }
    .ppg-form__group--nolabel {
      padding-top: 1.875rem; }
  .ppg-form__title {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 1.25rem;
    color: #333333;
    margin-bottom: 0.9375rem; }
  .ppg-form__row {
    padding-bottom: 1.25rem; }
  .ppg-form__content {
    display: none; }
  .ppg-form__fieldset {
    margin-bottom: 1.25rem;
    padding-bottom: 1.25rem;
    border-bottom: 1px solid #c9c6c6; }
    .ppg-form__fieldset:first-child {
      margin-top: 0; }
    .ppg-form__fieldset:last-child {
      padding-bottom: 0;
      border-bottom: none; }
    .ppg-form__fieldset--flush-br {
      border-bottom: none; }
    .ppg-form__fieldset--submit {
      padding-left: 0.9375rem;
      padding-right: 0.9375rem; }
      .ppg-form__fieldset--submit .ppg-link.ppg-icon__crossR-3a.open:after {
        transform: rotate(180deg); }
  .ppg-form__legend {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.125rem;
    color: #c33400;
    display: block;
    padding-left: 0.46875rem; }
  .ppg-form__label {
    display: block;
    font-family: Arial, Helvetica, sans-serif;
    color: #333333;
    font-size: 0.875rem;
    font-weight: bold;
    padding: 0.3125rem 0; }
    .ppg-form__label--left {
      display: inline-block;
      font-size: 0.875rem; }
    .ppg-form__label--right {
      font-size: 0.875rem;
      position: absolute;
      bottom: 0;
      left: 5.625rem;
      transform: translate(0, -50%); }
    .ppg-form__label--abs {
      position: absolute;
      top: -1.5625rem;
      left: 0; }
  .ppg-form__optionBox {
    display: inline-block;
    border: 1px solid #bbbbbb;
    border-radius: 3px;
    background-color: #f4f4f4;
    width: 100%; }
    .ppg-form__optionBox .ppg-form__input--radio:checked + .ppg-form__checkbox {
      background-color: white;
      border: 1px solid #bbbbbb;
      font-weight: bold; }
  .ppg-form__checkbox {
    display: inline-block;
    height: 2.5rem;
    border-radius: 3px;
    border: 1px solid transparent;
    padding: 0.4375rem 0.5625rem 0.8125rem 0.5625rem;
    position: relative;
    width: 49.2%;
    font-size: 0.875rem;
    text-align: center; }
    .ppg-form__checkbox:hover {
      background-color: #bbbbbb; }
  .ppg-form__input {
    height: 2.5rem;
    width: 100%;
    border-radius: 3px;
    border: 1px solid #bbbbbb;
    padding: 0.625rem;
    position: relative; }
    .ppg-form__input--disabled {
      background-color: #f3f3f3; }
    .ppg-form__input--error {
      border: 1px solid #d0011b; }
    .ppg-form__input--nolabel {
      margin-top: 1.875rem; }
    .ppg-form__input--number {
      display: inline-block;
      width: 4.0625rem; }
    .ppg-form__input--date {
      display: inline-block;
      width: 9.0625rem;
      padding-right: 1.875rem;
      font-size: 0.875rem; }
    .ppg-form__input:focus {
      outline: none;
      border: 1px solid #444444; }
  .ppg-form__submitbox {
    text-align: right;
    width: 18.75rem;
    float: right; }

.ppg-form--options .ppg-form__group {
  margin: 1.25rem 0;
  width: 20rem; }
  .ppg-form--options .ppg-form__group .ppg-form__optionBox {
    width: 100%; }

@media only screen and (max-width: 1023px) {
  .ppg-form__group {
    margin-bottom: 0.9375rem; }
    .ppg-form__group--datepicker:after {
      right: 0.625rem; }
  .ppg-form__optionBox {
    margin-bottom: 0.625rem; }
  .ppg-form__input--date {
    width: 8.125rem; }
  .ppg-form__label--abs {
    top: -2.1875rem; } }

@media only screen and (max-width: 767px) {
  .ppg-form .ppg-form__group--nopad {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .ppg-form__title {
    font-size: 1.0625rem; }
  .ppg-form__group {
    margin-bottom: 0.9375rem; }
    .ppg-form__group--datepicker {
      vertical-align: bottom; }
      .ppg-form__group--datepicker .ppg-form__input--nolabel {
        margin-top: 0; }
      .ppg-form__group--datepicker-to {
        padding-top: 1.875rem; }
      .ppg-form__group--datepicker .ppg-form__label {
        display: block; }
    .ppg-form__group--flush {
      margin-left: -0.9375rem;
      margin-right: -0.9375rem; }
    .ppg-form__group:after {
      top: 70%;
      transform: translate(0, -50%); }
  .ppg-form__label {
    text-align: left; }
    .ppg-form__label--abs {
      top: -1.5625rem; }
    .ppg-form__label--right {
      position: absolute;
      bottom: 0;
      right: -0.625rem;
      transform: translate(0, -50%); }
  .ppg-form__input--nolabel {
    margin-top: 0; }
  .ppg-form__input--date {
    width: 100%; }
  .ppg-form__fieldset--submit {
    margin-top: 0;
    text-align: center; }
    .ppg-form__fieldset--submit > a {
      padding-bottom: 0.3125rem;
      padding-top: 0.3125rem; }
    .ppg-form__fieldset--submit .ppg-form__submitbox {
      text-align: center;
      display: block;
      width: 100%; }
      .ppg-form__fieldset--submit .ppg-form__submitbox > a {
        display: block;
        padding-top: 0.3125rem;
        padding-bottom: 0.3125rem; }
      .ppg-form__fieldset--submit .ppg-form__submitbox > input[type="submit"] {
        margin: 0;
        display: block;
        width: 100%; }
  .ppg-form--options .ppg-form__group {
    width: 100%; } }

/*----------------------------------------------
   # módulo
------------------------------------------------*/
.ppg-header {
  background-color: white; }
  .ppg-header__row {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem; }
  .ppg-header__logo {
    padding-top: 2.1875rem;
    padding-bottom: 0.9375rem; }
    .ppg-header__logo img {
      height: 3.75rem;
      width: 22.125rem; }
  .ppg-header__list {
    float: right;
    display: block; }
  .ppg-header__item.ppg-header__item {
    box-sizing: border-box;
    padding-top: 0.1875rem;
    position: relative;
    margin-bottom: 0.3125rem;
    width: 7.125rem;
    text-align: center;
    cursor: pointer; }
  .ppg-header__item--button.ppg-header__item--button {
    margin-left: 0.625rem;
    padding: 0;
    box-sizing: border-box; }
  .ppg-header__item:after {
    position: absolute;
    top: 45%;
    right: 0.625rem;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%); }
  .ppg-header__search {
    width: 23.125rem;
    float: right;
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
    position: relative; }
    .ppg-header__search:after {
      position: absolute;
      top: 50%;
      right: 0.625rem;
      -webkit-transform: translate(0, -50%);
      -moz-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      -o-transform: translate(0, -50%);
      transform: translate(0, -50%); }

@media only screen and (max-width: 1023px) {
  .ppg-header__row {
    margin: 0; }
  .ppg-header__item--button.ppg-header__item--button {
    margin-bottom: 0.625rem; }
  .ppg-header__search {
    width: 21.875rem;
    margin-top: 0; }
  .ppg-header__logo {
    margin-top: 0.3125rem; }
    .ppg-header__logo img {
      width: 19.8125rem;
      height: 3.3125rem; } }

@media only screen and (max-width: 767px) {
  .ppg-header__buttons {
    display: none; }
  .ppg-header__search {
    display: none; }
  .ppg-header__logo {
    margin: 0.625rem auto;
    padding: 0;
    text-align: center; }
    .ppg-header__logo img {
      width: 14.125rem;
      height: 2.3125rem; } }

/*----------------------------------------------
   # HEADINGS: estilización de los encabezados
------------------------------------------------*/
.ppg-heading {
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: 2rem;
  color: #c33400; }
  .ppg-heading--bold {
    font-weight: bold; }
  .ppg-heading--white {
    color: white; }
  .ppg-heading--black {
    color: #333333; }
  .ppg-heading--wind {
    margin-top: 1.25rem;
    margin-bottom: 1.875rem; }
  .ppg-heading--brd {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    margin-bottom: 0.625rem;
    border-bottom: 1px solid #dddddd; }
  .ppg-heading--wind02 {
    margin-bottom: 1.3125rem; }
  .ppg-heading--type02 {
    font-size: 1.125rem; }

.ppg-main__search .ppg-heading {
  margin-bottom: 1.25rem; }

.ppg-table .ppg-heading {
  color: black;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.38;
  text-align: left; }
  .ppg-table .ppg-heading--type02 {
    border-bottom: none;
    color: #333333;
    padding: 0.625rem 0;
    margin: 0; }

.ppg-table--type02 .ppg-heading {
  font-size: 0.75rem;
  color: #333333;
  display: inline-block;
  line-height: 1.83; }
  .ppg-table--type02 .ppg-heading--type02 {
    padding: 0;
    padding-left: 1.875rem; }
  .ppg-table--type02 .ppg-heading--type03 {
    display: block;
    padding-bottom: 0.625rem;
    margin-bottom: 0.9375rem;
    font-size: 1rem;
    border-bottom: 1px solid #dddddd; }
  .ppg-table--type02 .ppg-heading--type04 {
    color: #c9c6c6; }

@media only screen and (max-width: 1279px) {
  .ppg-main__search .ppg-heading {
    margin-bottom: 1rem; } }

@media only screen and (max-width: 767px) {
  .ppg-main__search .ppg-heading {
    font-size: 1.75rem;
    max-width: 17.625rem;
    margin: 0 auto;
    margin-bottom: 1.0625rem;
    margin-top: -1.4375rem; } }

@media only screen and (max-width: 767px) {
  .ppg-heading {
    font-size: 1.25rem; }
    .ppg-heading--wind02 {
      margin-bottom: 0.78125rem; }
    .ppg-heading--type02 {
      font-size: 1rem; } }

[class*=" ppg-icon"]:after,
[class^="ppg-icon"]:after {
  content: "";
  background-image: url("../assets/img/icons/spritesheet.png");
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.3125rem; }

.ppg-icon__fb-a:after, .ppg-icon__fb-a:before {
  width: 51px;
  height: 50px;
  background-position: -5px -5px; }

.ppg-icon__fb-b:after, .ppg-icon__fb-b:before {
  width: 102px;
  height: 100px;
  background-position: -66px -5px; }

.ppg-icon__fb-c:after, .ppg-icon__fb-c:before {
  width: 153px;
  height: 150px;
  background-position: -178px -5px; }

.ppg-icon__060-a:after, .ppg-icon__060-a:before {
  width: 40px;
  height: 40px;
  background-position: -341px -5px; }

.ppg-icon__060-b:after, .ppg-icon__060-b:before {
  width: 80px;
  height: 80px;
  background-position: -391px -5px; }

.ppg-icon__060-c:after, .ppg-icon__060-c:before {
  width: 120px;
  height: 120px;
  background-position: -341px -95px; }

.ppg-icon__acc-a:after, .ppg-icon__acc-a:before {
  width: 40px;
  height: 40px;
  background-position: -481px -5px; }

.ppg-icon__acc-b:after, .ppg-icon__acc-b:before {
  width: 80px;
  height: 80px;
  background-position: -481px -55px; }

.ppg-icon__acc-c:after, .ppg-icon__acc-c:before {
  width: 120px;
  height: 120px;
  background-position: -5px -225px; }

.ppg-icon__help-a:after, .ppg-icon__help-a:before {
  width: 25px;
  height: 25px;
  background-position: -531px -5px; }

.ppg-icon__help-b:after, .ppg-icon__help-b:before {
  width: 50px;
  height: 50px;
  background-position: -5px -145px; }

.ppg-icon__help-c:after, .ppg-icon__help-c:before {
  width: 75px;
  height: 75px;
  background-position: -471px -145px; }

.ppg-icon__bullet-a:after, .ppg-icon__bullet-a:before {
  width: 8px;
  height: 10px;
  background-position: -566px -5px; }

.ppg-icon__bullet-b:after, .ppg-icon__bullet-b:before {
  width: 14px;
  height: 14px;
  background-position: -566px -22px; }

.ppg-icon__bullet-c:after, .ppg-icon__bullet-c:before {
  width: 21px;
  height: 21px;
  background-position: -65px -145px; }

.ppg-icon__mailbox-a:after, .ppg-icon__mailbox-a:before {
  width: 24px;
  height: 18px;
  background-position: -96px -145px; }

.ppg-icon__mailbox-b:after, .ppg-icon__mailbox-b:before {
  width: 48px;
  height: 36px;
  background-position: -135px -230px; }

.ppg-icon__mailbox-c:after, .ppg-icon__mailbox-c:before {
  width: 72px;
  height: 54px;
  background-position: -193px -230px; }

.ppg-icon__mailgrey-a:after, .ppg-icon__mailgrey-a:before {
  width: 22px;
  height: 17px;
  background-position: -130px -145px; }

.ppg-icon__mailgrey-b:after, .ppg-icon__mailgrey-b:before {
  width: 44px;
  height: 34px;
  background-position: -275px -230px; }

.ppg-icon__mailgrey-c:after, .ppg-icon__mailgrey-c:before {
  width: 66px;
  height: 51px;
  background-position: -329px -230px; }

.ppg-icon__calendar-a:after, .ppg-icon__calendar-a:before {
  width: 19px;
  height: 20px;
  background-position: -556px -145px; }

.ppg-icon__calendar-b:after, .ppg-icon__calendar-b:before {
  width: 38px;
  height: 40px;
  background-position: -405px -230px; }

.ppg-icon__calendar-c:after, .ppg-icon__calendar-c:before {
  width: 57px;
  height: 60px;
  background-position: -453px -230px; }

.ppg-icon__folder-a:after, .ppg-icon__folder-a:before {
  width: 40px;
  height: 27px;
  background-position: -520px -230px; }

.ppg-icon__folder-b:after, .ppg-icon__folder-b:before {
  width: 80px;
  height: 54px;
  background-position: -135px -300px; }

.ppg-icon__folder-c:after, .ppg-icon__folder-c:before {
  width: 120px;
  height: 81px;
  background-position: -225px -304px; }

.ppg-icon__chat-a:after, .ppg-icon__chat-a:before {
  width: 23px;
  height: 21px;
  background-position: -96px -175px; }

.ppg-icon__chat-b:after, .ppg-icon__chat-b:before {
  width: 46px;
  height: 42px;
  background-position: -355px -304px; }

.ppg-icon__chat-c:after, .ppg-icon__chat-c:before {
  width: 69px;
  height: 63px;
  background-position: -411px -304px; }

.ppg-icon__share-a:after, .ppg-icon__share-a:before {
  width: 18px;
  height: 18px;
  background-position: -129px -175px; }

.ppg-icon__share-b:after, .ppg-icon__share-b:before {
  width: 36px;
  height: 36px;
  background-position: -490px -304px; }

.ppg-icon__share-c:after, .ppg-icon__share-c:before {
  width: 54px;
  height: 54px;
  background-position: -490px -350px; }

.ppg-icon__download-a:after, .ppg-icon__download-a:before {
  width: 14px;
  height: 21px;
  background-position: -157px -175px; }

.ppg-icon__download-b:after, .ppg-icon__download-b:before {
  width: 28px;
  height: 42px;
  background-position: -5px -414px; }

.ppg-icon__download-c:after, .ppg-icon__download-c:before {
  width: 42px;
  height: 63px;
  background-position: -43px -414px; }

.ppg-icon__address-a:after, .ppg-icon__address-a:before {
  width: 22px;
  height: 29px;
  background-position: -181px -175px; }

.ppg-icon__address-b:after, .ppg-icon__address-b:before {
  width: 44px;
  height: 58px;
  background-position: -95px -414px; }

.ppg-icon__address-c:after, .ppg-icon__address-c:before {
  width: 66px;
  height: 87px;
  background-position: -149px -414px; }

.ppg-icon__link-a:after, .ppg-icon__link-a:before {
  width: 16px;
  height: 16px;
  background-position: -213px -175px; }

.ppg-icon__link-b:after, .ppg-icon__link-b:before {
  width: 32px;
  height: 32px;
  background-position: -536px -304px; }

.ppg-icon__link-c:after, .ppg-icon__link-c:before {
  width: 48px;
  height: 48px;
  background-position: -225px -414px; }

.ppg-icon__listen-a:after, .ppg-icon__listen-a:before {
  width: 23px;
  height: 20px;
  background-position: -239px -175px; }

.ppg-icon__listen-b:after, .ppg-icon__listen-b:before {
  width: 46px;
  height: 40px;
  background-position: -283px -414px; }

.ppg-icon__listen-c:after, .ppg-icon__listen-c:before {
  width: 69px;
  height: 60px;
  background-position: -339px -414px; }

.ppg-icon__crossW-2a:after, .ppg-icon__crossW-2a:before {
  width: 8px;
  height: 11px;
  background-position: -571px -46px; }

.ppg-icon__crossW-2a-Light:after, .ppg-icon__crossW-2a-Light:before {
  width: 8px;
  height: 11px;
  background-position: -5px -67px; }

.ppg-icon__crossW-2b:after, .ppg-icon__crossW-2b:before {
  width: 16px;
  height: 22px;
  background-position: -272px -175px; }

.ppg-icon__crossW-2b-Light:after, .ppg-icon__crossW-2b-Light:before {
  width: 16px;
  height: 27px;
  background-position: -298px -175px; }

.ppg-icon__crossW-2c:after, .ppg-icon__crossW-2c:before {
  width: 24px;
  height: 33px;
  background-position: -556px -175px; }

.ppg-icon__crossW-3:after, .ppg-icon__crossW-3:before {
  width: 11px;
  height: 7px;
  background-position: -554px -346px; }

.ppg-icon__crossW-3a:after, .ppg-icon__crossW-3a:before {
  width: 11px;
  height: 7px;
  background-position: -5px -363px; }

.ppg-icon__crossW-3b:after, .ppg-icon__crossW-3b:before {
  width: 22px;
  height: 14px;
  background-position: -26px -363px; }

.ppg-icon__crossW-3c:after, .ppg-icon__crossW-3c:before {
  width: 33px;
  height: 21px;
  background-position: -418px -414px; }

.ppg-icon__crossW-4:after, .ppg-icon__crossW-4:before {
  width: 16px;
  height: 27px;
  background-position: -58px -363px; }

.ppg-icon__crossDisable-4aLight:after, .ppg-icon__crossDisable-4aLight:before {
  width: 16px;
  height: 27px;
  background-position: -84px -363px; }

.ppg-icon__crossDisable-4bLight:after, .ppg-icon__crossDisable-4bLight:before {
  width: 32px;
  height: 54px;
  background-position: -461px -414px; }

.ppg-icon__crossDisable-4cLight:after, .ppg-icon__crossDisable-4cLight:before {
  width: 48px;
  height: 81px;
  background-position: -503px -414px; }

.ppg-icon__crossB-2a:after, .ppg-icon__crossB-2a:before {
  width: 8px;
  height: 11px;
  background-position: -23px -67px; }

.ppg-icon__crossB-2b:after, .ppg-icon__crossB-2b:before {
  width: 16px;
  height: 22px;
  background-position: -355px -363px; }

.ppg-icon__crossB-2c:after, .ppg-icon__crossB-2c:before {
  width: 24px;
  height: 33px;
  background-position: -554px -363px; }

.ppg-icon__crossB-3a-filled:after, .ppg-icon__crossB-3a-filled:before {
  width: 9px;
  height: 7px;
  background-position: -41px -67px; }

.ppg-icon__crossB-3b-filled:after, .ppg-icon__crossB-3b-filled:before {
  width: 18px;
  height: 14px;
  background-position: -561px -406px; }

.ppg-icon__crossB-3c-filled:after, .ppg-icon__crossB-3c-filled:before {
  width: 27px;
  height: 21px;
  background-position: -5px -505px; }

.ppg-icon__crossB-3c-filled:after, .ppg-icon__crossB-3c-filled:before {
  width: 27px;
  height: 21px;
  background-position: -5px -505px; }

.ppg-icon__crossR-1a:after, .ppg-icon__crossR-1a:before {
  width: 11px;
  height: 7px;
  background-position: -110px -363px; }

.ppg-icon__crossR-1b:after, .ppg-icon__crossR-1b:before {
  width: 22px;
  height: 14px;
  background-position: -42px -505px; }

.ppg-icon__crossR-1c:after, .ppg-icon__crossR-1c:before {
  width: 33px;
  height: 21px;
  background-position: -74px -505px; }

.ppg-icon__crossR-2a:after, .ppg-icon__crossR-2a:before {
  width: 8px;
  height: 11px;
  background-position: -341px -67px; }

.ppg-icon__crossR-2aLight:after, .ppg-icon__crossR-2aLight:before {
  width: 16px;
  height: 27px;
  background-position: -561px -430px; }

.ppg-icon__crossR-2b:after, .ppg-icon__crossR-2b:before {
  width: 16px;
  height: 22px;
  background-position: -5px -467px; }

.ppg-icon__crossR-2bLight:after, .ppg-icon__crossR-2bLight:before {
  width: 32px;
  height: 54px;
  background-position: -225px -505px; }

.ppg-icon__crossR-2c:after, .ppg-icon__crossR-2c:before {
  width: 24px;
  height: 33px;
  background-position: -267px -505px; }

.ppg-icon__crossR-2cLight:after, .ppg-icon__crossR-2cLight:before {
  width: 48px;
  height: 81px;
  background-position: -590px -5px; }

.ppg-icon__crossR-3a:after, .ppg-icon__crossR-3a:before {
  width: 11px;
  height: 7px;
  background-position: -5px -96px; }

.ppg-icon__crossR-3a-filled:after, .ppg-icon__crossR-3a-filled:before {
  width: 9px;
  height: 6px;
  background-position: -26px -96px; }

.ppg-icon__crossR-3b:after, .ppg-icon__crossR-3b:before {
  width: 22px;
  height: 14px;
  background-position: -571px -96px; }

.ppg-icon__crossR-3b-filled:after, .ppg-icon__crossR-3b-filled:before {
  width: 18px;
  height: 12px;
  background-position: -603px -96px; }

.ppg-icon__crossR-3c:after, .ppg-icon__crossR-3c:before {
  width: 33px;
  height: 21px;
  background-position: -603px -118px; }

.ppg-icon__crossR-3c-filled:after, .ppg-icon__crossR-3c-filled:before {
  width: 27px;
  height: 18px;
  background-position: -590px -149px; }

.ppg-icon__timetable-a:after, .ppg-icon__timetable-a:before {
  width: 19px;
  height: 19px;
  background-position: -65px -177px; }

.ppg-icon__timetable-b:after, .ppg-icon__timetable-b:before {
  width: 38px;
  height: 38px;
  background-position: -590px -177px; }

.ppg-icon__timetable-c:after, .ppg-icon__timetable-c:before {
  width: 57px;
  height: 57px;
  background-position: -570px -225px; }

.ppg-icon__print-a:after, .ppg-icon__print-a:before {
  width: 21px;
  height: 19px;
  background-position: -578px -292px; }

.ppg-icon__print-b:after, .ppg-icon__print-b:before {
  width: 42px;
  height: 38px;
  background-position: -588px -321px; }

.ppg-icon__print-c:after, .ppg-icon__print-c:before {
  width: 63px;
  height: 57px;
  background-position: -561px -467px; }

.ppg-icon__map-a:after, .ppg-icon__map-a:before {
  width: 28px;
  height: 28px;
  background-position: -131px -369px; }

.ppg-icon__map-b:after, .ppg-icon__map-b:before {
  width: 56px;
  height: 56px;
  background-position: -117px -534px; }

.ppg-icon__map-c:after, .ppg-icon__map-c:before {
  width: 84px;
  height: 84px;
  background-position: -301px -505px; }

.ppg-icon__position-a:after, .ppg-icon__position-a:before {
  width: 24px;
  height: 25px;
  background-position: -169px -369px; }

.ppg-icon__position-b:after, .ppg-icon__position-b:before {
  width: 48px;
  height: 50px;
  background-position: -589px -369px; }

.ppg-icon__position-c:after, .ppg-icon__position-c:before {
  width: 72px;
  height: 75px;
  background-position: -395px -534px; }

.ppg-icon__search-r:after, .ppg-icon__search-r:before {
  width: 21px;
  height: 23px;
  background-position: -589px -429px; }

.ppg-icon__search-ra:after, .ppg-icon__search-ra:before {
  width: 21px;
  height: 23px;
  background-position: -418px -462px; }

.ppg-icon__search-rb:after, .ppg-icon__search-rb:before {
  width: 42px;
  height: 46px;
  background-position: -477px -534px; }

.ppg-icon__search-rc:after, .ppg-icon__search-rc:before {
  width: 63px;
  height: 69px;
  background-position: -529px -534px; }

.ppg-icon__search-w:after, .ppg-icon__search-w:before {
  width: 21px;
  height: 23px;
  background-position: -42px -534px; }

.ppg-icon__search-wa:after, .ppg-icon__search-wa:before {
  width: 21px;
  height: 23px;
  background-position: -183px -534px; }

.ppg-icon__search-wb:after, .ppg-icon__search-wb:before {
  width: 42px;
  height: 46px;
  background-position: -5px -613px; }

.ppg-icon__search-wc:after, .ppg-icon__search-wc:before {
  width: 63px;
  height: 69px;
  background-position: -648px -5px; }

.ppg-icon__tlf-a:after, .ppg-icon__tlf-a:before {
  width: 22px;
  height: 23px;
  background-position: -648px -84px; }

.ppg-icon__tlf-b:after, .ppg-icon__tlf-b:before {
  width: 44px;
  height: 46px;
  background-position: -646px -117px; }

.ppg-icon__tlf-c:after, .ppg-icon__tlf-c:before {
  width: 66px;
  height: 69px;
  background-position: -638px -173px; }

.ppg-icon__tlfGrey-a:after, .ppg-icon__tlfGrey-a:before {
  width: 22px;
  height: 22px;
  background-position: -680px -84px; }

.ppg-icon__tlfGrey-b:after, .ppg-icon__tlfGrey-b:before {
  width: 44px;
  height: 44px;
  background-position: -637px -252px; }

.ppg-icon__tlfGrey-c:after, .ppg-icon__tlfGrey-c:before {
  width: 66px;
  height: 66px;
  background-position: -634px -429px; }

.ppg-icon__tree-a:after, .ppg-icon__tree-a:before {
  width: 28px;
  height: 20px;
  background-position: -640px -306px; }

.ppg-icon__tree-b:after, .ppg-icon__tree-b:before {
  width: 56px;
  height: 40px;
  background-position: -647px -336px; }

.ppg-icon__tree-c:after, .ppg-icon__tree-c:before {
  width: 84px;
  height: 60px;
  background-position: -602px -534px; }

.ppg-icon--before:after {
  content: none; }

.ppg-icon--before:before {
  content: '';
  background-image: url("../assets/img/icons/spritesheet.png");
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.3125rem; }

.ppg-icon--rotate:after, .ppg-icon--rotate:before {
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

.ppg-icon__help {
  padding-left: 4.0625rem;
  min-height: 3.1875rem;
  padding-right: 2.5rem;
  position: relative; }
  .ppg-icon__help:before {
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    margin-right: 0.8125rem;
    width: 3.25rem;
    height: 3.1875rem;
    border-radius: 50%;
    vertical-align: middle;
    content: url("../assets/img/contents/support.png"); }
  .ppg-icon__help:after {
    display: none; }
  .ppg-icon__help--text {
    display: inline-block; }
    .ppg-icon__help--text:before, .ppg-icon__help--text:after {
      display: none; }

.ppg-icon__job:before {
  display: inline-block;
  margin-right: 0.8125rem;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  vertical-align: middle;
  content: url("../assets/img/contents/pae-app.png"); }

/*
	Modificador para los iconos en el menu principal
*/
.ppg-menu .ppg-menu__link .ppg-icon__crossW-3a {
  display: table-cell;
  vertical-align: middle; }

/*----------------------------------------------
   # módulo
------------------------------------------------*/
.ppg-info {
  display: block;
  background-color: #f4f4f4;
  border: 1px solid #dddddd;
  padding: 1.5625rem 1.5625rem 0 1.5625rem; }
  .ppg-info__fieldset {
    position: relative; }
  .ppg-info__legend {
    width: 100%;
    padding-bottom: 1.5625rem;
    padding-top: 0.4375rem;
    display: block;
    font-size: 1.5rem;
    color: #333333; }
  .ppg-info__radio {
    position: absolute;
    top: 0;
    right: 0; }
  .ppg-info__lbl-radio {
    display: inline-block;
    width: 5.625rem;
    text-align: center;
    border: 1px solid #dddddd;
    background-color: white;
    color: #c33400;
    padding: 0.75rem 1.25rem;
    font-size: 0.875rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer; }
    .ppg-info__lbl-radio:hover, .ppg-info__lbl-radio:focus {
      background-color: #c33400;
      color: white; }
  .ppg-info__lbl-textarea {
    font-size: 1rem;
    color: #333333;
    display: block; }
  .ppg-info__textarea {
    display: block;
    border: 1px solid #dddddd;
    border-radius: 5px;
    width: 100%;
    min-height: 9.375rem;
    margin-bottom: 1.25rem;
    padding: 0.625rem;
    color: #999999; }
  .ppg-info__submit {
    padding-right: 3.125rem;
    padding-left: 3.125rem;
    width: auto;
    float: right; }
  .ppg-info__contet-text-area {
    padding-bottom: 1.5625rem; }

@media only screen and (max-width: 1023px) {
  .ppg-info__radio {
    position: inherit;
    margin-bottom: 1.25rem; }
  .ppg-info__legend {
    padding-bottom: 0.3125rem; } }

@media only screen and (max-width: 767px) {
  .ppg-info__legend {
    font-size: 1.25rem; } }

/*----------------------------------------------
   # LINK: estilización de los enlaces del portal
			Entenemods por enlace todos aquellos que su
			comportamiento afecta a la URL de la página.
			También, incluimos enlacs tpo "ancla".
------------------------------------------------*/
.ppg-link {
  display: inline-block;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.875rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #c33400;
  text-decoration: none;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  position: relative;
  box-sizing: border-box;
  border: 1px solid transparent; }
  .ppg-link:focus {
    box-shadow: 0 0 5px 0 #c33400;
    border: solid 1px #bc3302;
    text-decoration: none;
    border-radius: 1px;
    outline: 0; }
  .ppg-link:active {
    color: #333333;
    text-decoration: underline; }
  .ppg-link__apps {
    display: inline-block;
    width: 49%; }
    .ppg-link__apps img {
      width: 100%; }
  .ppg-link--bold {
    font-weight: bold; }
  .ppg-link--white {
    color: white; }
  .ppg-link--black {
    color: #333333; }
    .ppg-link--black:focus {
      text-decoration: underline; }
    .ppg-link--black:active {
      color: #c33400; }
  .ppg-link--red {
    color: #c33400; }
  .ppg-link--border:after {
    content: "";
    height: 0.9375rem;
    border: 0.5px solid #c9c6c6;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%); }
  .ppg-link--big {
    font-size: 1.25rem;
    color: #333333;
    font-weight: bold; }
  .ppg-link--btn {
    border-radius: 0.1875rem;
    background-color: #c33400;
    border: 1px solid transparent;
    padding: 0.75rem 1.25rem;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    font-size: 0.875rem;
    cursor: pointer;
    text-align: center; }
    .ppg-link--btn:hover {
      text-decoration: none;
      background-color: #9e2c02; }
    .ppg-link--btn:focus {
      background-color: #c33400;
      border: 1px solid white;
      box-shadow: 0 0 0 2px #c33400;
      text-decoration: none;
      outline: none; }
  .ppg-link--btn2 {
    border-radius: 3px;
    border: 1px solid #bbbbbb;
    padding: 0.625rem;
    font-size: 0.875rem;
    line-height: 1.29;
    color: #c33400;
    display: inline-block; }
    .ppg-link--btn2:hover {
      text-decoration: none;
      background-color: #fff4f0; }
    .ppg-link--btn2:focus {
      box-shadow: 0 0 5px 0 #c33400;
      border: 1px solid #c33400;
      text-decoration: none;
      outline: none; }
  .ppg-link--btn-disable {
    border: 1px solid #e0ded9;
    color: #999999; }
    .ppg-link--btn-disable:hover {
      background-color: transparent;
      cursor: default; }
    .ppg-link--btn-disable:focus {
      box-shadow: none;
      border: 1px solid #e0ded9; }
  .ppg-link--btn-active {
    background-color: #c33400;
    color: white;
    border: 1px solid #e0ded9; }
    .ppg-link--btn-active:hover {
      text-decoration: none;
      background-color: #c33400;
      color: white;
      cursor: default; }
  .ppg-link--btn3:hover {
    background-color: #fff4f0;
    text-decoration: none; }
  .ppg-link--seeds {
    margin-bottom: 0.375rem; }
    .ppg-link--seeds:before {
      transform: rotate(180deg);
      vertical-align: initial; }
  .ppg-link--btn-margin {
    margin-top: 0.75rem; }
  .ppg-link--btn-padding {
    padding: 0.75rem 0.875rem; }
  .ppg-link--pills {
    position: relative;
    display: block;
    border: 1px solid #dddddd;
    margin-bottom: 1.25rem;
    padding: 0.625rem;
    color: #333333; }
    .ppg-link--pills:hover, .ppg-link--pills:focus {
      box-shadow: 0 3px 4px 0 rgba(68, 68, 68, 0.63);
      border-color: #c33400; }
      .ppg-link--pills:hover:before, .ppg-link--pills:focus:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border-left: 4px solid #c33400; }

.ppg-slick--type3 .ppg-link--btn2 {
  width: 2.5rem;
  height: 2.5rem;
  text-align: center; }

.ppg-header .ppg-header__item--button .ppg-link--btn {
  color: white;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px; }

.ppg-news .o-list__item:hover .ppg-link {
  text-decoration: underline; }

.o-list--inline .ppg-link {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: #333333; }
  .o-list--inline .ppg-link.ppg-link--rrss {
    display: block;
    padding: 0.3125rem 0 0.3125rem 0.625rem; }
    .o-list--inline .ppg-link.ppg-link--rrss:hover, .o-list--inline .ppg-link.ppg-link--rrss:focus {
      background-color: #fff4f0; }

.o-list--header .ppg-link {
  color: #333333; }
  .o-list--header .ppg-link.ppg-link--header {
    display: block;
    padding: 0.625rem 1.5rem; }
    .o-list--header .ppg-link.ppg-link--header:hover, .o-list--header .ppg-link.ppg-link--header:focus {
      background-color: #fff4f0;
      text-decoration: none; }
    .o-list--header .ppg-link.ppg-link--header:after {
      content: url("../assets/img/icons/files/icon-flecha-negra--3a-filled.png");
      display: inline-block;
      margin-left: 0.625rem; }

.ppg-table .ppg-link {
  color: #c33400;
  display: table-cell;
  text-align: right; }
  .ppg-table .ppg-link--type02 {
    padding: 0;
    text-align: left; }

.ppg-footer--list .ppg-link {
  font-size: 0.75rem;
  color: #333333; }

.ppg-footer__link:first-child .ppg-link {
  padding-left: 0; }

.ppg-footer.ppg-footer__link {
  border-top: 1px solid #c9c6c6; }
  .ppg-footer.ppg-footer__link:last-child {
    text-align: left; }

.ppg-footer .o-list.o-list--inline {
  border-top: 1px solid #c9c6c6;
  border-bottom: 1px solid #c9c6c6; }

.o-list--type01 .ppg-link {
  width: 100%;
  padding: 0.625rem 1.25rem;
  position: relative;
  height: 2.5rem; }
  .o-list--type01 .ppg-link--red {
    color: #c33400; }
  .o-list--type01 .ppg-link:hover {
    background: #fff4f0; }
    .o-list--type01 .ppg-link:hover:before {
      content: "";
      width: 0.3125rem;
      height: 2.5rem;
      position: absolute;
      left: 0;
      top: 0;
      background-color: #c33400; }

@media only screen and (max-width: 1023px) {
  .o-list--inline .ppg-link {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  .ppg-table .ppg-link--type02 {
    padding: 0; }
  .ppg-link--seeds {
    margin-top: 0.5625rem; } }

@media only screen and (max-width: 767px) {
  .ppg-link__apps {
    width: 8.125rem; }
  .ppg-link--border {
    width: 100%; }
    .ppg-link--border:after {
      display: none; }
  .ppg-link--seeds {
    margin-top: 0.3125rem; }
  .ppg-link--btn-padding {
    padding: 0.75rem 0.5rem; }
  .ppg-footer__link.ppg-footer__link:last-child {
    text-align: left; }
  .ppg-footer__link:first-child {
    padding-left: 0.75rem; }
  .ppg-footer .o-list.o-list--inline {
    border: 0 none; }
    .ppg-footer .o-list.o-list--inline .o-list__item {
      border-top: 1px solid #c9c6c6; }
      .ppg-footer .o-list.o-list--inline .o-list__item:last-child {
        padding-top: 0.9375rem;
        padding-bottom: 0;
        text-align: left; }
  .ppg-footer .o-unit {
    padding-left: 0;
    padding-right: 0; }
  .ppg-footer .ppg-text--lighted-3 {
    width: 100%;
    text-align: center;
    display: block;
    padding-top: 0.75rem; } }

.ppg-menu {
  background-color: #c33400; }
  .ppg-menu__it {
    float: left;
    border-right: 1px solid rgba(255, 255, 255, 0.28); }
    .ppg-menu__it:first-child {
      border-left: 1px solid rgba(255, 255, 255, 0.28); }
  .ppg-menu__link {
    width: 100%;
    height: 100%;
    display: table;
    color: white;
    font-size: 0.875rem;
    font-weight: bold;
    padding: 1rem 1.125rem 1rem 1.125rem;
    border: 2px solid transparent;
    text-align: left;
    vertical-align: middle;
    margin-top: 0.3125rem; }
    .ppg-menu__link .ppg-menu__text-link {
      display: table-cell;
      vertical-align: middle; }
    .ppg-menu__link:hover {
      text-decoration: none;
      background-color: #9e2c02; }
    .ppg-menu__link:focus {
      text-decoration: none;
      outline: none;
      border-radius: 5px;
      border: 2px solid white;
      box-shadow: 0px 0px 5px 3px #9e2c02; }
    .ppg-menu__link.active {
      color: #9e2c02;
      background-color: white; }
      .ppg-menu__link.active .ppg-icon__crossW-3a:after {
        width: 0.6875rem;
        height: 0.4375rem;
        background-position: -5px -96px; }
  .ppg-menu__content {
    padding: 1.875rem;
    position: absolute;
    background-color: white;
    z-index: 2;
    border-right: 1px solid #dddddd;
    border-left: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd; }
    .ppg-menu__content .ppg-text {
      margin-top: 0.625rem; }
  .ppg-menu__it-2l {
    margin-bottom: 1.5625rem; }
  .ppg-menu__link-2l {
    display: block;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.875rem;
    color: #333333;
    padding: 0.9375rem 0.625rem 0.625rem; }
    .ppg-menu__link-2l:hover, .ppg-menu__link-2l:focus {
      text-decoration: none;
      background-color: #fff4f0; }
  .ppg-menu__sticky {
    display: none; }
  .ppg-menu .ppg-menu__secondary {
    display: none; }

.menu-fixed {
  position: fixed;
  z-index: 1000;
  top: 0;
  max-width: 62.5rem;
  width: 100%;
  box-shadow: 0 4px 3px rgba(0, 0, 0, 0.2); }

@media only screen and (max-width: 1023px) {
  .ppg-menu__link {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem; }
  .ppg-menu__sticky {
    display: none; } }

@media only screen and (max-width: 767px) {
  .ppg-menu {
    display: none;
    background-color: white;
    border-top: 5px solid #c33400; }
    .ppg-menu__it {
      float: none;
      border-right: 0;
      margin-right: -0.9375rem;
      margin-left: -0.9375rem; }
    .ppg-menu .ppg-menu__link {
      display: block;
      color: #9e2c02;
      padding: 0.9375rem 1rem;
      border: 0;
      border-bottom: 1px solid #dddddd;
      text-align: left;
      margin-top: 0; }
      .ppg-menu .ppg-menu__link .ppg-menu__text-link {
        display: inline-block;
        width: 94%; }
      .ppg-menu .ppg-menu__link:hover {
        text-decoration: none;
        background-color: white; }
      .ppg-menu .ppg-menu__link:focus {
        border-radius: 0;
        border: 0;
        border-bottom: 1px solid #dddddd;
        box-shadow: none; }
      .ppg-menu .ppg-menu__link .ppg-icon__crossW-3a {
        display: inline-block;
        width: 5%; }
        .ppg-menu .ppg-menu__link .ppg-icon__crossW-3a:after {
          width: 0.6875rem;
          height: 0.4375rem;
          background-position: -5px -96px; }
      .ppg-menu .ppg-menu__link.active {
        color: #9e2c02;
        background-color: white; }
        .ppg-menu .ppg-menu__link.active .ppg-icon__crossW-3a:after {
          transform: rotate(180deg); }
    .ppg-menu__content {
      display: none;
      padding: 0;
      position: inherit;
      border: 0; }
    .ppg-menu__list-2l {
      background-color: #f4f4f4; }
    .ppg-menu__it-2l {
      margin-bottom: 0;
      padding-right: 1rem;
      padding-left: 1rem; }
    .ppg-menu__link-2l {
      display: block;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 0.875rem;
      color: #333333;
      padding: 0.75rem 0.625rem;
      border-bottom: 1px solid #dddddd; }
      .ppg-menu__link-2l:before {
        content: none; }
    .ppg-menu__sticky {
      display: block;
      background-color: #f8f8f8; }
      .ppg-menu__sticky:after, .ppg-menu__sticky:before {
        content: '';
        clear: both;
        display: block; }
      .ppg-menu__sticky .ppg-menu__stk-item {
        float: left;
        width: 40%; }
        .ppg-menu__sticky .ppg-menu__stk-item:first-child, .ppg-menu__sticky .ppg-menu__stk-item:last-child {
          width: 30%; }
        .ppg-menu__sticky .ppg-menu__stk-item:last-child .ppg-menu__stk-link:after {
          border-right: 0; }
      .ppg-menu__sticky .ppg-menu__stk-link {
        position: relative;
        padding-top: 0.9375rem;
        padding-bottom: 0.9375rem;
        font-size: 0.875rem;
        display: block;
        color: #333333;
        text-align: center; }
        .ppg-menu__sticky .ppg-menu__stk-link:after {
          position: absolute;
          right: 0;
          top: 50%;
          content: '';
          width: 0.125rem;
          height: 40%;
          border-right: 1px solid #dddddd;
          transform: translate(0, -50%); }
        .ppg-menu__sticky .ppg-menu__stk-link.stk--menu:before {
          background: url("../assets/img/icons/files/icon-menu-mobile.png") no-repeat center center;
          display: inline-block;
          vertical-align: middle;
          margin-right: 0.3125rem;
          content: '';
          width: 1.5625rem;
          height: 1.5625rem; }
        .ppg-menu__sticky .ppg-menu__stk-link.stk--folder:before {
          background: url(../assets/img/icons/spritesheet.png) no-repeat -520px -230px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 0.3125rem;
          content: '';
          width: 2.5rem;
          height: 1.5625rem; }
        .ppg-menu__sticky .ppg-menu__stk-link.stk--help:before {
          display: inline-block;
          vertical-align: middle;
          margin-right: 0.3125rem;
          content: '';
          background: url(../assets/img/icons/spritesheet.png) no-repeat -531px -5px;
          width: 1.5625rem;
          height: 1.5625rem; }
        .ppg-menu__sticky .ppg-menu__stk-link.active {
          background-color: #c33400;
          color: white;
          text-decoration: none; }
          .ppg-menu__sticky .ppg-menu__stk-link.active.stk--menu:before {
            background: url("../assets/img/icons/files/aspa.png") no-repeat center center; }
    .ppg-menu .ppg-menu__secondary {
      display: block;
      background-color: #f4f4f4; }
      .ppg-menu .ppg-menu__secondary .ppg-menu__it {
        padding-left: 1.25rem; }
      .ppg-menu .ppg-menu__secondary .ppg-form__group .ppg-form__label {
        display: inline-block;
        padding: 0;
        vertical-align: super; }
      .ppg-menu .ppg-menu__secondary .ppg-form__group .ui-selectmenu-button {
        display: inline-block; }
        .ppg-menu .ppg-menu__secondary .ppg-form__group .ui-selectmenu-button .ui-selectmenu-text {
          border: 0;
          padding-bottom: 0;
          background-color: transparent; } }

/*----------------------------------------------
   # módulo
------------------------------------------------*/
.ppg-micarpeta {
  display: none; }

@media only screen and (max-width: 1023px) {
  .ppg-micarpeta {
    display: block;
    height: 7.5rem;
    position: relative; }
    .ppg-micarpeta .ppg-link {
      position: absolute;
      top: 1.25rem;
      right: 8.75rem; } }

@media only screen and (max-width: 767px) {
  .ppg-micarpeta {
    height: 11.5625rem; }
    .ppg-micarpeta .ppg-link {
      position: relative;
      right: 0;
      top: 0; } }

/*----------------------------------------------
   # módulo
------------------------------------------------*/
/*----------------------------------------------
   # PPG-PAGINATION
------------------------------------------------*/
.ppg-pagination {
  padding-bottom: 2.9375rem; }
  .ppg-pagination__btn--left {
    float: left;
    text-align: left; }
  .ppg-pagination__btn--right {
    float: right;
    text-align: right; }
  .ppg-pagination__btn--middle {
    text-align: center; }

@media only screen and (max-width: 767px) {
  .ppg-pagination {
    text-align: center;
    margin: 0 auto; }
    .ppg-pagination__btn--middle {
      margin: 0 auto;
      width: auto; }
    .ppg-pagination__btn--left, .ppg-pagination__btn--right {
      width: auto; } }

/*----------------------------------------------
   # PILLS
------------------------------------------------*/
.ppg-pills {
  width: 100%;
  border: 1px solid #dddddd;
  margin-bottom: 1.25rem; }
  .ppg-pills__img {
    width: 4.375rem;
    padding: 0; }
  .ppg-pills__text {
    text-align: left; }
  .ppg-pills:hover, .ppg-pills:focus {
    outline: none;
    cursor: pointer;
    border: 1px solid #c33400;
    box-shadow: 0 3px 4px 0 rgba(68, 68, 68, 0.38); }
  .ppg-pills:active {
    outline: none; }
  .ppg-pills.active {
    border: 1px solid #c33400; }
  .ppg-pills__list {
    margin-left: 3.75rem; }
  .ppg-pills__link {
    text-align: left;
    display: block;
    padding: 0.625rem 0; }
  .ppg-pills__mrg-txt {
    margin-bottom: 0.625rem; }
  .ppg-pills__mrg-blk {
    margin-bottom: 2.5rem; }
  .ppg-pills__mrg-bttm {
    margin-bottom: 3.75rem; }
  .ppg-pills__type02 {
    padding: 0.9375rem;
    border: 1px solid #dddddd;
    margin-bottom: 1.875rem; }
    .ppg-pills__type02:hover {
      cursor: pointer;
      border: 1px solid #c33400;
      box-shadow: 0 3px 4px 0 rgba(68, 68, 68, 0.38); }
    .ppg-pills__type02.active {
      border: 1px solid #c33400;
      border-left-width: 5px; }

/*----------------------------------------------
   # módulo
------------------------------------------------*/
.ppg-rrss {
  z-index: 15;
  display: none;
  width: 14.375rem;
  position: absolute;
  top: 3.125rem;
  left: 0.625rem;
  border: 1px solid #dddddd;
  background-color: white;
  border-radius: 5px; }
  .ppg-rrss:before {
    content: url("../assets/img/icons/files/icon-arrow-down-grey-up.png");
    display: inline-block;
    position: absolute;
    top: -1.0625rem;
    left: 1.25rem; }
  .ppg-rrss__item {
    border-bottom: 1px solid #dddddd; }
    .ppg-rrss__item:last-child {
      border-bottom: 0; }
    .ppg-rrss__item .ppg-link.ppg-link--rrss {
      padding: 0.9375rem; }
      .ppg-rrss__item .ppg-link.ppg-link--rrss:hover {
        text-decoration: none; }
  .ppg-rrss.ppg-rrss--header {
    width: 9.375rem;
    left: 0; }

.ppg-menu__secondary .o-list--header .o-list__box {
  float: inherit; }
  .ppg-menu__secondary .o-list--header .o-list__box .o-list__item {
    float: inherit; }

.ppg-menu__secondary .o-list--header .ppg-link.ppg-link--header {
  padding-left: 0; }

/*----------------------------------------------
   # SEARCH: input search
------------------------------------------------*/
.ppg-search {
  position: relative; }
  .ppg-search__input {
    width: 100%;
    padding: 0.8125rem 1.875rem 0.8125rem 0.9375rem;
    border-radius: 3px;
    border: 1px solid #dbdbdb;
    position: relative; }
    .ppg-search__input:focus {
      border: 1px solid #444444;
      outline: none; }
  .ppg-search__button {
    position: absolute;
    top: 0;
    right: 0;
    width: 2.8125rem;
    height: 2.8125rem; }
  .ppg-search--splitted {
    padding: 0.9375rem;
    border: 1px solid #dddddd;
    background-color: #f4f4f4;
    margin-top: 0.9375rem;
    margin-bottom: 1.875rem; }
    .ppg-search--splitted .ppg-search__input {
      display: inline-block;
      width: calc(100% - 140px);
      margin-right: 0.625rem;
      vertical-align: top; }
    .ppg-search--splitted .ppg-search__button {
      position: relative;
      display: inline-block;
      vertical-align: top;
      width: 7.5rem; }

.ppg-main__search {
  height: 20.3125rem;
  background-image: url("../assets/img/contents/demo.jpg");
  position: relative;
  background-size: cover; }
  .ppg-main__search .ppg-search__input {
    padding-right: 3.125rem; }

.ppg-main__overlay {
  background-color: rgba(51, 51, 51, 0.82);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.ppg-main__search-home {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 3.125rem; }

.ppg-main__box {
  position: relative;
  width: 50rem;
  margin: auto; }

@media only screen and (max-width: 1279px) {
  .ppg-main__search-home {
    width: 100%; }
  .ppg-main__search {
    height: 15.425rem; }
  .ppg-main__box {
    width: 74%;
    margin: 0 auto; } }

@media only screen and (max-width: 1023px) {
  .ppg-search__input {
    width: 100%; }
  .ppg-search--splitted {
    margin-left: 0.9375rem;
    margin-right: 0.9375rem; } }

@media only screen and (max-width: 767px) {
  .ppg-main__search {
    height: 13.5rem; }
  .ppg-main__box {
    width: 90%; }
  .ppg-search--splitted .ppg-search__input {
    display: block;
    width: 100%;
    margin-bottom: 0.625rem;
    margin-right: 0; }
  .ppg-search--splitted .ppg-search__button {
    display: block;
    width: 100%; } }

/*----------------------------------------------
   # módulo select: se sobreescriben los estilos del select custom de jquery ui
------------------------------------------------*/
.ui-selectmenu-text {
  background: white;
  padding: 0.46875rem;
  border-radius: 3px;
  border: 1px solid #bbbbbb;
  box-sizing: border-box;
  cursor: pointer;
  height: 2.5rem; }

.ui-selectmenu-menu .ui-menu {
  width: 13.75rem;
  border-radius: 3px;
  border: 1px solid #dddddd;
  background: white;
  box-shadow: 0 2px 4px 0 #9b9b9b;
  max-height: 11.5625rem; }

.ui-selectmenu-menu .ui-menu-item {
  padding: 0.5rem;
  border-bottom: 1px solid #dddddd;
  cursor: pointer; }
  .ui-selectmenu-menu .ui-menu-item:hover {
    background: #fff4f0; }
  .ui-selectmenu-menu .ui-menu-item:last-child {
    border: none; }

.ui-selectmenu-menu .ui-menu-item-wrapper.ui-state-active {
  font-weight: bold; }

.ui-selectmenu-button.ui-button {
  width: 100%;
  display: block;
  position: relative; }
  .ui-selectmenu-button.ui-button:focus {
    outline: none; }
    .ui-selectmenu-button.ui-button:focus .ui-selectmenu-text {
      box-shadow: 0 0 5px 0 #c33400;
      border: solid 1px #c33400; }

.ppg-header .ui-selectmenu-text {
  border: 1px solid transparent; }
  .ppg-header .ui-selectmenu-text:hover {
    background: #fff4f0; }

.ppg-select--form .ui-selectmenu-text {
  margin-right: 0;
  position: relative;
  display: block; }
  .ppg-select--form .ui-selectmenu-text:after {
    content: "";
    background-image: url("../assets/img/icons/spritesheet.png");
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.3125rem;
    width: 9px;
    height: 7px;
    background-position: -41px -67px;
    position: absolute;
    top: 50%;
    right: 0.625rem;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%); }

@media only screen and (max-width: 1023px) {
  .ui-selectmenu-button.ui-button {
    display: block; } }

@media only screen and (max-width: 767px) {
  .ui-selectmenu-button.ui-button {
    display: block; } }

/*----------------------------------------------
   # SLICK: estilización del carrusel
------------------------------------------------*/
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.ppg-slick {
  width: 100%; }
  .ppg-slick--type1 .ppg-slick__item {
    height: 23.4375rem;
    display: inline-block;
    margin-right: 1.21875rem; }
  .ppg-slick--type1 .ppg-slick__link:hover, .ppg-slick--type1 .ppg-slick__link:focus {
    text-decoration: none; }
  .ppg-slick--type1 .slick-prev, .ppg-slick--type1 .slick-next {
    width: 40px;
    height: 60px;
    z-index: 1; }
    .ppg-slick--type1 .slick-prev:before, .ppg-slick--type1 .slick-next:before {
      display: inline-block;
      padding: 1rem 0.75rem;
      background-color: #c33400;
      content: url("../assets/img/icons/files/icon-flecha-blanca--2b-light.png");
      border: 1px solid transparent; }
    .ppg-slick--type1 .slick-prev.slick-disabled:before, .ppg-slick--type1 .slick-next.slick-disabled:before {
      background-color: #c9c6c6; }
    .ppg-slick--type1 .slick-prev.slick-disabled:focus:before, .ppg-slick--type1 .slick-next.slick-disabled:focus:before {
      box-shadow: none;
      border: none; }
    .ppg-slick--type1 .slick-prev:focus:before, .ppg-slick--type1 .slick-next:focus:before {
      box-shadow: 0 0 5px 0 #c33400;
      border: solid 1px #bc3302; }
  .ppg-slick--type1 .slick-prev {
    left: -1.875rem; }
    .ppg-slick--type1 .slick-prev:before {
      content: url("../assets/img/icons/files/icon-flecha-blanca--2b-lightb.png"); }
  .ppg-slick--type1 .slick-next {
    right: -1.875rem; }
  .ppg-slick--type1 .slick-disabled:hover:before {
    box-shadow: none; }
  .ppg-slick--type2 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
    background-color: #e9e9e9; }
    .ppg-slick--type2 .ppg-slick__box {
      width: 53.125rem;
      margin: auto; }
    .ppg-slick--type2 .ppg-slick__item {
      margin-right: 1.5625rem;
      margin-left: 0.3125rem; }
    .ppg-slick--type2 .ppg-slick__link:hover, .ppg-slick--type2 .ppg-slick__link:focus {
      text-decoration: none; }
    .ppg-slick--type2 .slick-prev, .ppg-slick--type2 .slick-next {
      z-index: 1; }
      .ppg-slick--type2 .slick-prev:before, .ppg-slick--type2 .slick-next:before {
        display: inline-block;
        content: url("../assets/img/icons/files/icon-flecha-roja--2a-light.png");
        border: 1px solid transparent; }
      .ppg-slick--type2 .slick-prev.slick-disabled:before, .ppg-slick--type2 .slick-next.slick-disabled:before {
        background-color: #c9c6c6; }
      .ppg-slick--type2 .slick-prev.slick-disabled:focus:before, .ppg-slick--type2 .slick-next.slick-disabled:focus:before {
        box-shadow: none;
        border: none; }
    .ppg-slick--type2 .slick-prev {
      left: -1.875rem; }
      .ppg-slick--type2 .slick-prev:before {
        transform: rotate(180deg); }
    .ppg-slick--type2 .slick-next {
      right: -1.875rem; }
    .ppg-slick--type2 .slick-disabled:hover:before {
      box-shadow: none; }
  .ppg-slick--type3 {
    padding: 1.25rem 1.25rem;
    border-bottom: 1px solid #dddddd;
    border-top: 1px solid #dddddd; }
    .ppg-slick--type3 .slick-prev, .ppg-slick--type3 .slick-next {
      z-index: 1; }
      .ppg-slick--type3 .slick-prev:before, .ppg-slick--type3 .slick-next:before {
        display: inline-block;
        content: url("../assets/img/icons/files/icon-flecha-roja--2a.png");
        border: 1px solid transparent; }
      .ppg-slick--type3 .slick-prev.slick-disabled:focus:before, .ppg-slick--type3 .slick-next.slick-disabled:focus:before {
        box-shadow: none;
        border: none; }
    .ppg-slick--type3 .slick-prev {
      left: -1.5625rem; }
      .ppg-slick--type3 .slick-prev:before {
        content: url("../assets/img/icons/files/icon-flecha-roja--2ab.png"); }
    .ppg-slick--type3 .slick-next {
      right: -1.5625rem; }
    .ppg-slick--type3 .slick-disabled:hover:before {
      box-shadow: none; }

.o-layout--dynamic .o-card--slick {
  width: 19.0625rem; }

.o-layout--dynamic .ppg-slick--type1 .ppg-slick__item {
  float: left;
  margin-right: 2.0625rem; }
  .o-layout--dynamic .ppg-slick--type1 .ppg-slick__item:last-child {
    margin-right: 0; }

@media only screen and (max-width: 1279px) {
  .ppg-slick--type1 .slick-next {
    right: -0.5rem; }
  .ppg-slick--type1 .slick-prev {
    left: -1.125rem; }
  .ppg-slick--type1 .ppg-slick__item {
    height: auto; }
  .o-layout--dynamic .ppg-slick--type1 .ppg-slick__item {
    margin-right: 2.0625rem; } }

@media only screen and (max-width: 1023px) {
  .ppg-slick--type2 {
    padding-top: 2.1875rem;
    padding-bottom: 2.1875rem; }
    .ppg-slick--type2 .ppg-slick__box {
      width: 43.75rem;
      margin: auto; }
  .o-layout--dynamic .ppg-slick {
    width: auto;
    margin: auto;
    display: inline-block;
    text-align: left; }
    .o-layout--dynamic .ppg-slick--type1 .ppg-slick__item {
      margin-right: 2.0625rem; } }

@media only screen and (max-width: 767px) {
  .ppg-slick--type2 .ppg-slick__box {
    width: 100%; }
  .o-layout--dynamic .ppg-slick--type1 .ppg-slick__item {
    margin: 0 auto;
    display: block;
    float: none; } }

/*----------------------------------------------
   # STEPS
------------------------------------------------*/
.ppg-step__one {
  padding-top: 1.25rem; }

.ppg-step__childs {
  display: none;
  position: relative;
  padding-top: 1.25rem; }
  .ppg-step__childs:before {
    content: '';
    position: absolute;
    left: 0;
    top: -1.25rem;
    height: 15px;
    width: 100%;
    border-bottom: 1px solid #dddddd;
    background-color: white;
    z-index: 5; }
  .ppg-step__childs:after {
    position: absolute;
    top: -0.5625rem;
    left: 50%;
    transform: translate(-50%, 0);
    content: url("../assets/img/icons/files/icon-arrow-down-grey.png");
    z-index: 10; }
  .ppg-step__childs.active {
    display: block; }

/*----------------------------------------------
   # PPG-TABLE
------------------------------------------------*/
.ppg-table {
  margin-bottom: 0.9375rem; }
  .ppg-table__head {
    display: block;
    padding-top: 0.875rem;
    padding-bottom: 0.625rem; }
  .ppg-table__body {
    border-top: 1px solid #e0ded9; }
  .ppg-table__col {
    vertical-align: middle; }
  .ppg-table__row {
    display: block; }
  .ppg-table__row > td {
    padding-top: 0.6875rem;
    padding-bottom: 1.375rem; }
  .ppg-table__text {
    width: 50%; }
    .ppg-table__text--type02 {
      width: 25%;
      text-align: center; }
  .ppg-table__box {
    border-bottom: 1px solid #e0ded9;
    border-top: 1px solid #e0ded9;
    padding: 0rem 0.875rem; }
    .ppg-table__box--type02 {
      border-bottom: 1px solid #c1c1c1;
      padding: 0rem 0.875rem; }
  .ppg-table__list--type02 {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem; }
    .ppg-table__list--type02:nth-child(odd) {
      background-color: #f8f8f8; }
    .ppg-table__list--type02 [class*=" o-it"],
    .ppg-table__list--type02 [class^="o-it"] {
      margin-bottom: 0.625rem; }
    .ppg-table__list--type02:hover {
      background-color: #fff4f0;
      cursor: pointer; }
  .ppg-table__title {
    padding-left: 0.625rem;
    padding-right: 0.75rem;
    color: #c9c6c6; }
  .ppg-table__content--type02 {
    background-color: #f9f9f9;
    border: 1px solid #dddddd;
    padding: 1.3125rem 0 0.75rem 0.3125rem; }

@media only screen and (max-width: 1023px) {
  .ppg-table__content {
    display: block; } }

@media only screen and (max-width: 767px) {
  .ppg-table__head {
    cursor: pointer; }
  .ppg-table .ppg-table__row {
    padding-bottom: 0; }
    .ppg-table .ppg-table__row--type02 {
      padding: 0; }
  .ppg-table__text, .ppg-table__text--type02 {
    width: 100%;
    display: block;
    padding-bottom: 0.6875rem;
    text-align: left; }
  .ppg-table__content {
    display: none; }
    .ppg-table__content.open {
      display: block; }
  .ppg-table--type02 .ppg-table__list--type02 {
    padding-bottom: 0; }
    .ppg-table--type02 .ppg-table__list--type02 .ppg-heading--type03 {
      margin-bottom: 0;
      border: 0; }
    .ppg-table--type02 .ppg-table__list--type02 [class*=" o-it"],
    .ppg-table--type02 .ppg-table__list--type02 [class^="o-it"] {
      margin-bottom: 0; }
    .ppg-table--type02 .ppg-table__list--type02.open {
      padding-bottom: 0.9375rem; }
      .ppg-table--type02 .ppg-table__list--type02.open .ppg-heading--type03 {
        margin-bottom: 0.9375rem;
        border-bottom: 1px solid #dddddd; }
      .ppg-table--type02 .ppg-table__list--type02.open [class*=" o-it"],
      .ppg-table--type02 .ppg-table__list--type02.open [class^="o-it"] {
        margin-bottom: 0.625rem; } }

/*----------------------------------------------
   # PPG__TABS
------------------------------------------------*/
.ppg-tabs {
  padding: 0;
  margin: 0;
  border: 1px solid #c9c6c6;
  border-radius: 0;
  margin-bottom: 1.25rem; }
  .ppg-tabs .ppg-tabs__list {
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 0; }
    .ppg-tabs .ppg-tabs__list .ppg-tabs__item {
      margin: 0;
      padding: 0;
      border: 0;
      border-radius: 0;
      width: 50%;
      background-color: transparent; }
      .ppg-tabs .ppg-tabs__list .ppg-tabs__item.ui-tabs-active .ppg-tabs__link {
        position: relative;
        background-color: #c33400;
        color: white; }
        .ppg-tabs .ppg-tabs__list .ppg-tabs__item.ui-tabs-active .ppg-tabs__link:after {
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          background-color: #c33400;
          bottom: -0.3125rem;
          left: 50%;
          -webkit-transform: translate(-50%, 0) rotate(45deg);
          -moz-transform: translate(-50%, 0) rotate(45deg);
          -ms-transform: translate(-50%, 0) rotate(45deg);
          -o-transform: translate(-50%, 0) rotate(45deg);
          transform: translate(-50%, 0) rotate(45deg); }
    .ppg-tabs .ppg-tabs__list .ppg-tabs__link {
      display: block;
      width: 100%;
      text-align: center;
      background-color: white;
      color: #333333;
      border-bottom: 1px solid transparent; }
      .ppg-tabs .ppg-tabs__list .ppg-tabs__link:hover {
        background-color: #fff4f0; }
  .ppg-tabs .ppg-tabs__content {
    background-color: white; }
  .ppg-tabs__img {
    border-radius: 50%;
    border: 3px solid transparent; }
  .ppg-tabs .ppg-link:hover {
    text-decoration: none; }
    .ppg-tabs .ppg-link:hover .ppg-tabs__img {
      border: 3px solid #c33400; }
    .ppg-tabs .ppg-link:hover .ppg-tabs__caption {
      color: #c33400; }
  .ppg-tabs--type2.ppg-tabs {
    border: 0; }
    .ppg-tabs--type2.ppg-tabs .ppg-tabs__list {
      position: relative;
      bottom: -1px;
      padding: 0;
      margin: 0;
      border: 0;
      border-radius: 0; }
      .ppg-tabs--type2.ppg-tabs .ppg-tabs__list .ppg-tabs__item {
        width: auto;
        margin-right: 1.25rem;
        border: 1px solid #bbbbbb;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        background-color: transparent; }
        .ppg-tabs--type2.ppg-tabs .ppg-tabs__list .ppg-tabs__item.ui-tabs-active {
          border-bottom-color: white; }
          .ppg-tabs--type2.ppg-tabs .ppg-tabs__list .ppg-tabs__item.ui-tabs-active .ppg-tabs__link {
            position: relative;
            background-color: white;
            color: #333333;
            border-bottom: 0; }
            .ppg-tabs--type2.ppg-tabs .ppg-tabs__list .ppg-tabs__item.ui-tabs-active .ppg-tabs__link:after {
              content: none; }
      .ppg-tabs--type2.ppg-tabs .ppg-tabs__list .ppg-tabs__link {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        background-color: #f4f4f4;
        color: #333333;
        border-bottom: 1px solid white; }
        .ppg-tabs--type2.ppg-tabs .ppg-tabs__list .ppg-tabs__link:hover {
          background-color: #fff4f0; }
    .ppg-tabs--type2.ppg-tabs .ppg-tabs__content {
      border-top: 1px solid #bbbbbb;
      padding-left: 0;
      padding-right: 0;
      background-color: white; }
    .ppg-tabs--type2.ppg-tabs .ppg-tabs__text {
      margin-bottom: 1.25rem; }

@media only screen and (max-width: 767px) {
  .ppg-tabs {
    display: none; }
    .ppg-tabs--type2.ppg-tabs {
      display: block; }
      .ppg-tabs--type2.ppg-tabs .ppg-tabs__list .ppg-tabs__item {
        width: 49%;
        margin-right: .5%; }
      .ppg-tabs--type2.ppg-tabs .ppg-tabs__list .ppg-tabs__link {
        padding-right: 0.3125rem;
        padding-left: 0.3125rem;
        overflow: hidden;
        text-overflow: ellipsis; } }

/*----------------------------------------------
   # TEXT: estilización de los elementos de texto del portal
			Sólo se estilizan txt párrafos qe no tienen interacción
			propia del elemento.
------------------------------------------------*/
.ppg-text {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.875rem;
  line-height: 1.29;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #333333; }
  .ppg-text--bold {
    font-weight: bold; }
  .ppg-text--lighted {
    color: #666666; }
  .ppg-text--lighted-2 {
    color: #444444; }
  .ppg-text--lighted-3 {
    font-size: 0.75rem;
    color: #444444; }
  .ppg-text--top-lighted {
    padding-top: 0.375rem;
    color: #444444; }
  .ppg-text--primary-color {
    color: #c33400; }
  .ppg-text--wind {
    padding-top: 0.625rem; }
  .ppg-text--type02 {
    font-size: 1rem; }
  .ppg-text--type03 {
    padding-left: 1rem; }
  .ppg-text--mbttm {
    margin-bottom: 0.625rem; }

.o-list--inline .ppg-text--lighted-2 {
  padding-top: 0.75rem;
  font-size: 0.75rem;
  float: right; }

.ppg-footer--list .ppg-text {
  font-size: 0.8125rem;
  padding-bottom: 0.625rem;
  font-weight: bold; }

.ppg-table--type02 .ppg-text {
  padding-left: 1.875rem; }
  .ppg-table--type02 .ppg-text--type02 {
    padding-left: 0;
    font-weight: bold;
    display: inline-block;
    word-wrap: break-word;
    max-width: 66%;
    vertical-align: middle; }

@media only screen and (max-width: 767px) {
  .ppg-text--type03 {
    padding-left: 0; } }

.ppg-menu {
  display: block; }
  .ppg-menu__content {
    display: none; }
  .ppg-menu .content-active {
    display: block; }

@media only screen and (max-width: 319px) {
  .ppg-menu {
    display: none; } }

@media only screen and (max-width: 767px) {
  .ppg-menu {
    display: none; }
    .ppg-menu__it .ppg-menu__content {
      width: auto !important;
      display: none; }
    .ppg-menu__it:hover .ppg-menu__content {
      width: auto !important;
      display: none; } }

@media only screen and (max-width: 1023px) {
  .ppg-menu__it .ppg-menu__content {
    display: none; }
  .ppg-menu__content {
    display: none; }
  .ppg-menu__it:hover .ppg-menu__content {
    display: block; }
  .ppg-menu__it:hover .ppg-menu__link {
    color: #9e2c02;
    background-color: white; }
    .ppg-menu__it:hover .ppg-menu__link .ppg-icon__crossW-3a:after {
      width: 11px;
      height: 7px;
      background-position: -5px -96px; } }

@media only screen and (max-width: 1279px) {
  .ppg-menu__it .ppg-menu__content {
    display: none; }
  .ppg-menu__content {
    display: none; }
  .ppg-menu__it:hover .ppg-menu__content {
    display: block; }
  .ppg-menu__it:hover .ppg-menu__link {
    color: #9e2c02;
    background-color: white; }
    .ppg-menu__it:hover .ppg-menu__link .ppg-icon__crossW-3a:after {
      width: 11px;
      height: 7px;
      background-position: -5px -96px; } }

@media only screen and (max-width: 3499px) {
  .ppg-menu__it .ppg-menu__content {
    display: none; }
  .ppg-menu__content {
    display: none; }
  .ppg-menu__it:hover .ppg-menu__content {
    display: block; }
  .ppg-menu__it:hover .ppg-menu__link {
    color: #9e2c02;
    background-color: white; }
    .ppg-menu__it:hover .ppg-menu__link .ppg-icon__crossW-3a:after {
      width: 11px;
      height: 7px;
      background-position: -5px -96px; } }

/*----------------------------------------------
   # MAP LAYER
------------------------------------------------*/
.ppg-map {
  position: relative;
  width: 100%;
  min-height: 17.5rem;
  overflow: hidden; }
  .ppg-map__info {
    width: 40%;
    max-width: 15.5rem;
    position: absolute;
    left: 1rem;
    top: 1rem;
    background: white;
    padding: 1.25rem 1.3125rem 1.0625rem; }
    .ppg-map__info--title {
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
      font-size: 0.875rem; }
    .ppg-map__info--text {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 1rem;
      line-height: 1.19; }
    .ppg-map__info--top {
      padding-right: 1.25rem; }
  .ppg-map__list {
    margin-top: 0.75rem; }
    .ppg-map__list--item {
      padding: 0.5rem 0;
      border-top: 0.0625rem solid #dddddd; }
      .ppg-map__list--item:last-child {
        padding-bottom: 0; }
    .ppg-map__list .ppg-icon--before:before {
      margin-right: 0.625rem; }
  .ppg-map .ppg-fig__example {
    height: 100%;
    min-height: 17.5rem; }

@media only screen and (max-width: 767px) {
  .ppg-map__info {
    width: 100%;
    max-width: 88%; }
    .ppg-map__info--top {
      padding-right: 0; } }

/*----------------------------------------------
   # módulo
------------------------------------------------*/
/*------------------------------------
         # TRUMPS FLOAT
     ------------------------------------*/
/**
     * Float left.
     */
.t-float-left {
  float: left !important; }

/**
      * Float right.
      */
.t-float-right {
  float: right !important; }

/**
      * Float none.
      */
.t-float-none {
  float: none !important; }

/*------------------------------------
         # END TRUMPS FLOAT
     ------------------------------------*/
/*------------------------------------
       # TRUMPS FONTS SIZE
    -----------------------------------*/
.t-fs-0 {
  font-size: 0px !important; }

.t-fs-10 {
  font-size: 10px !important; }

.t-fs-13 {
  font-size: 13px !important; }

.t-fs-14 {
  font-size: 14px !important; }

.t-fs-16 {
  font-size: 16px !important; }

.t-fs-18 {
  font-size: 18px !important; }

.t-fs-20 {
  font-size: 20px !important; }

.t-fs-22 {
  font-size: 22px !important; }

.t-fs-24 {
  font-size: 24px !important; }

.t-fs-27 {
  font-size: 27px !important; }

/*------------------------------------
       # END TRUMPS FONTS SIZE
    -----------------------------------*/
/*------------------------------------
         # .t-hide
     ------------------------------------*/
/**
     * Hide elements on certain breakpoints based on your responsive settings.
     */
.t-hide {
  display: none !important; }

@media only screen and (max-width: 319px) {
  .t-hide-xs {
    display: none !important; } }

@media only screen and (max-width: 767px) {
  .t-hide-sm {
    display: none !important; } }

@media only screen and (max-width: 1023px) {
  .t-hide-md {
    display: none !important; } }

@media only screen and (max-width: 1279px) {
  .t-hide-lg {
    display: none !important; } }

@media only screen and (max-width: 3499px) {
  .t-hide-xl {
    display: none !important; } }

@media only screen and (max-width: 1279px) {
  .t-hide--lg {
    display: none !important; } }

@media only screen and (max-width: 767px) {
  .t-hide--sm {
    display: none !important; } }

/*------------------------------------
       # TRUMPS FONTS SIZE
    -----------------------------------*/
.t-lh-160 {
  line-height: 160% !important; }

/*------------------------------------
       # END TRUMPS FONTS SIZE
    -----------------------------------*/
/*------------------------------------
     # TRUMP MARGIN
    ------------------------------------*/
.t-margin--600 {
  margin: -600px !important; }

.t-margin-top--600 {
  margin-top: -600px !important; }

.t-margin-right--600 {
  margin-right: -600px !important; }

.t-margin-bottom--600 {
  margin-bottom: -600px !important; }

.t-margin-left--600 {
  margin-left: -600px !important; }

.t-margin--595 {
  margin: -595px !important; }

.t-margin-top--595 {
  margin-top: -595px !important; }

.t-margin-right--595 {
  margin-right: -595px !important; }

.t-margin-bottom--595 {
  margin-bottom: -595px !important; }

.t-margin-left--595 {
  margin-left: -595px !important; }

.t-margin--590 {
  margin: -590px !important; }

.t-margin-top--590 {
  margin-top: -590px !important; }

.t-margin-right--590 {
  margin-right: -590px !important; }

.t-margin-bottom--590 {
  margin-bottom: -590px !important; }

.t-margin-left--590 {
  margin-left: -590px !important; }

.t-margin--585 {
  margin: -585px !important; }

.t-margin-top--585 {
  margin-top: -585px !important; }

.t-margin-right--585 {
  margin-right: -585px !important; }

.t-margin-bottom--585 {
  margin-bottom: -585px !important; }

.t-margin-left--585 {
  margin-left: -585px !important; }

.t-margin--580 {
  margin: -580px !important; }

.t-margin-top--580 {
  margin-top: -580px !important; }

.t-margin-right--580 {
  margin-right: -580px !important; }

.t-margin-bottom--580 {
  margin-bottom: -580px !important; }

.t-margin-left--580 {
  margin-left: -580px !important; }

.t-margin--575 {
  margin: -575px !important; }

.t-margin-top--575 {
  margin-top: -575px !important; }

.t-margin-right--575 {
  margin-right: -575px !important; }

.t-margin-bottom--575 {
  margin-bottom: -575px !important; }

.t-margin-left--575 {
  margin-left: -575px !important; }

.t-margin--570 {
  margin: -570px !important; }

.t-margin-top--570 {
  margin-top: -570px !important; }

.t-margin-right--570 {
  margin-right: -570px !important; }

.t-margin-bottom--570 {
  margin-bottom: -570px !important; }

.t-margin-left--570 {
  margin-left: -570px !important; }

.t-margin--565 {
  margin: -565px !important; }

.t-margin-top--565 {
  margin-top: -565px !important; }

.t-margin-right--565 {
  margin-right: -565px !important; }

.t-margin-bottom--565 {
  margin-bottom: -565px !important; }

.t-margin-left--565 {
  margin-left: -565px !important; }

.t-margin--560 {
  margin: -560px !important; }

.t-margin-top--560 {
  margin-top: -560px !important; }

.t-margin-right--560 {
  margin-right: -560px !important; }

.t-margin-bottom--560 {
  margin-bottom: -560px !important; }

.t-margin-left--560 {
  margin-left: -560px !important; }

.t-margin--555 {
  margin: -555px !important; }

.t-margin-top--555 {
  margin-top: -555px !important; }

.t-margin-right--555 {
  margin-right: -555px !important; }

.t-margin-bottom--555 {
  margin-bottom: -555px !important; }

.t-margin-left--555 {
  margin-left: -555px !important; }

.t-margin--550 {
  margin: -550px !important; }

.t-margin-top--550 {
  margin-top: -550px !important; }

.t-margin-right--550 {
  margin-right: -550px !important; }

.t-margin-bottom--550 {
  margin-bottom: -550px !important; }

.t-margin-left--550 {
  margin-left: -550px !important; }

.t-margin--545 {
  margin: -545px !important; }

.t-margin-top--545 {
  margin-top: -545px !important; }

.t-margin-right--545 {
  margin-right: -545px !important; }

.t-margin-bottom--545 {
  margin-bottom: -545px !important; }

.t-margin-left--545 {
  margin-left: -545px !important; }

.t-margin--540 {
  margin: -540px !important; }

.t-margin-top--540 {
  margin-top: -540px !important; }

.t-margin-right--540 {
  margin-right: -540px !important; }

.t-margin-bottom--540 {
  margin-bottom: -540px !important; }

.t-margin-left--540 {
  margin-left: -540px !important; }

.t-margin--535 {
  margin: -535px !important; }

.t-margin-top--535 {
  margin-top: -535px !important; }

.t-margin-right--535 {
  margin-right: -535px !important; }

.t-margin-bottom--535 {
  margin-bottom: -535px !important; }

.t-margin-left--535 {
  margin-left: -535px !important; }

.t-margin--530 {
  margin: -530px !important; }

.t-margin-top--530 {
  margin-top: -530px !important; }

.t-margin-right--530 {
  margin-right: -530px !important; }

.t-margin-bottom--530 {
  margin-bottom: -530px !important; }

.t-margin-left--530 {
  margin-left: -530px !important; }

.t-margin--525 {
  margin: -525px !important; }

.t-margin-top--525 {
  margin-top: -525px !important; }

.t-margin-right--525 {
  margin-right: -525px !important; }

.t-margin-bottom--525 {
  margin-bottom: -525px !important; }

.t-margin-left--525 {
  margin-left: -525px !important; }

.t-margin--520 {
  margin: -520px !important; }

.t-margin-top--520 {
  margin-top: -520px !important; }

.t-margin-right--520 {
  margin-right: -520px !important; }

.t-margin-bottom--520 {
  margin-bottom: -520px !important; }

.t-margin-left--520 {
  margin-left: -520px !important; }

.t-margin--515 {
  margin: -515px !important; }

.t-margin-top--515 {
  margin-top: -515px !important; }

.t-margin-right--515 {
  margin-right: -515px !important; }

.t-margin-bottom--515 {
  margin-bottom: -515px !important; }

.t-margin-left--515 {
  margin-left: -515px !important; }

.t-margin--510 {
  margin: -510px !important; }

.t-margin-top--510 {
  margin-top: -510px !important; }

.t-margin-right--510 {
  margin-right: -510px !important; }

.t-margin-bottom--510 {
  margin-bottom: -510px !important; }

.t-margin-left--510 {
  margin-left: -510px !important; }

.t-margin--505 {
  margin: -505px !important; }

.t-margin-top--505 {
  margin-top: -505px !important; }

.t-margin-right--505 {
  margin-right: -505px !important; }

.t-margin-bottom--505 {
  margin-bottom: -505px !important; }

.t-margin-left--505 {
  margin-left: -505px !important; }

.t-margin--500 {
  margin: -500px !important; }

.t-margin-top--500 {
  margin-top: -500px !important; }

.t-margin-right--500 {
  margin-right: -500px !important; }

.t-margin-bottom--500 {
  margin-bottom: -500px !important; }

.t-margin-left--500 {
  margin-left: -500px !important; }

.t-margin--495 {
  margin: -495px !important; }

.t-margin-top--495 {
  margin-top: -495px !important; }

.t-margin-right--495 {
  margin-right: -495px !important; }

.t-margin-bottom--495 {
  margin-bottom: -495px !important; }

.t-margin-left--495 {
  margin-left: -495px !important; }

.t-margin--490 {
  margin: -490px !important; }

.t-margin-top--490 {
  margin-top: -490px !important; }

.t-margin-right--490 {
  margin-right: -490px !important; }

.t-margin-bottom--490 {
  margin-bottom: -490px !important; }

.t-margin-left--490 {
  margin-left: -490px !important; }

.t-margin--485 {
  margin: -485px !important; }

.t-margin-top--485 {
  margin-top: -485px !important; }

.t-margin-right--485 {
  margin-right: -485px !important; }

.t-margin-bottom--485 {
  margin-bottom: -485px !important; }

.t-margin-left--485 {
  margin-left: -485px !important; }

.t-margin--480 {
  margin: -480px !important; }

.t-margin-top--480 {
  margin-top: -480px !important; }

.t-margin-right--480 {
  margin-right: -480px !important; }

.t-margin-bottom--480 {
  margin-bottom: -480px !important; }

.t-margin-left--480 {
  margin-left: -480px !important; }

.t-margin--475 {
  margin: -475px !important; }

.t-margin-top--475 {
  margin-top: -475px !important; }

.t-margin-right--475 {
  margin-right: -475px !important; }

.t-margin-bottom--475 {
  margin-bottom: -475px !important; }

.t-margin-left--475 {
  margin-left: -475px !important; }

.t-margin--470 {
  margin: -470px !important; }

.t-margin-top--470 {
  margin-top: -470px !important; }

.t-margin-right--470 {
  margin-right: -470px !important; }

.t-margin-bottom--470 {
  margin-bottom: -470px !important; }

.t-margin-left--470 {
  margin-left: -470px !important; }

.t-margin--465 {
  margin: -465px !important; }

.t-margin-top--465 {
  margin-top: -465px !important; }

.t-margin-right--465 {
  margin-right: -465px !important; }

.t-margin-bottom--465 {
  margin-bottom: -465px !important; }

.t-margin-left--465 {
  margin-left: -465px !important; }

.t-margin--460 {
  margin: -460px !important; }

.t-margin-top--460 {
  margin-top: -460px !important; }

.t-margin-right--460 {
  margin-right: -460px !important; }

.t-margin-bottom--460 {
  margin-bottom: -460px !important; }

.t-margin-left--460 {
  margin-left: -460px !important; }

.t-margin--455 {
  margin: -455px !important; }

.t-margin-top--455 {
  margin-top: -455px !important; }

.t-margin-right--455 {
  margin-right: -455px !important; }

.t-margin-bottom--455 {
  margin-bottom: -455px !important; }

.t-margin-left--455 {
  margin-left: -455px !important; }

.t-margin--450 {
  margin: -450px !important; }

.t-margin-top--450 {
  margin-top: -450px !important; }

.t-margin-right--450 {
  margin-right: -450px !important; }

.t-margin-bottom--450 {
  margin-bottom: -450px !important; }

.t-margin-left--450 {
  margin-left: -450px !important; }

.t-margin--445 {
  margin: -445px !important; }

.t-margin-top--445 {
  margin-top: -445px !important; }

.t-margin-right--445 {
  margin-right: -445px !important; }

.t-margin-bottom--445 {
  margin-bottom: -445px !important; }

.t-margin-left--445 {
  margin-left: -445px !important; }

.t-margin--440 {
  margin: -440px !important; }

.t-margin-top--440 {
  margin-top: -440px !important; }

.t-margin-right--440 {
  margin-right: -440px !important; }

.t-margin-bottom--440 {
  margin-bottom: -440px !important; }

.t-margin-left--440 {
  margin-left: -440px !important; }

.t-margin--435 {
  margin: -435px !important; }

.t-margin-top--435 {
  margin-top: -435px !important; }

.t-margin-right--435 {
  margin-right: -435px !important; }

.t-margin-bottom--435 {
  margin-bottom: -435px !important; }

.t-margin-left--435 {
  margin-left: -435px !important; }

.t-margin--430 {
  margin: -430px !important; }

.t-margin-top--430 {
  margin-top: -430px !important; }

.t-margin-right--430 {
  margin-right: -430px !important; }

.t-margin-bottom--430 {
  margin-bottom: -430px !important; }

.t-margin-left--430 {
  margin-left: -430px !important; }

.t-margin--425 {
  margin: -425px !important; }

.t-margin-top--425 {
  margin-top: -425px !important; }

.t-margin-right--425 {
  margin-right: -425px !important; }

.t-margin-bottom--425 {
  margin-bottom: -425px !important; }

.t-margin-left--425 {
  margin-left: -425px !important; }

.t-margin--420 {
  margin: -420px !important; }

.t-margin-top--420 {
  margin-top: -420px !important; }

.t-margin-right--420 {
  margin-right: -420px !important; }

.t-margin-bottom--420 {
  margin-bottom: -420px !important; }

.t-margin-left--420 {
  margin-left: -420px !important; }

.t-margin--415 {
  margin: -415px !important; }

.t-margin-top--415 {
  margin-top: -415px !important; }

.t-margin-right--415 {
  margin-right: -415px !important; }

.t-margin-bottom--415 {
  margin-bottom: -415px !important; }

.t-margin-left--415 {
  margin-left: -415px !important; }

.t-margin--410 {
  margin: -410px !important; }

.t-margin-top--410 {
  margin-top: -410px !important; }

.t-margin-right--410 {
  margin-right: -410px !important; }

.t-margin-bottom--410 {
  margin-bottom: -410px !important; }

.t-margin-left--410 {
  margin-left: -410px !important; }

.t-margin--405 {
  margin: -405px !important; }

.t-margin-top--405 {
  margin-top: -405px !important; }

.t-margin-right--405 {
  margin-right: -405px !important; }

.t-margin-bottom--405 {
  margin-bottom: -405px !important; }

.t-margin-left--405 {
  margin-left: -405px !important; }

.t-margin--400 {
  margin: -400px !important; }

.t-margin-top--400 {
  margin-top: -400px !important; }

.t-margin-right--400 {
  margin-right: -400px !important; }

.t-margin-bottom--400 {
  margin-bottom: -400px !important; }

.t-margin-left--400 {
  margin-left: -400px !important; }

.t-margin--395 {
  margin: -395px !important; }

.t-margin-top--395 {
  margin-top: -395px !important; }

.t-margin-right--395 {
  margin-right: -395px !important; }

.t-margin-bottom--395 {
  margin-bottom: -395px !important; }

.t-margin-left--395 {
  margin-left: -395px !important; }

.t-margin--390 {
  margin: -390px !important; }

.t-margin-top--390 {
  margin-top: -390px !important; }

.t-margin-right--390 {
  margin-right: -390px !important; }

.t-margin-bottom--390 {
  margin-bottom: -390px !important; }

.t-margin-left--390 {
  margin-left: -390px !important; }

.t-margin--385 {
  margin: -385px !important; }

.t-margin-top--385 {
  margin-top: -385px !important; }

.t-margin-right--385 {
  margin-right: -385px !important; }

.t-margin-bottom--385 {
  margin-bottom: -385px !important; }

.t-margin-left--385 {
  margin-left: -385px !important; }

.t-margin--380 {
  margin: -380px !important; }

.t-margin-top--380 {
  margin-top: -380px !important; }

.t-margin-right--380 {
  margin-right: -380px !important; }

.t-margin-bottom--380 {
  margin-bottom: -380px !important; }

.t-margin-left--380 {
  margin-left: -380px !important; }

.t-margin--375 {
  margin: -375px !important; }

.t-margin-top--375 {
  margin-top: -375px !important; }

.t-margin-right--375 {
  margin-right: -375px !important; }

.t-margin-bottom--375 {
  margin-bottom: -375px !important; }

.t-margin-left--375 {
  margin-left: -375px !important; }

.t-margin--370 {
  margin: -370px !important; }

.t-margin-top--370 {
  margin-top: -370px !important; }

.t-margin-right--370 {
  margin-right: -370px !important; }

.t-margin-bottom--370 {
  margin-bottom: -370px !important; }

.t-margin-left--370 {
  margin-left: -370px !important; }

.t-margin--365 {
  margin: -365px !important; }

.t-margin-top--365 {
  margin-top: -365px !important; }

.t-margin-right--365 {
  margin-right: -365px !important; }

.t-margin-bottom--365 {
  margin-bottom: -365px !important; }

.t-margin-left--365 {
  margin-left: -365px !important; }

.t-margin--360 {
  margin: -360px !important; }

.t-margin-top--360 {
  margin-top: -360px !important; }

.t-margin-right--360 {
  margin-right: -360px !important; }

.t-margin-bottom--360 {
  margin-bottom: -360px !important; }

.t-margin-left--360 {
  margin-left: -360px !important; }

.t-margin--355 {
  margin: -355px !important; }

.t-margin-top--355 {
  margin-top: -355px !important; }

.t-margin-right--355 {
  margin-right: -355px !important; }

.t-margin-bottom--355 {
  margin-bottom: -355px !important; }

.t-margin-left--355 {
  margin-left: -355px !important; }

.t-margin--350 {
  margin: -350px !important; }

.t-margin-top--350 {
  margin-top: -350px !important; }

.t-margin-right--350 {
  margin-right: -350px !important; }

.t-margin-bottom--350 {
  margin-bottom: -350px !important; }

.t-margin-left--350 {
  margin-left: -350px !important; }

.t-margin--345 {
  margin: -345px !important; }

.t-margin-top--345 {
  margin-top: -345px !important; }

.t-margin-right--345 {
  margin-right: -345px !important; }

.t-margin-bottom--345 {
  margin-bottom: -345px !important; }

.t-margin-left--345 {
  margin-left: -345px !important; }

.t-margin--340 {
  margin: -340px !important; }

.t-margin-top--340 {
  margin-top: -340px !important; }

.t-margin-right--340 {
  margin-right: -340px !important; }

.t-margin-bottom--340 {
  margin-bottom: -340px !important; }

.t-margin-left--340 {
  margin-left: -340px !important; }

.t-margin--335 {
  margin: -335px !important; }

.t-margin-top--335 {
  margin-top: -335px !important; }

.t-margin-right--335 {
  margin-right: -335px !important; }

.t-margin-bottom--335 {
  margin-bottom: -335px !important; }

.t-margin-left--335 {
  margin-left: -335px !important; }

.t-margin--330 {
  margin: -330px !important; }

.t-margin-top--330 {
  margin-top: -330px !important; }

.t-margin-right--330 {
  margin-right: -330px !important; }

.t-margin-bottom--330 {
  margin-bottom: -330px !important; }

.t-margin-left--330 {
  margin-left: -330px !important; }

.t-margin--325 {
  margin: -325px !important; }

.t-margin-top--325 {
  margin-top: -325px !important; }

.t-margin-right--325 {
  margin-right: -325px !important; }

.t-margin-bottom--325 {
  margin-bottom: -325px !important; }

.t-margin-left--325 {
  margin-left: -325px !important; }

.t-margin--320 {
  margin: -320px !important; }

.t-margin-top--320 {
  margin-top: -320px !important; }

.t-margin-right--320 {
  margin-right: -320px !important; }

.t-margin-bottom--320 {
  margin-bottom: -320px !important; }

.t-margin-left--320 {
  margin-left: -320px !important; }

.t-margin--315 {
  margin: -315px !important; }

.t-margin-top--315 {
  margin-top: -315px !important; }

.t-margin-right--315 {
  margin-right: -315px !important; }

.t-margin-bottom--315 {
  margin-bottom: -315px !important; }

.t-margin-left--315 {
  margin-left: -315px !important; }

.t-margin--310 {
  margin: -310px !important; }

.t-margin-top--310 {
  margin-top: -310px !important; }

.t-margin-right--310 {
  margin-right: -310px !important; }

.t-margin-bottom--310 {
  margin-bottom: -310px !important; }

.t-margin-left--310 {
  margin-left: -310px !important; }

.t-margin--305 {
  margin: -305px !important; }

.t-margin-top--305 {
  margin-top: -305px !important; }

.t-margin-right--305 {
  margin-right: -305px !important; }

.t-margin-bottom--305 {
  margin-bottom: -305px !important; }

.t-margin-left--305 {
  margin-left: -305px !important; }

.t-margin--300 {
  margin: -300px !important; }

.t-margin-top--300 {
  margin-top: -300px !important; }

.t-margin-right--300 {
  margin-right: -300px !important; }

.t-margin-bottom--300 {
  margin-bottom: -300px !important; }

.t-margin-left--300 {
  margin-left: -300px !important; }

.t-margin--295 {
  margin: -295px !important; }

.t-margin-top--295 {
  margin-top: -295px !important; }

.t-margin-right--295 {
  margin-right: -295px !important; }

.t-margin-bottom--295 {
  margin-bottom: -295px !important; }

.t-margin-left--295 {
  margin-left: -295px !important; }

.t-margin--290 {
  margin: -290px !important; }

.t-margin-top--290 {
  margin-top: -290px !important; }

.t-margin-right--290 {
  margin-right: -290px !important; }

.t-margin-bottom--290 {
  margin-bottom: -290px !important; }

.t-margin-left--290 {
  margin-left: -290px !important; }

.t-margin--285 {
  margin: -285px !important; }

.t-margin-top--285 {
  margin-top: -285px !important; }

.t-margin-right--285 {
  margin-right: -285px !important; }

.t-margin-bottom--285 {
  margin-bottom: -285px !important; }

.t-margin-left--285 {
  margin-left: -285px !important; }

.t-margin--280 {
  margin: -280px !important; }

.t-margin-top--280 {
  margin-top: -280px !important; }

.t-margin-right--280 {
  margin-right: -280px !important; }

.t-margin-bottom--280 {
  margin-bottom: -280px !important; }

.t-margin-left--280 {
  margin-left: -280px !important; }

.t-margin--275 {
  margin: -275px !important; }

.t-margin-top--275 {
  margin-top: -275px !important; }

.t-margin-right--275 {
  margin-right: -275px !important; }

.t-margin-bottom--275 {
  margin-bottom: -275px !important; }

.t-margin-left--275 {
  margin-left: -275px !important; }

.t-margin--270 {
  margin: -270px !important; }

.t-margin-top--270 {
  margin-top: -270px !important; }

.t-margin-right--270 {
  margin-right: -270px !important; }

.t-margin-bottom--270 {
  margin-bottom: -270px !important; }

.t-margin-left--270 {
  margin-left: -270px !important; }

.t-margin--265 {
  margin: -265px !important; }

.t-margin-top--265 {
  margin-top: -265px !important; }

.t-margin-right--265 {
  margin-right: -265px !important; }

.t-margin-bottom--265 {
  margin-bottom: -265px !important; }

.t-margin-left--265 {
  margin-left: -265px !important; }

.t-margin--260 {
  margin: -260px !important; }

.t-margin-top--260 {
  margin-top: -260px !important; }

.t-margin-right--260 {
  margin-right: -260px !important; }

.t-margin-bottom--260 {
  margin-bottom: -260px !important; }

.t-margin-left--260 {
  margin-left: -260px !important; }

.t-margin--255 {
  margin: -255px !important; }

.t-margin-top--255 {
  margin-top: -255px !important; }

.t-margin-right--255 {
  margin-right: -255px !important; }

.t-margin-bottom--255 {
  margin-bottom: -255px !important; }

.t-margin-left--255 {
  margin-left: -255px !important; }

.t-margin--250 {
  margin: -250px !important; }

.t-margin-top--250 {
  margin-top: -250px !important; }

.t-margin-right--250 {
  margin-right: -250px !important; }

.t-margin-bottom--250 {
  margin-bottom: -250px !important; }

.t-margin-left--250 {
  margin-left: -250px !important; }

.t-margin--245 {
  margin: -245px !important; }

.t-margin-top--245 {
  margin-top: -245px !important; }

.t-margin-right--245 {
  margin-right: -245px !important; }

.t-margin-bottom--245 {
  margin-bottom: -245px !important; }

.t-margin-left--245 {
  margin-left: -245px !important; }

.t-margin--240 {
  margin: -240px !important; }

.t-margin-top--240 {
  margin-top: -240px !important; }

.t-margin-right--240 {
  margin-right: -240px !important; }

.t-margin-bottom--240 {
  margin-bottom: -240px !important; }

.t-margin-left--240 {
  margin-left: -240px !important; }

.t-margin--235 {
  margin: -235px !important; }

.t-margin-top--235 {
  margin-top: -235px !important; }

.t-margin-right--235 {
  margin-right: -235px !important; }

.t-margin-bottom--235 {
  margin-bottom: -235px !important; }

.t-margin-left--235 {
  margin-left: -235px !important; }

.t-margin--230 {
  margin: -230px !important; }

.t-margin-top--230 {
  margin-top: -230px !important; }

.t-margin-right--230 {
  margin-right: -230px !important; }

.t-margin-bottom--230 {
  margin-bottom: -230px !important; }

.t-margin-left--230 {
  margin-left: -230px !important; }

.t-margin--225 {
  margin: -225px !important; }

.t-margin-top--225 {
  margin-top: -225px !important; }

.t-margin-right--225 {
  margin-right: -225px !important; }

.t-margin-bottom--225 {
  margin-bottom: -225px !important; }

.t-margin-left--225 {
  margin-left: -225px !important; }

.t-margin--220 {
  margin: -220px !important; }

.t-margin-top--220 {
  margin-top: -220px !important; }

.t-margin-right--220 {
  margin-right: -220px !important; }

.t-margin-bottom--220 {
  margin-bottom: -220px !important; }

.t-margin-left--220 {
  margin-left: -220px !important; }

.t-margin--215 {
  margin: -215px !important; }

.t-margin-top--215 {
  margin-top: -215px !important; }

.t-margin-right--215 {
  margin-right: -215px !important; }

.t-margin-bottom--215 {
  margin-bottom: -215px !important; }

.t-margin-left--215 {
  margin-left: -215px !important; }

.t-margin--210 {
  margin: -210px !important; }

.t-margin-top--210 {
  margin-top: -210px !important; }

.t-margin-right--210 {
  margin-right: -210px !important; }

.t-margin-bottom--210 {
  margin-bottom: -210px !important; }

.t-margin-left--210 {
  margin-left: -210px !important; }

.t-margin--205 {
  margin: -205px !important; }

.t-margin-top--205 {
  margin-top: -205px !important; }

.t-margin-right--205 {
  margin-right: -205px !important; }

.t-margin-bottom--205 {
  margin-bottom: -205px !important; }

.t-margin-left--205 {
  margin-left: -205px !important; }

.t-margin--200 {
  margin: -200px !important; }

.t-margin-top--200 {
  margin-top: -200px !important; }

.t-margin-right--200 {
  margin-right: -200px !important; }

.t-margin-bottom--200 {
  margin-bottom: -200px !important; }

.t-margin-left--200 {
  margin-left: -200px !important; }

.t-margin--195 {
  margin: -195px !important; }

.t-margin-top--195 {
  margin-top: -195px !important; }

.t-margin-right--195 {
  margin-right: -195px !important; }

.t-margin-bottom--195 {
  margin-bottom: -195px !important; }

.t-margin-left--195 {
  margin-left: -195px !important; }

.t-margin--190 {
  margin: -190px !important; }

.t-margin-top--190 {
  margin-top: -190px !important; }

.t-margin-right--190 {
  margin-right: -190px !important; }

.t-margin-bottom--190 {
  margin-bottom: -190px !important; }

.t-margin-left--190 {
  margin-left: -190px !important; }

.t-margin--185 {
  margin: -185px !important; }

.t-margin-top--185 {
  margin-top: -185px !important; }

.t-margin-right--185 {
  margin-right: -185px !important; }

.t-margin-bottom--185 {
  margin-bottom: -185px !important; }

.t-margin-left--185 {
  margin-left: -185px !important; }

.t-margin--180 {
  margin: -180px !important; }

.t-margin-top--180 {
  margin-top: -180px !important; }

.t-margin-right--180 {
  margin-right: -180px !important; }

.t-margin-bottom--180 {
  margin-bottom: -180px !important; }

.t-margin-left--180 {
  margin-left: -180px !important; }

.t-margin--175 {
  margin: -175px !important; }

.t-margin-top--175 {
  margin-top: -175px !important; }

.t-margin-right--175 {
  margin-right: -175px !important; }

.t-margin-bottom--175 {
  margin-bottom: -175px !important; }

.t-margin-left--175 {
  margin-left: -175px !important; }

.t-margin--170 {
  margin: -170px !important; }

.t-margin-top--170 {
  margin-top: -170px !important; }

.t-margin-right--170 {
  margin-right: -170px !important; }

.t-margin-bottom--170 {
  margin-bottom: -170px !important; }

.t-margin-left--170 {
  margin-left: -170px !important; }

.t-margin--165 {
  margin: -165px !important; }

.t-margin-top--165 {
  margin-top: -165px !important; }

.t-margin-right--165 {
  margin-right: -165px !important; }

.t-margin-bottom--165 {
  margin-bottom: -165px !important; }

.t-margin-left--165 {
  margin-left: -165px !important; }

.t-margin--160 {
  margin: -160px !important; }

.t-margin-top--160 {
  margin-top: -160px !important; }

.t-margin-right--160 {
  margin-right: -160px !important; }

.t-margin-bottom--160 {
  margin-bottom: -160px !important; }

.t-margin-left--160 {
  margin-left: -160px !important; }

.t-margin--155 {
  margin: -155px !important; }

.t-margin-top--155 {
  margin-top: -155px !important; }

.t-margin-right--155 {
  margin-right: -155px !important; }

.t-margin-bottom--155 {
  margin-bottom: -155px !important; }

.t-margin-left--155 {
  margin-left: -155px !important; }

.t-margin--150 {
  margin: -150px !important; }

.t-margin-top--150 {
  margin-top: -150px !important; }

.t-margin-right--150 {
  margin-right: -150px !important; }

.t-margin-bottom--150 {
  margin-bottom: -150px !important; }

.t-margin-left--150 {
  margin-left: -150px !important; }

.t-margin--145 {
  margin: -145px !important; }

.t-margin-top--145 {
  margin-top: -145px !important; }

.t-margin-right--145 {
  margin-right: -145px !important; }

.t-margin-bottom--145 {
  margin-bottom: -145px !important; }

.t-margin-left--145 {
  margin-left: -145px !important; }

.t-margin--140 {
  margin: -140px !important; }

.t-margin-top--140 {
  margin-top: -140px !important; }

.t-margin-right--140 {
  margin-right: -140px !important; }

.t-margin-bottom--140 {
  margin-bottom: -140px !important; }

.t-margin-left--140 {
  margin-left: -140px !important; }

.t-margin--135 {
  margin: -135px !important; }

.t-margin-top--135 {
  margin-top: -135px !important; }

.t-margin-right--135 {
  margin-right: -135px !important; }

.t-margin-bottom--135 {
  margin-bottom: -135px !important; }

.t-margin-left--135 {
  margin-left: -135px !important; }

.t-margin--130 {
  margin: -130px !important; }

.t-margin-top--130 {
  margin-top: -130px !important; }

.t-margin-right--130 {
  margin-right: -130px !important; }

.t-margin-bottom--130 {
  margin-bottom: -130px !important; }

.t-margin-left--130 {
  margin-left: -130px !important; }

.t-margin--125 {
  margin: -125px !important; }

.t-margin-top--125 {
  margin-top: -125px !important; }

.t-margin-right--125 {
  margin-right: -125px !important; }

.t-margin-bottom--125 {
  margin-bottom: -125px !important; }

.t-margin-left--125 {
  margin-left: -125px !important; }

.t-margin--120 {
  margin: -120px !important; }

.t-margin-top--120 {
  margin-top: -120px !important; }

.t-margin-right--120 {
  margin-right: -120px !important; }

.t-margin-bottom--120 {
  margin-bottom: -120px !important; }

.t-margin-left--120 {
  margin-left: -120px !important; }

.t-margin--115 {
  margin: -115px !important; }

.t-margin-top--115 {
  margin-top: -115px !important; }

.t-margin-right--115 {
  margin-right: -115px !important; }

.t-margin-bottom--115 {
  margin-bottom: -115px !important; }

.t-margin-left--115 {
  margin-left: -115px !important; }

.t-margin--110 {
  margin: -110px !important; }

.t-margin-top--110 {
  margin-top: -110px !important; }

.t-margin-right--110 {
  margin-right: -110px !important; }

.t-margin-bottom--110 {
  margin-bottom: -110px !important; }

.t-margin-left--110 {
  margin-left: -110px !important; }

.t-margin--105 {
  margin: -105px !important; }

.t-margin-top--105 {
  margin-top: -105px !important; }

.t-margin-right--105 {
  margin-right: -105px !important; }

.t-margin-bottom--105 {
  margin-bottom: -105px !important; }

.t-margin-left--105 {
  margin-left: -105px !important; }

.t-margin--100 {
  margin: -100px !important; }

.t-margin-top--100 {
  margin-top: -100px !important; }

.t-margin-right--100 {
  margin-right: -100px !important; }

.t-margin-bottom--100 {
  margin-bottom: -100px !important; }

.t-margin-left--100 {
  margin-left: -100px !important; }

.t-margin--95 {
  margin: -95px !important; }

.t-margin-top--95 {
  margin-top: -95px !important; }

.t-margin-right--95 {
  margin-right: -95px !important; }

.t-margin-bottom--95 {
  margin-bottom: -95px !important; }

.t-margin-left--95 {
  margin-left: -95px !important; }

.t-margin--90 {
  margin: -90px !important; }

.t-margin-top--90 {
  margin-top: -90px !important; }

.t-margin-right--90 {
  margin-right: -90px !important; }

.t-margin-bottom--90 {
  margin-bottom: -90px !important; }

.t-margin-left--90 {
  margin-left: -90px !important; }

.t-margin--85 {
  margin: -85px !important; }

.t-margin-top--85 {
  margin-top: -85px !important; }

.t-margin-right--85 {
  margin-right: -85px !important; }

.t-margin-bottom--85 {
  margin-bottom: -85px !important; }

.t-margin-left--85 {
  margin-left: -85px !important; }

.t-margin--80 {
  margin: -80px !important; }

.t-margin-top--80 {
  margin-top: -80px !important; }

.t-margin-right--80 {
  margin-right: -80px !important; }

.t-margin-bottom--80 {
  margin-bottom: -80px !important; }

.t-margin-left--80 {
  margin-left: -80px !important; }

.t-margin--75 {
  margin: -75px !important; }

.t-margin-top--75 {
  margin-top: -75px !important; }

.t-margin-right--75 {
  margin-right: -75px !important; }

.t-margin-bottom--75 {
  margin-bottom: -75px !important; }

.t-margin-left--75 {
  margin-left: -75px !important; }

.t-margin--70 {
  margin: -70px !important; }

.t-margin-top--70 {
  margin-top: -70px !important; }

.t-margin-right--70 {
  margin-right: -70px !important; }

.t-margin-bottom--70 {
  margin-bottom: -70px !important; }

.t-margin-left--70 {
  margin-left: -70px !important; }

.t-margin--65 {
  margin: -65px !important; }

.t-margin-top--65 {
  margin-top: -65px !important; }

.t-margin-right--65 {
  margin-right: -65px !important; }

.t-margin-bottom--65 {
  margin-bottom: -65px !important; }

.t-margin-left--65 {
  margin-left: -65px !important; }

.t-margin--60 {
  margin: -60px !important; }

.t-margin-top--60 {
  margin-top: -60px !important; }

.t-margin-right--60 {
  margin-right: -60px !important; }

.t-margin-bottom--60 {
  margin-bottom: -60px !important; }

.t-margin-left--60 {
  margin-left: -60px !important; }

.t-margin--55 {
  margin: -55px !important; }

.t-margin-top--55 {
  margin-top: -55px !important; }

.t-margin-right--55 {
  margin-right: -55px !important; }

.t-margin-bottom--55 {
  margin-bottom: -55px !important; }

.t-margin-left--55 {
  margin-left: -55px !important; }

.t-margin--50 {
  margin: -50px !important; }

.t-margin-top--50 {
  margin-top: -50px !important; }

.t-margin-right--50 {
  margin-right: -50px !important; }

.t-margin-bottom--50 {
  margin-bottom: -50px !important; }

.t-margin-left--50 {
  margin-left: -50px !important; }

.t-margin--45 {
  margin: -45px !important; }

.t-margin-top--45 {
  margin-top: -45px !important; }

.t-margin-right--45 {
  margin-right: -45px !important; }

.t-margin-bottom--45 {
  margin-bottom: -45px !important; }

.t-margin-left--45 {
  margin-left: -45px !important; }

.t-margin--40 {
  margin: -40px !important; }

.t-margin-top--40 {
  margin-top: -40px !important; }

.t-margin-right--40 {
  margin-right: -40px !important; }

.t-margin-bottom--40 {
  margin-bottom: -40px !important; }

.t-margin-left--40 {
  margin-left: -40px !important; }

.t-margin--35 {
  margin: -35px !important; }

.t-margin-top--35 {
  margin-top: -35px !important; }

.t-margin-right--35 {
  margin-right: -35px !important; }

.t-margin-bottom--35 {
  margin-bottom: -35px !important; }

.t-margin-left--35 {
  margin-left: -35px !important; }

.t-margin--30 {
  margin: -30px !important; }

.t-margin-top--30 {
  margin-top: -30px !important; }

.t-margin-right--30 {
  margin-right: -30px !important; }

.t-margin-bottom--30 {
  margin-bottom: -30px !important; }

.t-margin-left--30 {
  margin-left: -30px !important; }

.t-margin--25 {
  margin: -25px !important; }

.t-margin-top--25 {
  margin-top: -25px !important; }

.t-margin-right--25 {
  margin-right: -25px !important; }

.t-margin-bottom--25 {
  margin-bottom: -25px !important; }

.t-margin-left--25 {
  margin-left: -25px !important; }

.t-margin--20 {
  margin: -20px !important; }

.t-margin-top--20 {
  margin-top: -20px !important; }

.t-margin-right--20 {
  margin-right: -20px !important; }

.t-margin-bottom--20 {
  margin-bottom: -20px !important; }

.t-margin-left--20 {
  margin-left: -20px !important; }

.t-margin--15 {
  margin: -15px !important; }

.t-margin-top--15 {
  margin-top: -15px !important; }

.t-margin-right--15 {
  margin-right: -15px !important; }

.t-margin-bottom--15 {
  margin-bottom: -15px !important; }

.t-margin-left--15 {
  margin-left: -15px !important; }

.t-margin--10 {
  margin: -10px !important; }

.t-margin-top--10 {
  margin-top: -10px !important; }

.t-margin-right--10 {
  margin-right: -10px !important; }

.t-margin-bottom--10 {
  margin-bottom: -10px !important; }

.t-margin-left--10 {
  margin-left: -10px !important; }

.t-margin--5 {
  margin: -5px !important; }

.t-margin-top--5 {
  margin-top: -5px !important; }

.t-margin-right--5 {
  margin-right: -5px !important; }

.t-margin-bottom--5 {
  margin-bottom: -5px !important; }

.t-margin-left--5 {
  margin-left: -5px !important; }

.t-margin-0 {
  margin: 0px !important; }

.t-margin-top-0 {
  margin-top: 0px !important; }

.t-margin-right-0 {
  margin-right: 0px !important; }

.t-margin-bottom-0 {
  margin-bottom: 0px !important; }

.t-margin-left-0 {
  margin-left: 0px !important; }

.t-margin-5 {
  margin: 5px !important; }

.t-margin-top-5 {
  margin-top: 5px !important; }

.t-margin-right-5 {
  margin-right: 5px !important; }

.t-margin-bottom-5 {
  margin-bottom: 5px !important; }

.t-margin-left-5 {
  margin-left: 5px !important; }

.t-margin-10 {
  margin: 10px !important; }

.t-margin-top-10 {
  margin-top: 10px !important; }

.t-margin-right-10 {
  margin-right: 10px !important; }

.t-margin-bottom-10 {
  margin-bottom: 10px !important; }

.t-margin-left-10 {
  margin-left: 10px !important; }

.t-margin-15 {
  margin: 15px !important; }

.t-margin-top-15 {
  margin-top: 15px !important; }

.t-margin-right-15 {
  margin-right: 15px !important; }

.t-margin-bottom-15 {
  margin-bottom: 15px !important; }

.t-margin-left-15 {
  margin-left: 15px !important; }

.t-margin-20 {
  margin: 20px !important; }

.t-margin-top-20 {
  margin-top: 20px !important; }

.t-margin-right-20 {
  margin-right: 20px !important; }

.t-margin-bottom-20 {
  margin-bottom: 20px !important; }

.t-margin-left-20 {
  margin-left: 20px !important; }

.t-margin-25 {
  margin: 25px !important; }

.t-margin-top-25 {
  margin-top: 25px !important; }

.t-margin-right-25 {
  margin-right: 25px !important; }

.t-margin-bottom-25 {
  margin-bottom: 25px !important; }

.t-margin-left-25 {
  margin-left: 25px !important; }

.t-margin-30 {
  margin: 30px !important; }

.t-margin-top-30 {
  margin-top: 30px !important; }

.t-margin-right-30 {
  margin-right: 30px !important; }

.t-margin-bottom-30 {
  margin-bottom: 30px !important; }

.t-margin-left-30 {
  margin-left: 30px !important; }

.t-margin-35 {
  margin: 35px !important; }

.t-margin-top-35 {
  margin-top: 35px !important; }

.t-margin-right-35 {
  margin-right: 35px !important; }

.t-margin-bottom-35 {
  margin-bottom: 35px !important; }

.t-margin-left-35 {
  margin-left: 35px !important; }

.t-margin-40 {
  margin: 40px !important; }

.t-margin-top-40 {
  margin-top: 40px !important; }

.t-margin-right-40 {
  margin-right: 40px !important; }

.t-margin-bottom-40 {
  margin-bottom: 40px !important; }

.t-margin-left-40 {
  margin-left: 40px !important; }

.t-margin-45 {
  margin: 45px !important; }

.t-margin-top-45 {
  margin-top: 45px !important; }

.t-margin-right-45 {
  margin-right: 45px !important; }

.t-margin-bottom-45 {
  margin-bottom: 45px !important; }

.t-margin-left-45 {
  margin-left: 45px !important; }

.t-margin-50 {
  margin: 50px !important; }

.t-margin-top-50 {
  margin-top: 50px !important; }

.t-margin-right-50 {
  margin-right: 50px !important; }

.t-margin-bottom-50 {
  margin-bottom: 50px !important; }

.t-margin-left-50 {
  margin-left: 50px !important; }

.t-margin-55 {
  margin: 55px !important; }

.t-margin-top-55 {
  margin-top: 55px !important; }

.t-margin-right-55 {
  margin-right: 55px !important; }

.t-margin-bottom-55 {
  margin-bottom: 55px !important; }

.t-margin-left-55 {
  margin-left: 55px !important; }

.t-margin-60 {
  margin: 60px !important; }

.t-margin-top-60 {
  margin-top: 60px !important; }

.t-margin-right-60 {
  margin-right: 60px !important; }

.t-margin-bottom-60 {
  margin-bottom: 60px !important; }

.t-margin-left-60 {
  margin-left: 60px !important; }

.t-margin-65 {
  margin: 65px !important; }

.t-margin-top-65 {
  margin-top: 65px !important; }

.t-margin-right-65 {
  margin-right: 65px !important; }

.t-margin-bottom-65 {
  margin-bottom: 65px !important; }

.t-margin-left-65 {
  margin-left: 65px !important; }

.t-margin-70 {
  margin: 70px !important; }

.t-margin-top-70 {
  margin-top: 70px !important; }

.t-margin-right-70 {
  margin-right: 70px !important; }

.t-margin-bottom-70 {
  margin-bottom: 70px !important; }

.t-margin-left-70 {
  margin-left: 70px !important; }

.t-margin-75 {
  margin: 75px !important; }

.t-margin-top-75 {
  margin-top: 75px !important; }

.t-margin-right-75 {
  margin-right: 75px !important; }

.t-margin-bottom-75 {
  margin-bottom: 75px !important; }

.t-margin-left-75 {
  margin-left: 75px !important; }

.t-margin-80 {
  margin: 80px !important; }

.t-margin-top-80 {
  margin-top: 80px !important; }

.t-margin-right-80 {
  margin-right: 80px !important; }

.t-margin-bottom-80 {
  margin-bottom: 80px !important; }

.t-margin-left-80 {
  margin-left: 80px !important; }

.t-margin-85 {
  margin: 85px !important; }

.t-margin-top-85 {
  margin-top: 85px !important; }

.t-margin-right-85 {
  margin-right: 85px !important; }

.t-margin-bottom-85 {
  margin-bottom: 85px !important; }

.t-margin-left-85 {
  margin-left: 85px !important; }

.t-margin-90 {
  margin: 90px !important; }

.t-margin-top-90 {
  margin-top: 90px !important; }

.t-margin-right-90 {
  margin-right: 90px !important; }

.t-margin-bottom-90 {
  margin-bottom: 90px !important; }

.t-margin-left-90 {
  margin-left: 90px !important; }

.t-margin-95 {
  margin: 95px !important; }

.t-margin-top-95 {
  margin-top: 95px !important; }

.t-margin-right-95 {
  margin-right: 95px !important; }

.t-margin-bottom-95 {
  margin-bottom: 95px !important; }

.t-margin-left-95 {
  margin-left: 95px !important; }

.t-margin-100 {
  margin: 100px !important; }

.t-margin-top-100 {
  margin-top: 100px !important; }

.t-margin-right-100 {
  margin-right: 100px !important; }

.t-margin-bottom-100 {
  margin-bottom: 100px !important; }

.t-margin-left-100 {
  margin-left: 100px !important; }

.t-margin-105 {
  margin: 105px !important; }

.t-margin-top-105 {
  margin-top: 105px !important; }

.t-margin-right-105 {
  margin-right: 105px !important; }

.t-margin-bottom-105 {
  margin-bottom: 105px !important; }

.t-margin-left-105 {
  margin-left: 105px !important; }

.t-margin-110 {
  margin: 110px !important; }

.t-margin-top-110 {
  margin-top: 110px !important; }

.t-margin-right-110 {
  margin-right: 110px !important; }

.t-margin-bottom-110 {
  margin-bottom: 110px !important; }

.t-margin-left-110 {
  margin-left: 110px !important; }

.t-margin-115 {
  margin: 115px !important; }

.t-margin-top-115 {
  margin-top: 115px !important; }

.t-margin-right-115 {
  margin-right: 115px !important; }

.t-margin-bottom-115 {
  margin-bottom: 115px !important; }

.t-margin-left-115 {
  margin-left: 115px !important; }

.t-margin-120 {
  margin: 120px !important; }

.t-margin-top-120 {
  margin-top: 120px !important; }

.t-margin-right-120 {
  margin-right: 120px !important; }

.t-margin-bottom-120 {
  margin-bottom: 120px !important; }

.t-margin-left-120 {
  margin-left: 120px !important; }

.t-margin-125 {
  margin: 125px !important; }

.t-margin-top-125 {
  margin-top: 125px !important; }

.t-margin-right-125 {
  margin-right: 125px !important; }

.t-margin-bottom-125 {
  margin-bottom: 125px !important; }

.t-margin-left-125 {
  margin-left: 125px !important; }

.t-margin-130 {
  margin: 130px !important; }

.t-margin-top-130 {
  margin-top: 130px !important; }

.t-margin-right-130 {
  margin-right: 130px !important; }

.t-margin-bottom-130 {
  margin-bottom: 130px !important; }

.t-margin-left-130 {
  margin-left: 130px !important; }

.t-margin-135 {
  margin: 135px !important; }

.t-margin-top-135 {
  margin-top: 135px !important; }

.t-margin-right-135 {
  margin-right: 135px !important; }

.t-margin-bottom-135 {
  margin-bottom: 135px !important; }

.t-margin-left-135 {
  margin-left: 135px !important; }

.t-margin-140 {
  margin: 140px !important; }

.t-margin-top-140 {
  margin-top: 140px !important; }

.t-margin-right-140 {
  margin-right: 140px !important; }

.t-margin-bottom-140 {
  margin-bottom: 140px !important; }

.t-margin-left-140 {
  margin-left: 140px !important; }

.t-margin-145 {
  margin: 145px !important; }

.t-margin-top-145 {
  margin-top: 145px !important; }

.t-margin-right-145 {
  margin-right: 145px !important; }

.t-margin-bottom-145 {
  margin-bottom: 145px !important; }

.t-margin-left-145 {
  margin-left: 145px !important; }

.t-margin-150 {
  margin: 150px !important; }

.t-margin-top-150 {
  margin-top: 150px !important; }

.t-margin-right-150 {
  margin-right: 150px !important; }

.t-margin-bottom-150 {
  margin-bottom: 150px !important; }

.t-margin-left-150 {
  margin-left: 150px !important; }

.t-margin-155 {
  margin: 155px !important; }

.t-margin-top-155 {
  margin-top: 155px !important; }

.t-margin-right-155 {
  margin-right: 155px !important; }

.t-margin-bottom-155 {
  margin-bottom: 155px !important; }

.t-margin-left-155 {
  margin-left: 155px !important; }

.t-margin-160 {
  margin: 160px !important; }

.t-margin-top-160 {
  margin-top: 160px !important; }

.t-margin-right-160 {
  margin-right: 160px !important; }

.t-margin-bottom-160 {
  margin-bottom: 160px !important; }

.t-margin-left-160 {
  margin-left: 160px !important; }

.t-margin-165 {
  margin: 165px !important; }

.t-margin-top-165 {
  margin-top: 165px !important; }

.t-margin-right-165 {
  margin-right: 165px !important; }

.t-margin-bottom-165 {
  margin-bottom: 165px !important; }

.t-margin-left-165 {
  margin-left: 165px !important; }

.t-margin-170 {
  margin: 170px !important; }

.t-margin-top-170 {
  margin-top: 170px !important; }

.t-margin-right-170 {
  margin-right: 170px !important; }

.t-margin-bottom-170 {
  margin-bottom: 170px !important; }

.t-margin-left-170 {
  margin-left: 170px !important; }

.t-margin-175 {
  margin: 175px !important; }

.t-margin-top-175 {
  margin-top: 175px !important; }

.t-margin-right-175 {
  margin-right: 175px !important; }

.t-margin-bottom-175 {
  margin-bottom: 175px !important; }

.t-margin-left-175 {
  margin-left: 175px !important; }

.t-margin-180 {
  margin: 180px !important; }

.t-margin-top-180 {
  margin-top: 180px !important; }

.t-margin-right-180 {
  margin-right: 180px !important; }

.t-margin-bottom-180 {
  margin-bottom: 180px !important; }

.t-margin-left-180 {
  margin-left: 180px !important; }

.t-margin-185 {
  margin: 185px !important; }

.t-margin-top-185 {
  margin-top: 185px !important; }

.t-margin-right-185 {
  margin-right: 185px !important; }

.t-margin-bottom-185 {
  margin-bottom: 185px !important; }

.t-margin-left-185 {
  margin-left: 185px !important; }

.t-margin-190 {
  margin: 190px !important; }

.t-margin-top-190 {
  margin-top: 190px !important; }

.t-margin-right-190 {
  margin-right: 190px !important; }

.t-margin-bottom-190 {
  margin-bottom: 190px !important; }

.t-margin-left-190 {
  margin-left: 190px !important; }

.t-margin-195 {
  margin: 195px !important; }

.t-margin-top-195 {
  margin-top: 195px !important; }

.t-margin-right-195 {
  margin-right: 195px !important; }

.t-margin-bottom-195 {
  margin-bottom: 195px !important; }

.t-margin-left-195 {
  margin-left: 195px !important; }

.t-margin-200 {
  margin: 200px !important; }

.t-margin-top-200 {
  margin-top: 200px !important; }

.t-margin-right-200 {
  margin-right: 200px !important; }

.t-margin-bottom-200 {
  margin-bottom: 200px !important; }

.t-margin-left-200 {
  margin-left: 200px !important; }

.t-margin-205 {
  margin: 205px !important; }

.t-margin-top-205 {
  margin-top: 205px !important; }

.t-margin-right-205 {
  margin-right: 205px !important; }

.t-margin-bottom-205 {
  margin-bottom: 205px !important; }

.t-margin-left-205 {
  margin-left: 205px !important; }

.t-margin-210 {
  margin: 210px !important; }

.t-margin-top-210 {
  margin-top: 210px !important; }

.t-margin-right-210 {
  margin-right: 210px !important; }

.t-margin-bottom-210 {
  margin-bottom: 210px !important; }

.t-margin-left-210 {
  margin-left: 210px !important; }

.t-margin-215 {
  margin: 215px !important; }

.t-margin-top-215 {
  margin-top: 215px !important; }

.t-margin-right-215 {
  margin-right: 215px !important; }

.t-margin-bottom-215 {
  margin-bottom: 215px !important; }

.t-margin-left-215 {
  margin-left: 215px !important; }

.t-margin-220 {
  margin: 220px !important; }

.t-margin-top-220 {
  margin-top: 220px !important; }

.t-margin-right-220 {
  margin-right: 220px !important; }

.t-margin-bottom-220 {
  margin-bottom: 220px !important; }

.t-margin-left-220 {
  margin-left: 220px !important; }

.t-margin-225 {
  margin: 225px !important; }

.t-margin-top-225 {
  margin-top: 225px !important; }

.t-margin-right-225 {
  margin-right: 225px !important; }

.t-margin-bottom-225 {
  margin-bottom: 225px !important; }

.t-margin-left-225 {
  margin-left: 225px !important; }

.t-margin-230 {
  margin: 230px !important; }

.t-margin-top-230 {
  margin-top: 230px !important; }

.t-margin-right-230 {
  margin-right: 230px !important; }

.t-margin-bottom-230 {
  margin-bottom: 230px !important; }

.t-margin-left-230 {
  margin-left: 230px !important; }

.t-margin-235 {
  margin: 235px !important; }

.t-margin-top-235 {
  margin-top: 235px !important; }

.t-margin-right-235 {
  margin-right: 235px !important; }

.t-margin-bottom-235 {
  margin-bottom: 235px !important; }

.t-margin-left-235 {
  margin-left: 235px !important; }

.t-margin-240 {
  margin: 240px !important; }

.t-margin-top-240 {
  margin-top: 240px !important; }

.t-margin-right-240 {
  margin-right: 240px !important; }

.t-margin-bottom-240 {
  margin-bottom: 240px !important; }

.t-margin-left-240 {
  margin-left: 240px !important; }

.t-margin-245 {
  margin: 245px !important; }

.t-margin-top-245 {
  margin-top: 245px !important; }

.t-margin-right-245 {
  margin-right: 245px !important; }

.t-margin-bottom-245 {
  margin-bottom: 245px !important; }

.t-margin-left-245 {
  margin-left: 245px !important; }

.t-margin-250 {
  margin: 250px !important; }

.t-margin-top-250 {
  margin-top: 250px !important; }

.t-margin-right-250 {
  margin-right: 250px !important; }

.t-margin-bottom-250 {
  margin-bottom: 250px !important; }

.t-margin-left-250 {
  margin-left: 250px !important; }

/*------------------------------------
     # END TRUMP MARGIN
    ------------------------------------*/
/*------------------------------------
     # TRUMP PADDING
    ------------------------------------*/
.t-padding-none {
  padding: 0 !important; }

.t-padding-top-none {
  padding-top: 0 !important; }

.t-padding-right-none {
  padding-right: 0 !important; }

.t-padding-bottom-none {
  padding-bottom: 0 !important; }

.t-padding-left-none {
  padding-left: 0 !important; }

.t-padding-0 {
  padding: 0px !important; }

.t-padding-top-0 {
  padding-top: 0px !important; }

.t-padding-right-0 {
  padding-right: 0px !important; }

.t-padding-bottom-0 {
  padding-bottom: 0px !important; }

.t-padding-left-0 {
  padding-left: 0px !important; }

.t-padding-5 {
  padding: 5px !important; }

.t-padding-top-5 {
  padding-top: 5px !important; }

.t-padding-right-5 {
  padding-right: 5px !important; }

.t-padding-bottom-5 {
  padding-bottom: 5px !important; }

.t-padding-left-5 {
  padding-left: 5px !important; }

.t-padding-10 {
  padding: 10px !important; }

.t-padding-top-10 {
  padding-top: 10px !important; }

.t-padding-right-10 {
  padding-right: 10px !important; }

.t-padding-bottom-10 {
  padding-bottom: 10px !important; }

.t-padding-left-10 {
  padding-left: 10px !important; }

.t-padding-15 {
  padding: 15px !important; }

.t-padding-top-15 {
  padding-top: 15px !important; }

.t-padding-right-15 {
  padding-right: 15px !important; }

.t-padding-bottom-15 {
  padding-bottom: 15px !important; }

.t-padding-left-15 {
  padding-left: 15px !important; }

.t-padding-20 {
  padding: 20px !important; }

.t-padding-top-20 {
  padding-top: 20px !important; }

.t-padding-right-20 {
  padding-right: 20px !important; }

.t-padding-bottom-20 {
  padding-bottom: 20px !important; }

.t-padding-left-20 {
  padding-left: 20px !important; }

.t-padding-25 {
  padding: 25px !important; }

.t-padding-top-25 {
  padding-top: 25px !important; }

.t-padding-right-25 {
  padding-right: 25px !important; }

.t-padding-bottom-25 {
  padding-bottom: 25px !important; }

.t-padding-left-25 {
  padding-left: 25px !important; }

.t-padding-30 {
  padding: 30px !important; }

.t-padding-top-30 {
  padding-top: 30px !important; }

.t-padding-right-30 {
  padding-right: 30px !important; }

.t-padding-bottom-30 {
  padding-bottom: 30px !important; }

.t-padding-left-30 {
  padding-left: 30px !important; }

.t-padding-35 {
  padding: 35px !important; }

.t-padding-top-35 {
  padding-top: 35px !important; }

.t-padding-right-35 {
  padding-right: 35px !important; }

.t-padding-bottom-35 {
  padding-bottom: 35px !important; }

.t-padding-left-35 {
  padding-left: 35px !important; }

.t-padding-40 {
  padding: 40px !important; }

.t-padding-top-40 {
  padding-top: 40px !important; }

.t-padding-right-40 {
  padding-right: 40px !important; }

.t-padding-bottom-40 {
  padding-bottom: 40px !important; }

.t-padding-left-40 {
  padding-left: 40px !important; }

.t-padding-45 {
  padding: 45px !important; }

.t-padding-top-45 {
  padding-top: 45px !important; }

.t-padding-right-45 {
  padding-right: 45px !important; }

.t-padding-bottom-45 {
  padding-bottom: 45px !important; }

.t-padding-left-45 {
  padding-left: 45px !important; }

.t-padding-50 {
  padding: 50px !important; }

.t-padding-top-50 {
  padding-top: 50px !important; }

.t-padding-right-50 {
  padding-right: 50px !important; }

.t-padding-bottom-50 {
  padding-bottom: 50px !important; }

.t-padding-left-50 {
  padding-left: 50px !important; }

/*------------------------------------
     # END TRUMP padding
    ------------------------------------*/
/*------------------------------------
     # TRUMP TEXT-ALIGN
    ------------------------------------*/
.t-text-align-right {
  text-align: right !important; }

.t-text-align-center {
  text-align: center !important; }

.t-text-align-left {
  text-align: left !important; }

/*------------------------------------
     # END TRUMP TEXT-ALIGN
    ------------------------------------*/
/*------------------------------------
       # TRUMPS FONTS SIZE
    -----------------------------------*/
.t-w-50 {
  width: 50% !important; }

.t-w-100 {
  width: 100% !important; }

.t-w-25 {
  width: 25% !important; }

.t-w-80 {
  width: 80% !important; }

/*------------------------------------
       # END TRUMPS FONTS SIZE
    -----------------------------------*/
