/*----------------------------------------------
   # LINK: estilización de los enlaces del portal
			Entenemods por enlace todos aquellos que su
			comportamiento afecta a la URL de la página.
			También, incluimos enlacs tpo "ancla".
------------------------------------------------*/

.#{$wf__ns}link{
	display: inline-block;
	font-family: $wf__font--default;
	font-size: rem(14);
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: color(primary);
	text-decoration: none;
	padding-top: rem(2);
	padding-bottom: rem(2);
	position: relative;
	box-sizing: border-box;
	border:1px solid transparent;
	&:hover{
	
	}
	&:focus{
		box-shadow: 0 0 5px 0 color(primary);
		border: solid 1px #bc3302;
		text-decoration: none;
		border-radius:1px;
		outline: 0;
	}
	&:active{
		color: color(secondary);
		text-decoration: underline;
	}

	// ELEMENT
	&__apps{
		display: inline-block;
		width: 49%;
		img{
			width: 100%;
		}
	}

	//MODIFIER
	&--bold{
		font-weight: bold;
	}
	&--white{
		color: color(white);
	}
	&--black{
		color: color(secondary);
		&:focus{
			text-decoration: underline;
		}
		&:active{
			color: color(primary);
		}
	}
	&--red{
		color: color(primary);
	}
	
	&--border{
		&:after{
			content:"";
			height: rem(15);
			border: 0.5px solid color(b-grey-1);
			position: absolute;
			right:0;
			top: 50%;
			-webkit-transform: translate(0,-50%);
			-moz-transform: translate(0,-50%);
			-ms-transform: translate(0,-50%);
			-o-transform: translate(0,-50%);
			transform: translate(0,-50%);
		}
	}
	&--big{
		font-size: rem(20);
		color: color(secondary);
		font-weight:bold;
	}
    // same button
    &--btn{
        border-radius: rem(3);
        background-color: color(primary);
        //width: 100%;
        border: 1px solid transparent;
        padding: rem(12) rem(20);
        font-family:$wf__font--default;
        color: color(white);
        font-size: rem(14);
        cursor: pointer;
        text-align: center;
        &:hover{
            text-decoration: none;
            background-color: color(primary-hover);
        }
        &:focus{
            background-color: color(primary);
            border: 1px solid color(white);
            box-shadow: 0 0 0 2px color(primary);
            text-decoration: none;
            outline:none;
        }
    }
	//Link button disabled
	
	&--btn2{
		border-radius: 3px;
		border: 1px solid color(grey-9);
		padding: rem(10);
		font-size: rem(14);
		line-height: 1.29;
		color: color(primary);
		display: inline-block;
		&:hover{
			text-decoration: none;
			background-color: color(salmon);
		}
		&:focus{
			box-shadow: 0 0 5px 0 color(primary);
			border: 1px solid color(primary);
			text-decoration: none;
			outline:none;
		}
	}
	&--btn-disable{
		border: 1px solid color(grey-7);
		color: color(grey-8);
		&:hover{
			background-color: transparent;
			cursor: default;
		}
		&:focus{
			box-shadow: none;
			border:  1px solid color(grey-7);
		}
	}
	&--btn-active{
		background-color: color(primary);
		color: color(white);
		border: 1px solid color(grey-7);
		&:hover{
			text-decoration: none;
			background-color: color(primary);
			color: color(white); 
			cursor: default;
		}
	}
	&--btn3{
		&:hover{
			background-color: color(salmon);
			text-decoration: none;
		}
	}
	&--seeds{
		//margin-top:20px;
		margin-bottom:rem(6);
		&:before{
			transform: rotate(180deg);
			vertical-align: initial;
			
		}
	}
	&--btn-margin{
		margin-top: rem(12);
	}
	&--btn-padding{
		padding: rem(12) rem(14);
	}
	

	&--pills{
        position: relative;
		display: block;
        border:1px solid color(grey-4);
        margin-bottom: rem(20);
        padding: rem(10);
        color: color(secondary);
        &:hover, &:focus{
            box-shadow: 0 3px 4px 0 rgba(68, 68, 68, 0.63);
            border-color: color(primary);
            &:before{
                content: '';
                position: absolute;
                top:0;
                left:0;
                height:100%;
                border-left: 4px solid color(primary);
            }
        }
	}
}

// MODIFIER SLICK TYPE 3
.#{$wf__ns}slick--type3{
	.#{$wf__ns}link{
		&--btn2{
			width:rem(40);
			height:rem(40);
			text-align: center;
		}
	}
}
// BLOCK MODIFIER
.#{$wf__ns}header{
	.#{$wf__ns}header__item--button{
		.#{$wf__ns}link{
			&--btn{
				color:color(white);
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				border-top:none;
				border-bottom-right-radius: 5px;
				border-bottom-left-radius: 5px;
			}
		}
	}
}

.ppg-news {
	.o-list__item {
		&:hover{
			.ppg-link{
				text-decoration: underline;
			}
		}
	}
}
.o-list--inline{
	.#{$wf__ns}link{
		padding-left: rem(24);
		padding-right: rem(24);
		color: color(secondary);
		&.#{$wf__ns}link--rrss{
			display: block;
			padding: rem(5) 0 rem(5) rem(10);
			&:hover, &:focus{
				background-color: color(salmon);
			}
		}
	}
}
.o-list--header{
	.#{$wf__ns}link{
		color: color(secondary);
		&.#{$wf__ns}link--header{
			display: block;
			padding: rem(10) rem(24);
			&:hover, &:focus{
				background-color: color(salmon);
				text-decoration: none;
			}
			&:after{
				content: url($wf__base-path-icons-files + 'icon-flecha-negra--3a-filled.png');
				display: inline-block;
				margin-left: rem(10);
			}
		}
	}
}
.#{$wf__ns}table{
	.#{$wf__ns}link{
		color: color(primary);
		display: table-cell;
		text-align: right;
		&--type02{
			padding: 0;
			text-align: left;
		}
		
	}

}

.#{$wf__ns}footer{
	&--list{
		.#{$wf__ns}link{
			font-size: rem(12);
			color: color(secondary);
		}
	}
	&__link{
		&:first-child{
			.#{$wf__ns}link{
				padding-left: 0;
			}
		}
	}
    &.#{$wf__ns}footer__link{
         border-top: 1px solid color(b-grey-1);
        &:last-child{
            text-align: left;
        }
    }
    .o-list{
        &.o-list--inline{
            border-top: 1px solid color(b-grey-1);
            border-bottom: 1px solid color(b-grey-1);
            .o-list__item{

            }
        }
    }
}
.o-list--type01{
	.ppg-link{
		width:100%;
		padding: rem(10) rem(20);
		position: relative;
		height:rem(40);
		&--red{
			color:color(primary);
		}
		&:hover{
			background: color(salmon);
			&:before{
				content:"";
				width:rem(5);
				height:rem(40);
				position: absolute;
				left:0;
				top:0;
				background-color: color(primary);
			}
		}
		
	}
}


// END BLOCK MODIFIER



@include media-query('md'){
	.o-list--inline{
		.#{$wf__ns}link{
			padding-left: rem(12);
			padding-right: rem(12);
		}
	}
	.ppg-table{
		.ppg-link--type02{
			padding: 0;
		}
	}
	.#{$wf__ns}link{
		&--seeds{
			margin-top: rem(9);
		}
	}
}


@include media-query('sm'){
	.#{$wf__ns}link{
		&__apps{
			width: rem(130);
		}
		&--border{
			width:100%;
			
			&:after{
				display:none;
			}
		}
		&--seeds{
			margin-top: rem(5);
		}
		&--btn-padding{
			padding: rem(12) rem(8);
		}
	}
	.#{$wf__ns}footer{
		&__link.#{$wf__ns}footer__link{
			//border-top: 1px solid color(b-grey-1);
            &:last-child{
                text-align:left;
            }
		}
		&__link{
			&:first-child{
				padding-left: rem(12);
			}
		}
        .o-list{
            &.o-list--inline{
                border: 0 none;
                .o-list__item{
                    border-top: 1px solid color(b-grey-1);
                    &:last-child{
                        padding-top:rem(15);
                        padding-bottom:0;
                        text-align: left;
                    }
                }
            }
        }
        .o-unit{
            padding-left: 0;
            padding-right: 0;
        }
        .ppg-text--lighted-3{
            width:100%;
            text-align:center;
            display: block;
            padding-top:rem(12);
        }
    }
}

@include media-query('xs'){

}
