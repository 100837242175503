/*----------------------------------------------
   # módulo footer
------------------------------------------------*/

.#{$wf__ns}footer{
	background: color(grey-5);
	&__row{
		//border-bottom: 1px solid color(b-grey-1);
		padding: rem(17) 0;
		&:last-child{
			border: 0;
		}
	}
	&__list{
		width: rem(175);
		display:inline-block;
		vertical-align: top;
		text-align: left;
	}
	&__link{
		&:first-child{
			.#{$wf__ns}link{
				padding-left: 0;
			}
		}
	}
	&__copyright{
		width: rem(140);
		text-align: left;
	}
	&__copyright-title.#{$wf__ns}footer__copyright-title{
		font-size: rem(10);
		font-weight: bold;
	}
	//MODIFIER
	&__item{
		&--inline{
			padding-top: rem(10);
			//padding-bottom: 10px;
		}
	}
}



@include media-query('md'){
	.#{$wf__ns}footer{
		padding-left: rem(20);
		padding-right: rem(20);
	}
}


@include media-query('sm'){
	.#{$wf__ns}footer{
		text-align: center;
		&__ue{
			width: rem(63);
		}
		&__copyright.#{$wf__ns}footer__copyright{
			width: rem(110);
			margin-left: rem(-10);
		}
		&__copyright-title.#{$wf__ns}footer__copyright-title{
			font-size: rem(8);
		}
	}
}


@include media-query('xs'){

}
