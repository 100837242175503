///*------------------------------------------------------------
//    #COLORS
//-------------------------------------------------------------*/



$color-map: (
	primary		:	#c33400,
    primary-hover: #9e2c02,
    primary-bg	:	#fcf3f0,
	secondary	:	#333333,
	tertiary	:	#444444,
	grey-1		:	#666666,
	grey-2		:   #e9e9e9,
	grey-3		:   #f8f8f8,
	grey-4  	:   #dddddd,
	grey-5		:	#efefef,
	grey-6 		:   #dbdbdb,
	grey-7 		: 	#e0ded9,
	grey-8 		: 	#999999,
	grey-9 		: 	#bbbbbb,
	grey-10     :   #c1c1c1,
	grey-11 	: 	#f4f4f4,
	grey-12     :   #f9f9f9,
	b-grey-1	:	#c9c6c6,
	b-disabled	:	#f3f3f3,
	white 	  	:   #ffffff,
	white-o		:rgba(255,255,255,0.28),
	black     :   #000000,
    salmon: #fff4f0,
	error		:	#d0011b
);

