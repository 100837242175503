@if $include__units {


    $o-unit-spacing: ($wf__base-spacing / 2)+px;
     /*------------------------------------
         # OBJECTS UNITS
     ------------------------------------*/
    .o-unit{
        font-size:0;
        padding-left: $o-unit-spacing;
        padding-right: $o-unit-spacing;
        [class*=" o-it"],
        [class^="o-it"]{
            display: inline-block;
            font-size:1rem;
            vertical-align: top;
            padding-left: $o-unit-spacing;
            padding-right: $o-unit-spacing;

        }
        // ELEMENT
        &__row{
            margin-left: -$o-unit-spacing;
            margin-right: -$o-unit-spacing;
        }
        &--margin{
            margin-bottom: 90px;
        }
    }
    // MODIFIER
    .o-unit--middle{
        [class*=" o-it"],
        [class^="o-it"] {
            vertical-align: middle;
        }
    }
    .o-unit--base{
        [class*=" o-it"],
        [class^="o-it"] {
            vertical-align: baseline;
        }
    }
    .o-unit--bottom{
        [class*=" o-it"],
        [class^="o-it"] {
            vertical-align: bottom;
        }
    }
    @for $n from 1 through 16{
        @for $z from 1 through 16 {
            .o-it-#{$z}\/#{$n} {
                width: ((100%/$n)*$z);
            }
            @each $mq, $i in $breakpoints {
                @media #{$i}{
                    .o-it-#{$mq}-#{$z}\/#{$n} {
                        width: ((100%/$n)*$z);
                    }
                }
            }
        }
    }
     /*------------------------------------
         # END OBJECTS UNITS
     ------------------------------------*/
 }
@include media-query('md'){
    .o-unit{
        &--margin{
            margin-bottom: 20px;
        }
    }
}
@include media-query('sm'){
    .o-unit{
        &--margin{
            margin-bottom: 10px;
        }
    }
}
 