/*----------------------------------------------
   # MAP LAYER
------------------------------------------------*/

.#{$wf__ns}map {
    position: relative;
    width: 100%;
    min-height: rem(280);
    overflow: hidden;
    &__info {
        width: 40%;
        max-width: rem(248);
        position: absolute;
        left: rem(16);
        top: rem(16);
        background: color(white);
        padding: rem(20) rem(21) rem(17);
        &--title {
            font-family: $wf__font--default;
            font-weight: bold;
            font-size: rem(14);
        }
        &--text {
            font-family: $wf__font--default;
            font-size: rem(16);
            line-height: 1.19;
        }
        &--top {
            padding-right: rem(20);
        }
    }
    &__list {
        margin-top: rem(12);
        &--item {
            padding: rem(8) 0;
            border-top: rem(1) solid color(grey-4);
            &:last-child {
                padding-bottom: 0;
            }
        }
        .ppg-icon--before:before {
            margin-right: rem(10);
        }
    }
    .ppg-fig__example {
        height: 100%;
        min-height: rem(280);
    }
}

@include media-query('md') {
    .#{$wf__ns}map {
        &__info {
            &--title {
            }
            &--text {
            }
            &--top {
            }
        }
        &__list {
            &--item {
                &:last-child {
                }
            }
            .ppg-icon--before:before {
            }
        }
        .ppg-fig__example {
        }
    }
}

@include media-query('sm') {

    .#{$wf__ns}map {
        &__info {
            width: 100%;
            max-width: 88%;
            &--title {
            }
            &--text {
            }
            &--top {
                padding-right: 0;
            }
        }
        &__list {
            &--item {
                &:last-child {
                }
            }
            .ppg-icon--before:before {
            }
        }
        .ppg-fig__example {
        }
    }
}

@include media-query('xs') {

}
