/*------------------------------------
    # ELEMENT HTML
------------------------------------*/

html {
	font-family                 : $wf__base-font-family;
	font-size 					: $wf__base-font-size + px;
	line-height 				: $wf__base-line-height;
	min-height 					: 100%;
	-webkit-text-size-adjust 	: 100%;
	-ms-text-size-adjust 		: 100%;
	position  					: relative;
}

.bck-grey{
	background-color: #f8f8f8;
	margin-top: 0 !important;
	padding-top: 46px;
}


/*------------------------------------
    # END ELEMENT HTML
------------------------------------*/
