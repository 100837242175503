/*----------------------------------------------
   # PILLS
------------------------------------------------*/

.#{$wf__ns}pills{
  width:100%;
  border: 1px solid color(grey-4);
  margin-bottom:rem(20);
  &__img{
    width:rem(70);
    padding:0;
  }
  &__text{
    text-align: left;
  }
  &:hover, &:focus{
    outline: none;
    cursor: pointer;
    border: 1px solid color(primary);
    box-shadow: 0 3px 4px 0 rgba(68, 68, 68, 0.38)
  }
  &:active{
    outline: none;
  }
  &.active{
    border: 1px solid color(primary);
  }
  &__list{
    margin-left: rem(60);
  }
  &__item{}
  &__link{
    text-align: left;
    display: block;
    padding:rem(10) 0;
  }
  &__mrg-txt{
    margin-bottom: rem(10);
  }
  &__mrg-blk{
    margin-bottom: rem(40);
  }
  &__mrg-bttm{
    margin-bottom: rem(60);
  }
  &__type02{
    padding: rem(15);
    border: 1px solid color(grey-4);
    margin-bottom:rem(30);
    &:hover{
      cursor: pointer;
      border: 1px solid color(primary);
      box-shadow: 0 3px 4px 0 rgba(68, 68, 68, 0.38)
    }
    &.active{
      border: 1px solid color(primary);
      border-left-width: 5px;
    }
  }
}
