///*------------------------------------
//    #Z-INDEX-MAP
//--------------------------------------*/

$z-index-map: (
        base:       0,
        offcanvas:  1,
        dropdown:   10,
        tooltip:    15,
        lightbox:   500
);

