/*------------------------------------
    # OBJECTS CARD
------------------------------------*/

$o-card__brd-color: color(b-grey-1);
$o-card__brd-color01: color(grey-4);
$o-card__zindex-base: z-index(base);
$o-card__zindex-offcanvas: z-index(offcanvas);
$o-card__bkg-clr00: color(white);


.o-card{
    background-color: $o-card__bkg-clr00;
    border: 1px solid $o-card__brd-color;
    padding: 10px;
    margin-bottom: 20px;
    // ELEMENT
    &__content{
        padding:9px 10px;
    }
    &__picture{
        img{
            width:100%;
        }
    }
    &__title{
        margin-bottom: 9px;
    }
    &__text{
        max-height: rem(55);
        overflow:hidden;
        margin-bottom: 15px;
    }
    &__link{}
    .o-card{
        &__box{
            $font-size: 14px;
            $line-height: 1.29;
            $lines-to-show: 2;
            
            //@include multiLineEllipsis($lineHeight: 1.9em, $lineCount: 5, $bgColor: white); 
            display: block; /* Fallback for non-webkit */
            display: -webkit-box;
            max-width: 204px;
            height: $font-size*$line-height*$lines-to-show; /* Fallback for non-webkit */
            //margin: 0 auto;
            font-size: $font-size;
            line-height: $line-height;
            -webkit-line-clamp: $lines-to-show;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

    }
    
    //MODIFIER
    &--slick{
        width:316px;
        height: 375px;
        &:hover{
            box-shadow: 0 3px 4px 0 rgba(68, 68, 68, 0.38);
            border: solid 1px #c33400;
        }
        &:active{
            box-shadow: 0 3px 4px 0 rgba(68, 68, 68, 0.38);
            border: solid 1px #c33400;
        }
        &:focus{
            box-shadow: 0 0 11px 0 #c33400;
            border: solid 1px #bc3302;
        }
    }

    &--type2{
        position: relative;
        .o-card__picture{
            position: absolute;
            right:0;
            bottom: 0;
            z-index: $o-card__zindex-base;
        }
        .o-card__content{
            position: relative;
            z-index: $o-card__zindex-offcanvas;
        }
        .o-card__title{}
        .o-card__link{}
        .o-card__text{
            width:70%;
        }

    }

    &--type3{
        border: 1px solid $o-card__brd-color01;
        .o-card__picture{
            width:60%;
            display: inline-block;
            vertical-align: top;
        }
        .o-card__content{
            width: 39%;
            display: inline-block;
            vertical-align: top;
        }
        .o-card__title{
            margin-bottom:10px;
        }
        .o-card__text{
            margin-bottom:30px;
        }

    }
    &--middle{

    }
}

.#{$wf__ns}slick{
    &__link{
        &:focus{
            outline:none;
            .o-card--slick {
                box-shadow: 0 0 11px 0 #c33400;
                border: solid 1px #bc3302;
                outline:none;
            }
        }
    }
}
@include media-query('lg') {
    .o-card {
        &--slick{
            width: 351.1px;
            height: 431.3px;
        }
    }
    .o-layout--dynamic{
        .#{$wf__ns}slick{
            .o-card--slick {
				width: 305px;
            }
        }
    }
}
@include media-query('md') {
    // .o-card {
    //     &--slick{
    //         width: 445px;
    //         height: 470px;
    //     }
        
    // }
    
}
@include media-query('sm') {
    .o-card {
        &--slick{
            width: 300px;
            height: 366.4px;
        }
        &--type3{
            border: 1px solid $o-card__brd-color01;
            .o-card__picture{
                width:100%;
                display: block;
                vertical-align: top;
            }
            .o-card__content{
                width: 100%;
                display: inline-block;
                vertical-align: top;
            }
            .o-card__title{
                margin-bottom:10px;
            }
            .o-card__text{
                margin-bottom:30px;
            }

        }
    }
}


