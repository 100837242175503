/*----------------------------------------------
   # módulo
------------------------------------------------*/

.#{$wf__ns}rrss{
	z-index: z-index(tooltip);
	display: none;
	width: rem(230);
	position: absolute;
	top:rem(50);
	left:rem(10);
	border: 1px solid color(grey-4);
	background-color: color(white);
	border-radius: 5px;
	&:before{
		content: url($wf__base-path-icons-files + 'icon-arrow-down-grey-up.png');
		display: inline-block;
		position: absolute;
		top:rem(-17);
		left:rem(20);
		
	}
	&__item{
		border-bottom: 1px solid color(grey-4);
		&:last-child{
			border-bottom:0;
		}
		.ppg-link.ppg-link--rrss{
			padding:rem(15);
			&:hover{
				text-decoration: none;
			}
		}
	}
	
	// MODIFIER
	
	&.#{$wf__ns}rrss--header{
		width: rem(150);
		left:0;
	}
}
.#{$wf__ns}menu__secondary{
	.o-list--header{
		.o-list__box{
			float: inherit;
			.o-list__item{
				float: inherit;
			}
		}
		.ppg-link.ppg-link--header{
			padding-left:0;
		}
	}
}

@include media-query('md'){

}


@include media-query('sm'){

}

@include media-query('xs'){

}
