/*------------------------------------
    # OBJECT LAYOUT UNO
------------------------------------*/
.o-layout{
    max-width: $wf__base-wrapper+px;
    margin: auto;
    margin-top:20px;
    @extend %clearfix;
    
    // ELEMENTS 
    &__jumbo{
        position: relative;
        width: 100%;
        float: left;
        margin-bottom: 20px;
    }
    &__content{
        position: relative;
        width: 65.8%;
        float: left;
        margin-right:19.5px;
    }
    &__sidebar{
        position: relative;
        width: 32%;
        float: left;
    }

    // MODIFIER

    &--dynamic{
        .o-layout__jumbo{
            width:65%;
        }
        .o-layout__content{}
        .o-layout__sidebar{
            float: none;
            display: inline-block;
        }
    }
}

@include media-query('lg') {
    .o-layout {
        width:100%;
        &__jumbo{
            margin-top: rem(67);
            padding-left: rem(21);
            padding-right: rem(21);
        }
        &--dynamic{
            .o-layout__jumbo{
                padding:0;
            }
            .o-layout__content{}
            .o-layout__sidebar{
                margin-top: rem(67);
            }
        }
    }
}

@include media-query('md'){
    .o-layout{
        //padding:20px;
        padding: 0 rem(10);
        // ELEMENTS
        &__jumbo{
            float: none;
            display: block;
        }
        &__content{
            width:100%;
            float: none;
            display: block;
        }
        &__sidebar{
            width:100%;
            float: none;
            display: block;
        }

        // MODIFIER

        &--dynamic{
            .o-layout__jumbo {
                width: 100%;
                text-align: center;
            }
            .o-layout__sidebar{
                display: block;
            }
        }
    }
}


@include media-query('sm') {
    .o-layout {
        padding:0;
        &__jumbo{
            margin-top: rem(83);
            padding: 0 10px;

        }
        //&__content,&__sidebar{
        //    padding: 0 10px;
        //}
        &__content{
            padding: 0 rem(10);
        }
        &--sm-nomargin{
            margin-top:0;
        }
        &__content--type02,&__sidebar--type02{
            padding: 0;
        }
    }
}
/*------------------------------------
    # END OBJECT LAYOUT UNO
------------------------------------*/
