/*----------------------------------------------
   # módulo
------------------------------------------------*/

.#{$wf__ns}duplicated{
	display:none;
}

@include media-query('md'){
	.#{$wf__ns}duplicated {
		display: block;
		// margin-right: -15px;
		// margin-left: -15px;
	}
}


@include media-query('sm'){

}

@include media-query('xs'){

}
