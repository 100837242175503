/*------------------------------------
    # OBJECT LAYOUT LIST
------------------------------------*/

//- Declare local var

$o-list-borderColor: 1px solid color(grey-4);
$o-list-bck-color: color(white);
$o-list-bck-color01: transparent;

.o-list{
    background-color: $o-list-bck-color;
    border: $o-list-borderColor;
    padding: 0 ($wf__base-spacing / 2)+px 20px;
    margin-bottom: 20px;
    //ELEMENT
    &__name{
        border-bottom: $o-list-borderColor;
        padding-top: 15px;
        padding-bottom: 8px;
        margin-bottom: 15px;
    }
    &__item{
        border-bottom: $o-list-borderColor;
        margin-bottom: 15px;
        &:last-child{
            border-bottom:0;
        }
    }

    // MODIFIER
    &--aside{
        &.o-list {
            padding-bottom:0;
            //ELEMENT
            .o-list__name {
                margin-bottom: 0;
            }
            .o-list__item {
                padding-top:10px;
                padding-bottom:10px;
                margin-bottom: 0;
            }
        }
    }
    &--inline{
        @extend %clearfix;
        .o-list__item{
            float: left;
            border:0;
            padding-top: 10px;
            padding-bottom: 10px;
            &:first-child{
                padding-left: 0;
            }
            &:last-child{
                padding-right:0;
            }
        }
        .o-list{
            &--flush-pd{
                padding:0;
            }
        }
    }
    &--flush{
        padding:0;
        border: 0;
        margin:0;
    }
    &--flush-br{
        border: 0;
    }
    &--flush-pd{
        padding:0;
    }
    &--flush-mg{
        margin:0;
    }
    &--table{
        border: none;
        background-color: transparent;
    }
    &--border{
        border-bottom: 1px solid color(grey-10);
        border-top: 1px solid color(grey-7);
    }
    &--border02{
        border-top: 1px solid color(grey-4);
        border-bottom: 1px solid color(grey-4);
        .o-list__item{
            margin-bottom: 0;
            padding-left: rem(20);
        }
        .o-lit__box{
            float: right;
        }
    }
    &--border03{
        border-bottom: 1px solid color(grey-4);
        margin-bottom: 15px;
        .o-list__item{
            margin-bottom: 0;
            padding-left: rem(20);
        }
        .o-lit__box{
            float: right;
        }
    }
    &--type01{
        &.o-list{
            .o-list__item{
                padding: 0;
            }
            .o-list__name{
                width:100%;
                padding-left: 20px;
            }
            // MODIFIER
            &.o-list--inline{
                .o-list__item{
                    padding-top: 20px;
                    padding-bottom: 20px;
                    
                }
            }
        }
    }
    &--style{
         list-style-type: disc;
         color: color(primary);
    }
    &--margin{
        margin-bottom: rem(60);

    }
    &--rrss{
		padding-bottom: 10px;
		margin-bottom: 20px;
		border-bottom: 1px solid color(grey-4);
		.o-list{
			&__box{
				float: right;
				@extend %clearfix;
			}
			&__item{
				position: relative;
				padding:0;
				margin:0;
			}
		}
    }
    &--header{
		.o-list{
			&__box{
				float: right;
				@extend %clearfix;
			}
			&__item{
				position: relative;
				padding:0;
			}
		}
    }
}

//MODIFIER
.ppg-footer{
    .o-list{
        background-color: $o-list-bck-color01;
        &.o-list--inline{
            .o-list-item{
                border-bottom: 1px solid color(b-grey-1);
                border-top: 1px solid color(b-grey-1);
            }
        }
    }
}

.ppg-news{
    .o-list__item{
        padding-top: 15px;
        padding-bottom: 15px;
        width:100%;
        &:hover{
            background: color(salmon);
        }
    }
}
.ppg-table{
    .o-list__item{
        margin-bottom: 12px;
    }
    .o-list__item2{
        border: 0;
        border-bottom: 1px solid color(grey-10);
        margin: 0;
    }
    .o-list__item3{
        border-bottom: 0;
        margin-bottom: 0;
    }
    .o-list__item4{
        margin-bottom: 0;
    }
}
.ppg-table--type02{
    .o-list__item{
        margin-bottom: 0;
        &:first-child{
            padding-left: 15px;
        }
    }
    
}


.o-list--aside{
    .o-list__name{
        width: 100%;
    }
}

@include media-query('md'){
    .o-list{
        &--type02{
            width: 32.9%;
            display: inline-block;
            vertical-align: top;
        }
        &--type03{
        width: 33%;
        display: inline-block;
        }
        &--type04{
            width: 64%;
            display: inline-block;
            vertical-align: top;
            margin-left: 20px;
        }
        &--margin{
            margin-bottom: rem(20);

        }
    }
    

}

@include media-query('sm'){
    .o-list{
        &--type02{
            width: 100%;
            display: block;
            vertical-align: top;
        }
        &--type03,&--type04{
            width: 100%;
            margin-left: 0;
        }
        &--type01{
            &.o-list{
                // MODIFIER
                &.o-list--inline{
                    .o-list__item{
                        &--type02{
                            padding-top: 0;
                            padding-bottom: 0;
                            border-bottom: 1px solid color(grey-4);
                            &:last-child{
                                border-bottom: 0;
                            }
                        }
                        
                        
                    }
                }
            }
     }
        
    }
    
    .o-list--border02{
        .o-list__item{
            padding-left: 9px;
        }
        .o-lit__box{
            float: inherit;
        }
    }
    .o-list--border03{
        .o-list__item{
            padding-left: 3px;
        }
        .o-lit__box{
            float: inherit;
        }
    }
    

}

/*------------------------------------
    # END OBJECT LIST
------------------------------------*/
