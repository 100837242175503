/*----------------------------------------------
   # PPG-DATE
------------------------------------------------*/

// LOCAL VAR

$border-color: color(b-grey-1);
$font-color: color(primary);
$background-color: color(grey-5);

.#{$wf__ns}date{
    text-align: center;
    border: 1px solid $border-color;
    color: $font-color;
    font-weight:bold;
    padding:rem(10);
    background-color: $background-color;
    &__month{
        display: block;
    }
}

@include media-query('md'){

}


@include media-query('sm'){

}

@include media-query('xs'){

}
