/*----------------------------------------------
   # Estilos para el body
------------------------------------------------*/

.body{
	&--type02{
		background-color: color(white);
	}
}

@include media-query('md'){

}


@include media-query('sm'){

}

@include media-query('xs'){

}
