/*----------------------------------------------
   # PPG__TABS
------------------------------------------------*/

// LOCAL VAR
$tabs__bck: color(white);
$tabs__bck-active: color(primary);
$tabs__bck-hover: color(salmon);
$tabs__font-color: color(secondary);
$tabs__font-color-active: color(white);
$tabs__brd-color: color(b-grey-1);


.#{$wf__ns}tabs{
    padding: 0;
    margin: 0;
    border: 1px solid $tabs__brd-color;
    border-radius: 0;
    margin-bottom: rem(20);
    .#{$wf__ns}tabs__list{
        padding:0;
        margin:0;
        border: 0;
        border-radius:0;
        @extend %clearfix;
        .#{$wf__ns}tabs__item{
            margin:0;
            padding:0;
            border:0;
            border-radius:0;
            width:50%;
            background-color: transparent;

            // modifier
            &.ui-tabs-active {
                .#{$wf__ns}tabs__link{
                    position: relative;
                    background-color: $tabs__bck-active;
                    color: $tabs__font-color-active;
                    &:after{
                        content: '';
                        position: absolute;
                        width:10px;
                        height:10px;
                        background-color: $tabs__bck-active;
                        bottom: rem(-5);
                        left:50%;
                        -webkit-transform: translate(-50%,0) rotate(45deg);
                        -moz-transform: translate(-50%,0) rotate(45deg);
                        -ms-transform: translate(-50%,0) rotate(45deg);
                        -o-transform: translate(-50%,0) rotate(45deg);
                        transform: translate(-50%,0) rotate(45deg);
                    }
                }
            }
        }
        .#{$wf__ns}tabs__link{
            display: block;
            width:100%;
            text-align: center;
            background-color: $tabs__bck;
            color: $tabs__font-color;
            border-bottom: 1px solid transparent;
            &:hover{
                background-color: $tabs__bck-hover;
            }
        }
    }
    .#{$wf__ns}tabs__content{
        background-color: $tabs__font-color-active;
    }
    &__img{
        border-radius:50%;
        border: 3px solid transparent;
    }
    
    //MODIFIER
    .ppg-link{
        &:hover{
            text-decoration:none;
            .ppg-tabs__img{
                border: 3px solid color(primary);
            }
            .ppg-tabs__caption{
                color: color(primary);
            }
        }
    }
    &--type2{
        &.#{$wf__ns}tabs{
            border:0;
            .#{$wf__ns}tabs__list{
                position: relative;
                bottom: -1px;
                padding:0;
                margin:0;
                border: 0;
                border-radius:0;
                @extend %clearfix;
                .#{$wf__ns}tabs__item{
                    width:auto;
                    margin-right:rem(20);
                    border:1px solid color(grey-9);
                    border-top-left-radius:5px;
                    border-top-right-radius:5px;
                    background-color: transparent;

                    // modifier
                    &.ui-tabs-active {
                        border-bottom-color: color(white);
                        .#{$wf__ns}tabs__link{
                            position: relative;
                            background-color: color(white);
                            color: color(secondary);
                            border-bottom:0;
                            &:after{
                                content: none;

                            }
                        }
                    }
                }
                .#{$wf__ns}tabs__link{
                    border-top-left-radius:5px;
                    border-top-right-radius:5px;
                    background-color: #f4f4f4;
                    color: color(secondary);
                    border-bottom: 1px solid color(white);
                    &:hover{
                        background-color: $tabs__bck-hover;
                    }
                }
            }
            .#{$wf__ns}tabs__content{
                border-top:1px solid color(grey-9);
                padding-left:0;
                padding-right:0;
                background-color: $tabs__font-color-active;
            }
            .#{$wf__ns}tabs__text{
                margin-bottom: rem(20);
            }
        }
    }
}

@include media-query('md'){

}


@include media-query('sm'){
    .#{$wf__ns}tabs{
        display: none; // ENTREGA
        &--type2{
            &.#{$wf__ns}tabs{
                display: block;
                .#{$wf__ns}tabs__list{
                    .#{$wf__ns}tabs__item{
                        width:49%;
                        margin-right:.5%;
                    }
                    .#{$wf__ns}tabs__link{
                        padding-right:rem(5);
                        padding-left:rem(5);
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
}
@include media-query('xs'){

}
