/*----------------------------------------------
   # módulo datepicker: sobreescribe los estilos de jquery ui para el componente  datepicker
------------------------------------------------*/

.ui-datepicker{
	background: color(white);
	width: rem(300);
	border: 1px solid color(grey-4);
	padding:rem(15);
	box-sizing: border-box;
	box-shadow: 0 2px 4px 0 #9b9b9b;
	margin-top:1px;
	.ui-state-default{
		color: color(secondary);
		border:1px solid transparent;
		border-radius: 4px;
		height:100%;
		display:block;
		padding:rem(10);
		&:hover{
			border: 1px solid color(grey-9);
			background: color(salmon);
			text-decoration: none;
		}
	}
	.ui-state-active{
		background: color(primary);
		color: color(white);
		&.ui-state-hover{
			background: color(primary);
			color: color(white);
		}
	}
}
.ui-datepicker-header{
	position:relative;
	text-align: center;
	padding-bottom:rem(10);
	.ui-datepicker-month{
		font-family:$wf__font--default;
		font-weight: bold;
	}
	.ui-datepicker-year{
		font-family:$wf__font--default;
		font-weight: bold;
	}
}
.ui-datepicker-calendar{
	width:100%;
	span{
		font-weight: bold;
		//padding:10px;
	}
	tbody{
		text-align: center;
		color: color(secondary);
		td{
			//padding:10px;
		}
	}
}

.ui-datepicker-prev{
	text-indent:-999999px;
	position: absolute;
	width: rem(11);
	height:rem(11);
	left:rem(2);
	top:rem(5);
	span{
		width:rem(8);
		height: rem(11);
		display:block;
		position:absolute;
		background-image: url($wf__base-path-icons + "spritesheet.png");
		background-position: -341px -67px;
		text-indent: -99999px;
		overflow:hidden;
		background-repeat:no-repeat;
		-webkit-transform: rotate(180deg);
		-moz-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		-o-transform: rotate(180deg);
		transform: rotate(180deg);
	}
	&.ui-state-disabled{
		span{
			background-position: -23px -67px;
			width:rem(8);
			height:rem(11);
			-webkit-transform: rotate(180deg);
			-moz-transform: rotate(180deg);
			-ms-transform: rotate(180deg);
			-o-transform: rotate(180deg);
			transform: rotate(180deg);
		}
	}
}

.ui-datepicker-next{
	text-indent:-999999px;
	position: absolute;
	right:rem(2);
	top:rem(5);
	width:rem(11);
	height:rem(11);
	span{
		width:rem(8);
		height: rem(11);
		display:block;
		position:absolute;
		//left:50%;
		top:50%;
		-webkit-transform: translate(0,-50%);
		-moz-transform: translate(0,-50%);
		-ms-transform: translate(0,-50%);
		-o-transform: translate(0,-50%);
		transform: translate(0,-50%);
		background-image: url($wf__base-path-icons + "spritesheet.png");
		background-position: -341px -67px;
		text-indent: -99999px;
		overflow:hidden;
		background-repeat:no-repeat;
	}
}






@include media-query('md'){

}


@include media-query('sm'){

}

@include media-query('xs'){

}
