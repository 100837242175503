/*----------------------------------------------
   # HEADINGS: estilización de los encabezados
------------------------------------------------*/

.#{$wf__ns}heading{
    font-family: $wf__font--default;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	font-size: rem(32);
	color: color(primary);

	// MODIFIER

	&--bold{
		font-weight:bold;
	}
	&--white{
		color: color(white);
	}
	&--black{
		color: color(secondary);
	}
	&--wind{
		margin-top: rem(20);
		margin-bottom: rem(30);
	}
	&--brd{
		padding-top: rem(20);
		padding-bottom: rem(20);
		margin-bottom:rem(10);
		border-bottom: 1px solid color(grey-4);
	}
	&--wind02{
		margin-bottom: rem(21);
	}
	&--type02{
		font-size: rem(18);
	} 
}

.#{$wf__ns}main__search{
	.#{$wf__ns}heading{
		margin-bottom: rem(20);
	}
}
.#{$wf__ns}table{
	.#{$wf__ns}heading{
		color: color(black);
		font-size: rem(16);
		font-weight: bold;
		line-height: 1.38;
		text-align: left;
		&--type02{
			border-bottom: none;
			color: color(secondary);
			padding: rem(10) 0;
			margin: 0;
		}
	}
}
.#{$wf__ns}table--type02{
	.#{$wf__ns}heading{
		font-size: rem(12);
		color: color(secondary);
		display: inline-block;
		line-height: 1.83;
		&--type02{
			padding: 0;
			padding-left: rem(30);
		}
		&--type03{
			display: block;
			padding-bottom: rem(10);
			margin-bottom: rem(15);
			font-size: rem(16);
			border-bottom: 1px solid color(grey-4);
		}
		&--type04{
			color: color(b-grey-1);
			
		}
	}

}


@include media-query('lg'){
	.#{$wf__ns}main__search{
		.#{$wf__ns}heading{
			margin-bottom: rem(16);
		}
	}
	

}


@include media-query('sm'){
	.#{$wf__ns}main__search{
		.#{$wf__ns}heading{
			font-size: rem(28);
			max-width: rem(282);
			margin: 0 auto;
			margin-bottom: rem(17);
			margin-top: rem(-23);

		}
	}
}

@include media-query('sm'){
	.#{$wf__ns}heading{
		font-size: rem(20);
		&--wind02{
			margin-bottom: rem(12.5);
		}
		&--type02{
			font-size: rem(16);
		} 
	}
}
