/*------------------------------------
    # OBJECTS AVATAR
------------------------------------*/

$o-avatar-spacing-middle: ($wf__base-spacing / 3)+px;

.o-avatar{
    display: table;
    &__item{
        padding-left: $o-avatar-spacing-middle;
        padding-right: $o-avatar-spacing-middle;
        vertical-align: top;
        display: table-cell;
        &--type02{
            width: 50%;
        }
        &--flush-pd{
            padding:0;
        }
    }
    // MODIFIER

    &--middle{
        .o-avatar__item{
            vertical-align: middle;
        }

    }
    &--base{
        .o-avatar__item {
            vertical-align: baseline;
        }
    }
}
@include media-query('sm'){
    .o-avatar{
        &--type02{
            padding: 12px 12px 12px 0;
            .o-avatar__item{
                display: block;
                &:first-child{
                    vertical-align: top;
                    display: table-cell;
                }
            }
            .o-avatar__item--type02{
                width: 100%;
                margin-bottom:5px;
            }
        }
    }
}

/*------------------------------------
    # END OBJECTS AVATAR
------------------------------------*/


