/*----------------------------------------------
   # módulo
------------------------------------------------*/

.#{$wf__ns}news{

}

@include media-query('md'){

}


@include media-query('sm'){

}

@include media-query('xs'){

}
