@if $include__trumps--line-height {
    /*------------------------------------
       # TRUMPS FONTS SIZE
    -----------------------------------*/
    @each $number, $i in $wf__trumps-lh {
        .t-lh-#{$number}{
            line-height: ($number+0%) !important;
        }
    }

    /*------------------------------------
       # END TRUMPS FONTS SIZE
    -----------------------------------*/
}// end if


