/*----------------------------------------------
   # SEARCH: input search
------------------------------------------------*/


.#{$wf__ns}search{
	position: relative;
	&__input{
		width:100%;
		padding: rem(13) rem(30) rem(13) rem(15);
		border-radius: 3px;
		border: 1px solid color(grey-6);
		position: relative;
		&:focus{
			border: 1px solid color(tertiary);
			outline:none;
		}
	}
	&__button{
		position:absolute;
		top:0;
		right:0;
		width: rem(45);
		height:rem(45);
	}
	
	//MODIFIER
	
	&--splitted{
		padding:rem(15);
		border:1px solid color(grey-4);
		background-color: color(grey-11);
		margin-top:rem(15);
		margin-bottom:rem(30);
		.ppg-search__input{
			display:inline-block;
			width: calc(100% - 140px);
			margin-right: rem(10);
			vertical-align:top;
		}
		.ppg-search__button{
			position: relative;
			display: inline-block;
			vertical-align:top;
			width: rem(120);
		}
	}
	
}


.#{$wf__ns}main{
	&__search{
		height:rem(325);
		background-image: url($wf__base-path-contents + "demo.jpg");
		position: relative;
		background-size: cover;
		.ppg-search__input{
			padding-right:rem(50);
		}
	}
	&__overlay{
		background-color: rgba(51,51,51,0.82);
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
	}
	&__search-home{
		width: 100%;
		text-align: center;
		position: absolute;
		top: rem(50);
		
	}
	&__box{
		position: relative;
		width: rem(800);
		margin:auto;
	}
}


@include media-query('lg'){
	.#{$wf__ns}main{
		&__search-home{
			width:100%;
		}
		&__search{
			height:rem(246.8);
		}
		&__box{
			width: 74%;
			margin: 0 auto;
		}
		
	}
}

@include media-query('md'){
	.#{$wf__ns}search{
		&__input{
			width: 100%;
		}
		&--splitted{
			margin-left:rem(15);
			margin-right:rem(15);
		}
	}

}

@include media-query('sm'){
	.#{$wf__ns}main{
		&__search{
			height:rem(216);
		}
		&__box{
			width: 90%;
		}
	}
	.#{$wf__ns}search{
		&--splitted{
			.ppg-search__input{
				display:block;
				width: 100%;
				margin-bottom: rem(10);
				margin-right:0;
			}
			.ppg-search__button{
				display: block;
				width: 100%;
			}
		}
	}
}

