/*----------------------------------------------
   # PPG-TABLE
------------------------------------------------*/

.#{$wf__ns}table{
    margin-bottom: rem(15);
    &__head{
        display: block;
        padding-top: rem(14);
        padding-bottom: rem(10);
    }
    &__body{
        border-top: 1px solid color(grey-7);
    }
    &__col{
        vertical-align: middle;
    }
    &__row{
        //padding-top: rem(11);
        //padding-bottom: rem(22);
        display: block;
    }
    &__row > td{
        padding-top:rem(11);
        padding-bottom: rem(22);
    }
    &__text{
        width: 50%;
        &--type02{
            width: 25%;
            text-align: center;
        }
    }
    &__box{
        border-bottom: 1px solid color(grey-7);
        border-top: 1px solid color(grey-7);
        padding: rem(0) rem(14);
        &--type02{
            border-bottom: 1px solid color(grey-10);
            padding: rem(0) rem(14);
        }
    }
    &__list{
        @extend %clearfix;
        &--type02{
            padding-top: rem(15);
            padding-bottom: rem(15);
            //margin-bottom: 15px;
            &:nth-child(odd){
                background-color: color(grey-3);
            }
            [class*=" o-it"],
            [class^="o-it"] {
                margin-bottom: rem(10);
            }
            &:hover{
                background-color: color(salmon);
                cursor: pointer;
            }
        }
    }
    &__title{
        padding-left: rem(10);
        padding-right: rem(12);
        color: color(b-grey-1);
    }
    &__content{
        &--type02{
            background-color: color(grey-12);
            border: 1px solid color(grey-4);
            padding: rem(21) 0 rem(12) rem(5);
        }
    }
}

@include media-query('md'){
    .#{$wf__ns}table{
        &__content{
            display: block;
        }
    }
}
@include media-query('sm'){
    .#{$wf__ns}table{
        &__head{
            cursor: pointer;
        }
         .#{$wf__ns}table{
            &__row{
                padding-bottom: 0;
                &--type02{
                    padding: 0;
                }
            }
         }
        
        &__text,&__text--type02{
            width: 100%;
            display: block;
            padding-bottom: rem(11);
            text-align: left;
        }
        &__content{
            display: none;
            &.open{
                display: block;
            }
        }
        &--type02{
            .#{$wf__ns}table__list--type02{
                padding-bottom:0;
                .ppg-heading--type03{
                    margin-bottom:0;
                    border:0;
                }
                [class*=" o-it"],
                [class^="o-it"]{
                    margin-bottom:0;
                }
                &.open{
                    padding-bottom:rem(15);
                    .ppg-heading--type03{
                        margin-bottom:rem(15);
                        border-bottom:1px solid color(grey-4);
                    }
                    [class*=" o-it"],
                    [class^="o-it"]{
                        margin-bottom:rem(10);
                    }
                }
            }
        }
    }

}
