 /*------------------------------------
     # ELEMENT BODY
 ------------------------------------*/
body {
	position                    : relative;
	font-family                 : $wf__font--default;
	color                       : color($wf__base-color);
	background-color            : color($wf__base-background-document);
}

 /*------------------------------------
    # END ELEMENT BODY
------------------------------------*/
