.ppg-menu{
  display: block;
  &__content{
    display:none;
  }

  //&__it{
  //  width: auto;
  //  height: auto;
  //}
  .content-active{
    display: block;
  }
}
@include media-query('xs'){
  .ppg-menu{
    display: none;
    &__it:hover {
		.ppg-menu__content {
		
		}
    }
  }
}
@include media-query('sm'){
  .ppg-menu{
    display: none;
    &__it{
		.ppg-menu__content{
			width:auto !important;
			display: none ;
		}
      &:hover {
		  .ppg-menu__content{
			  width:auto !important;
			  display: none;
		  }
      }
    }
  }
}
@include media-query('md'){
  .ppg-menu{
    &__it &__content{
      display:none;
    }
    &__content{
      display:none;
    }
    &__it:hover &__content{
      display:block;
    }
    &__it:hover &__link{
      color: $color02;
      background-color:$color01;
      .ppg-icon__crossW-3a:after{
        width: 11px;
        height: 7px;
        background-position: -5px -96px;
      }
    }
  }
}
@include media-query('lg'){
  .ppg-menu{
    &__it &__content{
      display:none;
    }
    &__content{
      display:none;
    }
    &__it:hover &__content{
      display:block;
    }
    &__it:hover &__link{
      color: $color02;
      background-color:$color01;
      .ppg-icon__crossW-3a:after{
        width: 11px;
        height: 7px;
        background-position: -5px -96px;
      }
    }
  }
}
@include media-query('xl'){
  .ppg-menu{
    &__it &__content{
      display:none;
    }
    &__content{
      display:none;
    }
    &__it:hover &__content{
      display:block;
    }
    &__it:hover &__link{
      color: $color02;
      background-color:$color01;
      .ppg-icon__crossW-3a:after{
        width: 11px;
        height: 7px;
        background-position: -5px -96px;
      }
    }
  }
}

