 /*----------------------------------------------------------------
     # ELEMENT HEADINGS
 ------------------------------------------------------------------*/


h1,
h2,
h3,
h4,
h5,
h6 {
	margin 			: 0;
	font-family 	: $wf__font--default;
	font-weight 	: normal;
	color 			: color($wf__base-color);
	line-height  	: $wf__base-line-height;
}

h1, %h1	{ font-size: rem($wf__h1)}
h2, %h2	{ font-size: rem($wf__h2)}
h3, %h3	{ font-size: rem($wf__h3)}
h4, %h4	{ font-size: rem($wf__h4)}
h5, %h5	{ font-size: rem($wf__h5)}
h6, %h6	{ font-size: rem($wf__h6)}

 /*----------------------------------------------------------------
     # END ELEMENT HEADINGS
 ------------------------------------------------------------------*/
