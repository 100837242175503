/*----------------------------------------------
   # SLICK: estilización del carrusel
------------------------------------------------*/
// LOCAL VAR


$background-color : color(grey-2);


// IMPORT
@import './bower_components/slick-carousel/slick/slick.scss';
@import './bower_components/slick-carousel/slick/slick-theme.scss';

.#{$wf__ns}slick{
    width: 100%;

    //MODIFIER
    &--type1{
        .#{$wf__ns}slick{
            &__fig{}
            &__img{}
            &__item{
                height: rem(375);
                display: inline-block;
                margin-right: rem(19.5);
            }
            &__link{
                &:hover, &:focus{
                    text-decoration: none;
                }
            }
        }
        // override style slick
        .slick-prev, .slick-next{
            width:40px;
            height:60px;
            z-index:z-index(offcanvas);
            &:before{
                display: inline-block;
                padding: rem(16) rem(12);
                background-color: color(primary);
                content:url($wf__base-path-icons-files + 'icon-flecha-blanca--2b-light.png');
                border: 1px solid transparent;
            }
            &.slick-disabled{
                &:before{
                    background-color: color(b-grey-1);
                }
                &:focus {
                    &:before {
                        box-shadow: none;
                        border:none;
                    }
                }
            }
            &:focus{
                &:before{
                    box-shadow: 0 0 5px 0 #c33400;
                    border: solid 1px #bc3302;
                }
            }
        }
        .slick-prev{
            left: rem(-30);
            &:before{
                content:url($wf__base-path-icons-files + 'icon-flecha-blanca--2b-lightb.png');
            }
        }
        .slick-next{
            right: rem(-30);
        }
        .slick-disabled{
            &:hover{
                &:before{
                    box-shadow:none;
                }
            }
        }
        
    }
    &--type2{
        padding-top: rem(50);
        padding-bottom: rem(50);
        background-color: color(grey-2);
        .#{$wf__ns}slick__box{
            width: rem(850);
            margin: auto;
        }
        .#{$wf__ns}slick{
            &__item{
                margin-right: rem(25);
                margin-left: rem(5);
            }
            &__link{
                &:hover, &:focus{
                    text-decoration: none;
                }
            }
        }
        // override style slick
        .slick-prev, .slick-next{
            z-index:z-index(offcanvas);
            &:before{
                display: inline-block;
                content:url($wf__base-path-icons-files + 'icon-flecha-roja--2a-light.png');
                border: 1px solid transparent;
            }
            &.slick-disabled{
                &:before{
                    background-color: color(b-grey-1);
                }
                &:focus {
                    &:before {
                        box-shadow: none;
                        border:none;
                    }
                }
            }
        }
        .slick-prev{
            left: rem(-30);
            &:before{
                transform: rotate(180deg);
            }
        }
        .slick-next{
            right: rem(-30);
        }
        .slick-disabled{
            &:hover{
                &:before{
                    box-shadow:none;
                }
            }
        }
    }
    &--type3{
        padding: rem(20) rem(20);
        border-bottom: 1px solid color(grey-4);
        border-top: 1px solid color(grey-4);

        // override style slick
        .slick-prev, .slick-next{
            z-index:z-index(offcanvas);
            &:before{
                display: inline-block;
                content:url($wf__base-path-icons-files + 'icon-flecha-roja--2a.png');
                border: 1px solid transparent;
            }
            &.slick-disabled{
                &:before{

                }
                &:focus {
                    &:before {
                        box-shadow: none;
                        border:none;
                    }
                }
            }
        }
        .slick-prev{
            left: rem(-25);
            &:before{
                content:url($wf__base-path-icons-files + 'icon-flecha-roja--2ab.png');
            }
        }
        .slick-next{
            right: rem(-25);
        }
        .slick-disabled{
            &:hover{
                &:before{
                    box-shadow:none;
                }
            }
        }
    }
}

.o-layout--dynamic{
    .o-card--slick{
        width: rem(305);
    }
	.#{$wf__ns}slick{
		@extend %clearfix;
		//MODIFIER
		&--type1{
			.#{$wf__ns}slick{
				&__item{
					float: left;
					margin-right: rem(33);
					&:last-child{
						margin-right:0;
					}
				}
			}
		}
	}
}


@include media-query('lg'){
    .#{$wf__ns}slick{
        &--type1{
            .slick-next{
                right: rem(-8);
            }
            .slick-prev{
                left:rem(-18);
            }
            .#{$wf__ns}slick{
                &__item{
                    height: auto;
                }
            }
        }
    }
	.o-layout--dynamic{
		.#{$wf__ns}slick{
			//MODIFIER
			&--type1{
				.#{$wf__ns}slick{
					&__item{
						margin-right: rem(33);
					}
				}
			}
		}
	}
}
@include media-query('md'){
    .#{$wf__ns}slick{
        &--type2{
            padding-top: rem(35);
            padding-bottom: rem(35);
            .#{$wf__ns}slick__box{
                width: rem(700);
                margin: auto;
            }
        }
    }
	.o-layout--dynamic{
		.#{$wf__ns}slick{
			width:auto;
			margin:auto;
			display: inline-block;
			text-align: left;
			&--type1{
				.#{$wf__ns}slick{
					&__item{
						margin-right: rem(33);
					}
				}
			}
		}
	}
}
@include media-query('sm'){
    .#{$wf__ns}slick{
        &--type2{
            .#{$wf__ns}slick__box{
                width:100%;
            }
        }
    }
    .o-layout--dynamic{
        .#{$wf__ns}slick{
            &--type1{
                .#{$wf__ns}slick{
                    &__item{
                        margin: 0 auto;
                        display: block;
                        float: none;
                    }
                }
            }
        }
    }
}
@include media-query('xs'){

}
