/*----------------------------------------------
   # BUTTONS: estilización de los botones
------------------------------------------------*/

.#{$wf__ns}button{
    border-radius: rem(3);
    background-color: color(primary);
    width: 100%;
    border: 1px solid transparent;
    padding: rem(13) rem(5);
    font-family:$wf__font--default;
    font-weight: bold;
    color: color(white);
    text-align: center;
    font-size: rem(14);
    cursor: pointer;
    &:focus{
        background-color: color(primary);
        border: 1px solid color(white);
        box-shadow: 0 0 0 2px color(primary);
        text-decoration: none;
        outline:none;
    }
    &--type02{
        background-color: transparent;
        color: color(primary);
        border:1px solid transparent;
        &:focus{
            background-color: transparent;
            outline:none;
            box-shadow: 0 0 5px 0 #c33400;
            border: solid 1px #bc3302;
        }
    }
    &--type03{
        background-color: transparent;
        color: color(secondary);
        text-align: right;
    }
    &--type04{
        background-color: color(white);
        color: color(primary);
        border: 1px solid color(primary);
        border-radius: 5px;
        &:hover{
            background-color: color(salmon);
        }
        &:focus{
            background-color: color(white);
            color: color(primary);
            border: 1px solid color(primary);
            box-shadow: 0 0 0 2px color(primary);
        }
    }
    &--nopd{
        padding-top: rem(6.5);
        padding-bottom: rem(6.5);
    }
   
}

.#{$wf__ns}header{
    .btn-default{
        border: 0;
        box-shadow: none;
        padding-right: rem(18);
        padding-left: rem(5);
        .filter-option.filter-option{
            text-align: right;
        }
        .bs-caret{
            .caret{
                right: rem(5);
            }
        }
        &:hover{
            background: color(white);
        }
        &:focus{
            box-shadow: 0 0 5px 0 color(primary);
            border: solid 1px #bc3302;
            border-radius:1px;
            background: color(white);
            outline:none;
        }
        span{
            text-align: center !important;
        }
    }
    &__list{
        span{
            text-align: center !important;
            .ui-selectmenu-text{
                margin-right:0;
                padding-right: rem(20);
            }
        }
    }
    &__search{
        .ppg-button{
            //position:absolute;
            //top:0;
            //right:0;
            //width: rem(45);
            //height:rem(45);
        }
    }
}

.#{$wf__ns}form{
    &--button{
        .ppg-button{
            width:60%;
            margin-left:rem(20);
        }
    }
}


.#{$wf__ns}main__search{
    .ppg-button{
        width: rem(46);
        height: rem(46);
        position: absolute;
        top:0;
        right:0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}


@include media-query('md'){

}


@include media-query('sm'){

}

@include media-query('xs'){

}
