/*------------------------------------
    # OBJECT BOX
------------------------------------*/

//- Declare local var
$o-box__bdr: color(grey-4);
$o-box__bck: color(white);

.o-box{
    width:100%;
    border: 1px solid $o-box__bdr;
    background-color: $o-box__bck;
    padding: 20px 15px;
    margin-bottom: 20px;
    &--type01{
        border:0;
        padding: 20px 0;
        margin-bottom:0;
    }
    &--flush-pd{
        padding:0;
    }

}


@include media-query('md'){
    .o-box{
        &--type02{
            width: 32.9%;
            display: inline-block;
            vertical-align: top;
        }
        &__img{
            width: 50%;
            display: block;
            padding-bottom: 5px;
            margin: 0 auto;
        }
    }
}
@include media-query('sm'){
    .o-box{
        &--type02{
            width: 100%;
            display: block;
        }
    }
}
/*------------------------------------
    # END OBJECT BOX
------------------------------------*/
