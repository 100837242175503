/*----------------------------------------------
   # módulo
------------------------------------------------*/

.#{$wf__ns}form{
	padding-top: rem(25);
	padding-bottom: rem(25);
	background-color: rgba(227, 227, 227, 0.82);
	.#{$wf__ns}form{
		&__group{
			&--nopad{
				padding-left: 0;
			}
		}
	}
	&__title{
		padding-left:rem(7.5);
		display:inline-block;
		&:after{
			content:"";
			width:rem(25);
			height:rem(25);
			background: url($wf__base-path-icons  +"svg/icon-ayuda.svg");
			margin-left: rem(10);
			display:inline-block;
			vertical-align: text-bottom;
		}
	}
	&__group{
		position: relative;
		margin-left:rem(-7.5);
		margin-right:rem(-7.5);
		//margin-bottom: 10px;
		&:after{
			position: absolute;
			right:rem(15);
			top: 50%;
			transform: translate(0,-50%);
		}
		&--datepicker{
			display:inline-block;
			
		}
		&--short{
			&:after{
				//right:40px;
			}
		}
		&--nolabel{
			padding-top:rem(30);
		}
		
	}
	&__title{
		font-family: $wf__font--default	;
		font-weight: bold;
		font-size: rem(20);
		color: color(secondary);
		margin-bottom: rem(15);
	}
	&__row{
		padding-bottom: rem(20);
	}
	&__content{
		display: none;
	}
	&__fieldset{
		margin-bottom: rem(20);
		padding-bottom: rem(20);
		border-bottom: 1px solid color(b-grey-1);
		&:first-child{
			margin-top:0;
		}
		&:last-child{
			padding-bottom:0;
			border-bottom: none;
		}
		&--flush-br{
			border-bottom:none;
		}
		&--submit{
			padding-left:rem(15);
			padding-right:rem(15);
			.#{$wf__ns}link{
				&.#{$wf__ns}icon__crossR-3a{
					&.open{
						&:after{
							transform: rotate(180deg);
						}
					}
				}
			}
		}
		@extend %clearfix;
	}
	&__legend{
		font-family: $wf__font--default;
		font-size: rem(18);
		color: color(primary);
		display:block;
		padding-left: rem(7.5);
	}
	&__label{
		display:block;
		font-family: $wf__font--default;
		color: color(secondary);
		font-size: rem(14);
		font-weight: bold;
		padding: rem(5) 0;
		&--left{
			display:inline-block;
			font-size: rem(14);
		}
		&--right{
			font-size:rem(14);
			position: absolute;
			bottom: 0;
			left: rem(90);
			transform: translate(0,-50%);
		}
		&--abs{
			position: absolute;
			top:rem(-25);
			left:0;
		}
	}
	&__optionBox{
		display:inline-block;
		border: 1px solid color(grey-9);
		border-radius:3px;
		background-color: color(grey-11);
		width:100%;
		.ppg-form__input--radio{
			&:checked + .ppg-form__checkbox{
				background-color: color(white);
				border:1px solid color(grey-9);
				font-weight: bold;
			}
		}
	}
	&__checkbox{
		display:inline-block;
		height:rem(40);
		border-radius:3px;
		border:1px solid transparent;
		padding: rem(7) rem(9) rem(13) rem(9);
		position: relative;
		width:49.2%;
		font-size:rem(14);
		&:hover{
			background-color: #bbbbbb;
		}
		text-align: center;
	}
	&__input{
		height:rem(40);
		width:100%;
		border-radius:3px;
		border:1px solid color(grey-9);
		padding: rem(10);
		position: relative;
		//Modifier
		&--disabled{
			background-color: color(b-disabled);
		}
		&--error{
			border: 1px solid color(error);
			&:after{
			
			}
		}
		&--nolabel{
			margin-top: rem(30);
		}
		&--number{
			display:inline-block;
			width: rem(65);
		}
		&--date{
			display: inline-block;
			width: rem(145);
			padding-right: rem(30);
			font-size: rem(14);
		}
		&:focus{
			outline:none;
			border: 1px solid color(tertiary);
		}
	}
	&__submitbox{
		text-align: right;
		width:rem(300);
		float:right;
	}
}

.ppg-form--options{
	.ppg-form__group{
		margin:rem(20) 0;
		width: rem(320);
		.ppg-form__optionBox{
			width:100%;
		}
	}
}

@include media-query('md'){
	
	.#{$wf__ns}form{
		&__group{
			margin-bottom: rem(15);
			&--datepicker{
				&:after{
					right:rem(10);
				}
			}
		}
		&__optionBox{
			margin-bottom: rem(10);
		}
		&__input{
			&--date{
				width:rem(130);
			}
		}
		&__label{
			&--abs{
				top:rem(-35);
			}
		}
	}
}

@include media-query('sm'){
	.#{$wf__ns}form{
		.#{$wf__ns}form{
			&__group{
				&--nopad{
					padding-left: rem(8);
					padding-right: rem(8);
				}
			}
		}
		&__title{
			font-size: rem(17);
		}
		&__group{
			margin-bottom: rem(15);
			&--datepicker{
				vertical-align:bottom;
				
				.ppg-form__input--nolabel{
					margin-top:0;
				}
				&-to{
					padding-top:rem(30);
				}
				&:after{
					//top:78%;
				}
				.#{$wf__ns}form__label{
					display: block;
				}
			}
			&--flush{
				margin-left:rem(-15);
				margin-right:rem(-15);
			}
			&:after{
				top: 70%;
				transform: translate(0,-50%);
			}
		}
		&__label{
			text-align: left;
			&--abs{
				top:rem(-25);
			}
			&--right{
				position: absolute;
				bottom: 0;
				right:rem(-10);
				transform: translate(0,-50%);
			}
		}
		&__input{
			&--nolabel{
				margin-top:0;
			}
			&--date{
				width:100%;
			}
		}
		&__fieldset{
			&--submit{
				margin-top:0;
				text-align: center;
				& > a {
					padding-bottom: rem(5);
					padding-top: rem(5);
				}
				.ppg-form__submitbox{
					text-align: center;
					display: block;
					width:100%;
					& > a{
						display: block;
						padding-top:rem(5);
						padding-bottom:rem(5);
					}
					& > input[type="submit"] {
						margin: 0;
						display: block;
						width:100%;
					}

				}
			}
		}
	}
	
	.ppg-form--options{
		.ppg-form__group{
			
			width: 100%;
			
		}
	}
	
}

@include media-query('xs'){

}
