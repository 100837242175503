/*----------------------------------------------
   # PPG-PAGINATION
------------------------------------------------*/

.#{$wf__ns}pagination{
    padding-bottom: rem(47);
    //background-color: color(white);
    &__btn{
        &--left{
            float: left;
            text-align: left;
        }
        &--right{
            float: right;
            text-align: right;
        }
        &--middle{
            text-align: center;

        }
    }
    
}

@include media-query('md'){

}


@include media-query('sm'){
    .#{$wf__ns}pagination{
        text-align: center;
        margin: 0 auto;
        &__btn{
            &--middle{
                margin: 0 auto;
                width: auto;
                
            }
            &--left,&--right{
                width: auto;
            }
        }
    }



}

@include media-query('xs'){

}
