/*----------------------------------------------
   # TEXT: estilización de los elementos de texto del portal
			Sólo se estilizan txt párrafos qe no tienen interacción
			propia del elemento.
------------------------------------------------*/

.#{$wf__ns}text{
    //font-family: Arial;
    font-family: $wf__font--default;
	font-size: rem(14);
	line-height: 1.29;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	color: color(secondary);
	&--bold{
		font-weight:bold;
	}
	&--lighted{
		color: color(grey-1);
	}
	&--lighted-2{
		color: color(tertiary);
	}
	&--lighted-3{
		font-size: rem(12);
		color: color(tertiary)
	}
	&--top-lighted{
		padding-top: rem(6);
		color: color(tertiary);
	}
	&--primary-color{
		color: color(primary);
	}
	&--wind{
		padding-top:rem(10);
	}
	&--type02{
		font-size: rem(16);
	}
	&--type03{
		padding-left: rem(16);
	}
	&--mbttm{
		margin-bottom:rem(10);
	}

}


// MODIFIER
.o-list--inline{
	.#{$wf__ns}text--lighted-2{
		padding-top: rem(12);
		font-size:rem(12);
		float: right;
	}
}
.#{$wf__ns}footer{
	&--list{
		.#{$wf__ns}text{
			font-size: rem(13);
			padding-bottom: rem(10);
			font-weight: bold;
		}
	}
}
.#{$wf__ns}table--type02{
	.#{$wf__ns}text{
		padding-left: rem(30);
		&--type02{
			padding-left: 0;
			font-weight: bold;
			display: inline-block;
			word-wrap: break-word;
			max-width: 66%;
			vertical-align: middle;
		}
	}
}
@include media-query('sm'){
	.#{$wf__ns}text{
		&--type03{
			padding-left: 0;
		}
	}
}
