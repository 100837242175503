 /*-------------------------------------------
 	# ELEMENT FONT GENERATOR
 ---------------------------------------------*/

///* Example for use @font-face
//
//	@include font-face([name-font],
//		"[path]",
//		[font-style],
//		[font-weight],
//		[type-font(eot | woff | svg | ttf)]
//	);
//
//*/

 /*-------------------------------------------
    # END ELEMENT FONT GENERATOR
---------------------------------------------*/
