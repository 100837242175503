 /*------------------------------------------------------------------------
     # GENERIC HIDE ACCESSIBLE
 -------------------------------------------------------------------------*/

.hideAccessible {
	position: absolute;
	left: -9999em;
}


 /*------------------------------------------------------------------------
    # END GENERIC HIDE ACCESSIBLE
-------------------------------------------------------------------------*/
