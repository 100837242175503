/*----------------------------------------------
   # módulo
------------------------------------------------*/

.#{$wf__ns}header{
	//height: 120px;
	background-color: color(white);
	&__row{
		margin-left: rem(-15);
		margin-right: rem(-15);
	}
	&__logo{
		padding-top: rem(35);
		padding-bottom:rem(15);
		img{
			height: rem(60);
			width:rem(354);
		}
	}
	&__list{
		float:right;
		display:block;
		
	}
	&__item.#{$wf__ns}header__item{
		box-sizing: border-box;
		padding-top: rem(3);
		position: relative;
		margin-bottom: rem(5);
		width: rem(114);
		text-align: center;
		cursor: pointer;
		&:hover{
			//background: color(salmon);
		}
	}
	&__item{
		&--button.#{$wf__ns}header__item--button{
			margin-left: rem(10);
			padding:0;
			box-sizing: border-box;
		}
		&:after{
			position: absolute;
			top: 45%;
			right:rem(10);
			-webkit-transform:translate(0,-50%);
			-moz-transform:translate(0,-50%);
			-ms-transform:translate(0,-50%);
			-o-transform:translate(0,-50%);
			transform:translate(0,-50%);
		}
	}
	&__search{
		width:rem(370);
		float:right;
		margin-top:rem(15);
		margin-bottom: rem(15);
		position: relative;
		&:after{
			position: absolute;
			top:50%;
			right: rem(10);
			-webkit-transform: translate(0,-50%);
			-moz-transform: translate(0,-50%);
			-ms-transform: translate(0,-50%);
			-o-transform: translate(0,-50%);
			transform: translate(0,-50%);
		}
	}
}


@include media-query('md'){
	.#{$wf__ns}header {
		&__row {
			margin: 0;
		}
		&__item{
			&--button.#{$wf__ns}header__item--button{
				margin-bottom:rem(10);
			}
		}
		&__search{
			width: rem(350);
			margin-top:0;
		}
		&__logo{
			margin-top:rem(5);
			img{
				width: rem(317);
				height: rem(53);
			}
		}
	}
}

@include media-query('md'){

}


@include media-query('sm'){
	.#{$wf__ns}header {
		&__buttons{
			display:none;
		}
		&__search{
			display:none;
		}
		&__logo{
			margin: rem(10) auto;
			padding: 0;
			text-align:center;
			img{
				width: rem(226);
				height: rem(37);
			}
		}
	}
}

@include media-query('xs'){

}
