@if $include__trump--padding{
    /*------------------------------------
     # TRUMP PADDING
    ------------------------------------*/
    .t-padding-none{
        padding: 0 !important;
    }
    .t-padding-top-none{
        padding-top: 0 !important;
    }
    .t-padding-right-none{
        padding-right: 0 !important;
    }
    .t-padding-bottom-none{
        padding-bottom: 0 !important;
    }
    .t-padding-left-none{
        padding-left: 0 !important;
    }
    @for $i from $wf__trumps-padding-init through $wf__trumps-padding-end{
        $value : $i*$wf__trumps-padding-steps;

        .t-padding-#{$value}{
            padding: #{$value}px !important;
        }
        .t-padding-top-#{$value}{
            padding-top: #{$value}px !important;
        }
        .t-padding-right-#{$value}{
            padding-right: #{$value}px !important;
        }
        .t-padding-bottom-#{$value}{
            padding-bottom: #{$value}px !important;
        }
        .t-padding-left-#{$value}{
            padding-left: #{$value}px !important;
        }
    }

    /*------------------------------------
     # END TRUMP padding
    ------------------------------------*/

}// end if
