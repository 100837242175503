/*------------------------------------
    # ELEMENT IMAGE
------------------------------------*/

img {
	max-width 		: 100%;
	font-style 		: italic;
	vertical-align 	: middle;
}
img,
img[width],
img[height] {
	max-width 		: none;
}

/*------------------------------------
    # END ELEMENT IMAGE
------------------------------------*/
