/*----------------------------------------------
   # STEPS
------------------------------------------------*/

.#{$wf__ns}step{
  &__one{
    padding-top:rem(20);
  }
  &__two{
    
  }
  &__childs{
    display: none;
    position: relative;
    padding-top: rem(20);
    &:before{
      content: '';
      position: absolute;
      left: 0;
      top: rem(-20);
      height: 15px;
      width:100%;
      border-bottom: 1px solid color(grey-4);
      background-color: color(white);
      z-index:5;
    }
    &:after{
      position: absolute;
      top:rem(-9);
      left: 50%;
      transform: translate(-50%, 0);
      content: url($wf__base-path-icons-files + 'icon-arrow-down-grey.png');
      z-index: 10;
    }
    &.active{
      display: block;
    }
  }
  &__third{}
}
